<template>
    <span :class="`px-2 py-1/2 rounded-full text-[9px] uppercase tracking-wide ${badgeClass}`">
        {{ badgeText }}
    </span>
</template>

<script>
export default{
    props: {
        source: {
            type: String,
            required: true
        }
    },
    computed: {
        badgeText()
        {
            return this.$t(`opportunities.source.${this.source}`);
        },
        badgeClass()
        {
            switch (this.source) {
                case 'trustup':
                    return 'bg-yellow-500 text-white';
                case 'facebook':
                    return 'bg-blue-600 text-white';
                case 'website':
                    return 'bg-gray-800 text-white';
                case 'other':
                    return 'bg-gray-300 text-black';
            }
        }
    }
}
</script>
