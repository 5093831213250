<template>
    <div>
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            
            <template v-if="worksites.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('worksites.archives.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('worksites.archives.empty.text') }}</p>
                </div>
            </template> 
            <template v-else>
                <h2 class="mb-4 text-2xl text-medium">{{ $t('worksites.archives.title') }}</h2>
                <worksites-index-list :worksites="worksites.get()"></worksites-index-list>
            </template>
        </template>
    </div>
    
</template>

<script>
    import WorksitesIndexList from './Index/_List';
    import ArchivedWorksiteCollection from '~spa/apps/trustup-pro-worksite-js/collections/ArchivedWorksiteCollection';
    export default {
        components: {
            WorksitesIndexList
        },
        data() {
            return {
                worksites: [],
                loading: true
            }
        },
        methods: {
            async getWorksites()
            {
                let {worksites} = await window.Worksite.endpoints.worksite.index({archived: true});
                this.worksites = worksites;
                this.loading = false;
            }
        },
        async created() {
            await this.getWorksites();
        }
    }
</script>