var render = function render(){var _vm=this,_c=_vm._self._c;return _c('td',{class:_vm.tdClass},[_vm._t("default"),_vm._v(" "),_c('v-spa-submenu-container',{ref:"submenu",attrs:{"position":"right-0"},on:{"hidden":_vm.updated},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(_vm.isRange)?[_c('div',{staticClass:"relative w-full px-2 py-[5px] overflow-hidden bg-gray-300 rounded-full cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.onClick()}}},[_c('div',{staticClass:"absolute inset-0 z-0 bg-primary",style:(`width: ${_vm.durationRangePercentage}%;`)}),_vm._v(" "),_c('p',{staticClass:"relative z-10 text-xs font-medium text-center text-white whitespace-nowrap"},[_vm._v(_vm._s(_vm.value()))])])]:[_c('div',{staticClass:"flex justify-center"},[_c('span',{staticClass:"flex items-center text-sm cursor-pointer",class:_vm.isPast ? 'text-red-600 hover:text-red-500 font-medium' : 'hover:text-black',on:{"click":function($event){$event.preventDefault();return _vm.onClick()}}},[(_vm.isPast)?_c('i',{staticClass:"mr-2 fal fa-exclamation-circle"}):_vm._e(),_vm._v(" "),(_vm.value() == null)?[_vm._v("\n                            "+_vm._s(_vm.$t('worksite.tasks.columns.date.empty'))+"\n                        ")]:[_vm._v("\n                            "+_vm._s(_vm.value())+"\n                        ")]],2)])]]}}])},[_vm._v(" "),_c('div',{staticClass:"w-auto",attrs:{"slot":"submenu"},slot:"submenu"},[_c('date-picker',{staticClass:"!border-0",attrs:{"mode":"range","is-inline":true,"locale":_vm.$i18n.locale,"attributes":[
                    {
                        key: 'today',
                        dot: true,
                        dates: new Date(),
                    },
                ],"columns":2,"step":1},on:{"input":_vm.dateSelected},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }