<template>
    <v-spa-layout-page
        :tabs="tabs"
        :content-padding-bottom="$route.name !== 'account.opportunities.index'"
    >
        <template slot="title">
            {{ $t('opportunities.index.title') }}
        </template>
        <v-spa-loading-button slot="title-button" v-if="$route.name === 'account.opportunities.index'" @clicked="$bus.$emit('worksite:opportunity-form-modal:open')">
            {{ $t('opportunities.index.create_button') }}
        </v-spa-loading-button>

        <router-view slot="content" :key="'opportunities-' + $route.fullPath"></router-view>
    </v-spa-layout-page>
</template>

<script>
    import {mapState} from 'vuex';
    import TitleTab from '~base/components/_Partials/TitleTab.vue';

    export default {
        components: {
            TitleTab
        },
        data() {
            return {
                tabs: [
                    {
                        name: 'account.opportunities.index',
                        label: this.$t('opportunities.index.tabs.index'),
                    },
                    {
                        name: 'account.opportunities.archives',
                        label: this.$t('opportunities.index.tabs.archives'),
                    }
                ]
            }
        }
    }
</script>
