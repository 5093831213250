
export default {
    worksite: null,
    worksitePhases: null,
    models: {
        account: [],
        users: [],
        worksites: [],
    },
    opportunity: null,
    opportunityQuotes: null,
    apps: null,
    google: null,
    openedModals: [],
    pages: {
        dropzone: {
            dropping: false,
            uploading: false
        },
        worksite: {
            files: {
                folder: null
            }
        }
    }
};