<template>
    <th class="px-4 py-4 text-sm font-normal text-left text-gray-500 lg:px-6 w-[25px]">
        <!-- <v-spa-submenu-container :container="$spa.css.submenu.container.default" position="right-0 w-[240px]" ref="submenu">
            <template v-slot:default="slotProps">
                <i class="cursor-pointer far fa-plus-circle hover:text-primary" @click.prevent="slotProps.toggle()" />
            </template>
            <div class="relative w-auto" slot="submenu">
                <v-spa-loader v-if="loading"></v-spa-loader>
                <span class="px-4 mt-4 mb-4 block text-gray-800 text-[15px] font-medium">{{ $t('worksite.tasks.add_column') }}</span>
                <a :class="$spa.css.contextmenu.link" href="#" v-for="columnType in columnTypesWithIcon" @click.prevent="$emit('clicked', columnType.type)" :key="columnType.type">
                    <i :class="`mr-2 text-sm text-gray-600 ${columnType.icon}`" />
                    {{ columnType.name }}
                </a>
            </div>
        </v-spa-submenu-container> -->
    </th>
</template>

<script>
    import WorksiteTaskColumn from '~base/models/WorksiteTaskColumn';

    export default {
        props: {
            columnTypes: {
                type: Array,
                required: true,
            },
            loading: {
                type: Boolean,
                required: true
            }
        },
        computed: {
            columnTypesWithIcon() {
                return this.columnTypes.map(type => {
                    let model = WorksiteTaskColumn[type]();
                    console.log(model, model.getName(), model.title);
                    return {
                        type: type,
                        name: model.getName(),
                        icon: model.getIcon()
                    };
                });
            }
        }
    }
</script>
