<template>
    <td :class="tdClass">
        <slot></slot>
        <div v-if="! clicked" class="cursor-pointer text-[14px] whitespace-nowrap truncate w-full whitespace-nowrap truncate py-0.5 px-[3px] hover:text-black border border-gray-100 hover:border hover:border-dashed hover:border-gray-600 hover:bg-white" @click.prevent="onClick()">
            <span class="">
                {{ value() || '...' }}
            </span>
        </div>
        <input ref="input" v-if="clicked" class="text-[14px] w-full py-0.5 px-[3px] bg-white text-gray-700 border border-dashed border-gray-600 focus:outline-none" v-model="taskText" @blur="updateValue" @keyup.enter="updateValue" @keyup.esc="updateValue" />
    </td>
</template>

<script>
    import Mixin from './mixin';
    
    export default {
        mixins: [Mixin],
        data() {
            return {
                taskText: null
            }
        },
        methods: {
            onClick()
            {
                this.taskText = this.value();
                this.clicked = true;
                this.$nextTick(() => this.$refs.input.focus());
            },

            updateValue(event)
            {
                console.log('updateValue?');
                this.clicked = false;

                if ( this.value() !== this.taskText ) {
                    console.log('Updating value', this.taskText, event);
                    this.task.setColumnValue(this.column.id, this.taskText);
                    this.$nextTick(() => this.updated());
                }
            }
        }
    }
</script>
