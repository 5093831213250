<template>
    <div>
        <div class="flex mb-2">
            <div class="px-6 w-[300px]">
                <span :class="$spa.css.table.th_text">
                    {{ $t('worksites.index.labels.title') }}
                </span>
            </div>
            <div class="px-6 w-[300px]">
                <span :class="$spa.css.table.th_text">
                    {{ $t('worksites.index.labels.dates') }}
                </span>
            </div>
            <div class="px-6 hidden lg:block w-[160px]">
                <span :class="$spa.css.table.th_text">
                    {{ $t('worksites.index.labels.managers') }}
                </span>
            </div>
            <div class="px-6 hidden lg:block w-[160px]">
                <span :class="$spa.css.table.th_text">
                    {{ $t('worksites.index.labels.team') }}
                </span>
            </div>
            <div class="px-6 w-[200px]">
                <span :class="$spa.css.table.th_text">
                    {{ $t('worksites.index.labels.financial') }}
                </span>
            </div>
            <div class="flex justify-end flex-1 px-6">
                <span :class="$spa.css.table.th_text">
                    {{ $t('worksites.index.labels.actions') }}
                </span>
            </div>
        </div>  
        <div class="flex flex-col space-y-2">
            <worksite-item
                v-for="worksite in worksites"
                :key="worksite.uuid"
                :worksite="worksite"
            />
        </div>
    </div>
</template>

<script>
    import UsersListAvatar from '~spa/apps/trustup-pro-base-js/components/_Partials/UsersListAvatar';
    import WorksiteItem from '~spa/apps/trustup-pro-worksite-js/components/Worksite/Item.vue';

    export default {
        components: {
            UsersListAvatar,
            WorksiteItem
        },
        props: {
            worksites: {
                type: Array,
                required: true
            }
        },
        methods: {
            //
        }
    }
</script>