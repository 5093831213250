<template>
    <v-spa-layout-page :tabs="tabs" :content-padding="false" :use-container="false">
        <template slot="title">{{ $t('planning.index.title') }}</template>
        <router-view slot="content" :key="'planning-' + $route.fullPath"></router-view>
    </v-spa-layout-page>
</template>

<script>
    export default {
        data() {
            return {
                tabs: [
                    {
                        name: 'account.planning.worksites',
                        label: this.$t('planning.index.tabs.worksites'),
                    },
                    {
                        name: 'account.planning.users',
                        label: this.$t('planning.index.tabs.users'),
                    }
                ]
            }
        }
    }
</script>
