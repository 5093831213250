import BaseEndpoint from '@spa/apps/trustup-pro-base-js/endpoints/BaseEndpoint';
import WorksiteProgressBillingQuoteCollection from './../collections/WorksiteProgressBillingQuoteCollection';
import WorksiteProgressBillingQuote from './../models/WorksiteProgressBillingQuote';

export default class WorksiteProgressBillingQuoteEndpoint extends BaseEndpoint
{

    constructor()
    {
        super(
            'worksite',
            {
                'path': 'worksites/worksite-uuid/progress-billing-quotes',
                'responseKey': 'quote',
                'responseKeyPlural': 'quotes',
                'modelClass': WorksiteProgressBillingQuote,
                'collectionClass': WorksiteProgressBillingQuoteCollection
            }
        );
    }

    setWorksite(worksite)
    {
        this.worksite = worksite;
        return this;
    }

    getPath()
    {
        return this.path.replace('worksite-uuid', this.worksite.uuid);
    }

    newCollection(models)
    {
        return new this.collectionClass(
            models,
            this.worksite
        );
    }

}