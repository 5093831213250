<template>
    <div :class="{'p-12': loading || (worksites && worksites.isEmpty())}">
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            
            <template v-if="worksites.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('planning.worksites.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('planning.worksites.empty.text') }}</p>
                </div>
            </template> 
            <template v-else>
                <timeline :resources="timelineResources" :events="timelineEvents"></timeline>
            </template>
        </template>
    </div>
    
</template>

<script>
    import Timeline from './_Timeline';

    export default {
        components: {
            Timeline
        },
        data() {
            return {
                worksites: [],
                timelineResources: null,
                timelineEvents: null,
                loading: true
            }
        },
        methods: {
            async getWorksites()
            {
                let {worksites} = await window.Worksite.endpoints.worksitePlanning.index();
                this.worksites = worksites;
            },
            
            async setupTimeline()
            {
                this.timelineResources = [];
                this.timelineEvents = [];
                
                for ( let i = 0; i < this.worksites.length; i++ ) {
                    for ( let n = 0; n < this.worksites[i].getUsers().length; n++ ) {
                        // If the user is not set already, add them to the resources
                        if ( ! this.timelineResources.find(resource => resource.id == `user-${this.worksites[i].getUsers()[n].id}`) ) {
                            this.timelineResources.push({
                                id: `user-${this.worksites[i].getUsers()[n].id}`,
                                position: this.worksites[i].getUsers()[n].id,
                                title: this.worksites[i].getUsers()[n].name
                            });
                        }

                        this.timelineEvents.push({
                            resourceId: `user-${this.worksites[i].getUsers()[n].id}`,
                            start: this.worksites[i].starts_at,
                            end: this.worksites[i].ends_at,
                            title: this.worksites[i].title,
                            position: i,
                            link: this.$router.resolve({name: 'account.worksite.homepage', params: {worksite: this.worksites[i].uuid}}).href,
                        });
                    }
                }
            }
        },
        async created() {
            await this.getWorksites();
            await this.setupTimeline();
            this.loading = false;
        }
    }
</script>