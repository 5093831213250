<template>
    <model-modal
        component="v-spa-mixed-modal"
        :model-class="modelClass"
        modal-type="form"
        modal-name="worksite:worksite-progress-billing-invoice-form-modal"
        @opened="onOpened"
        @resetted="onReset"
        ref="modal"
    >
        <template v-slot:default>
            <h2 class="mb-8 text-xl font-thin text-center text-gray-800">
                {{ $t('worksite.worksite-progress-billing-invoice-form-modal.title') }}
            </h2>
            <form @submit.prevent="submit">
                <v-spa-input :form="form" input-name="title" :label="$t('form.labels.invoice_title')" />
                <v-spa-textarea :form="form" input-name="description" :label="$t('form.labels.invoice_description')" />
                <v-spa-input :form="form" input-name="line" :label="$t('form.labels.invoice_line')" />
                <div class="flex justify-end w-full">
                    <v-spa-loading-button :loading="form.isLoading()">
                        <i class="mr-2 fal fa-check"></i>
                        {{ $t('worksite.worksite-progress-billing-invoice-form-modal.button') }}
                    </v-spa-loading-button>
                </div>
            </form>
        </template>
    </model-modal>
</template>

<script>
    import ModelModal from '@spa/apps/trustup-pro-base-js/components/Model/Modal';
    import Model from './../models/WorksiteProgressBilling';
    import Form from '@spa/classes/Form';

    export default {
        components: {
            ModelModal
        },

        data() {
            return {
                modelClass: Model,
                quote: null,
                form: new Form({
                    title: null,
                    description: null,
                    line: null
                })
            };
        },

        computed: {
            model()
            {
                return this.$refs.modal?.model || null;
            },
        },

        methods: {
            getModalEvent()
            {
                if ( this.modalName ) {
                    return `${this.modalName}:open`;
                }

                let method = this.modalType == 'form' ? 'getFormModalEvent' : 'getModalEvent';
                return this.modelClass[method]();
            },

            async onOpened({quote})
            {
                this.quote = quote;
                this.form.title = this.$t('worksite.worksite-progress-billing-invoice-form-modal.default_invoice_title', {title: this.$store.state.worksite.title});
                this.form.description = this.$t('worksite.worksite-progress-billing-invoice-form-modal.default_invoice_description', {quote: this.quote.number});
                this.form.line = this.$t('worksite.worksite-progress-billing-invoice-form-modal.default_invoice_line', {number: this.model.number});
            },

            onReset()
            {
                this.quote = null;
            },

            async submit()
            {
                if ( this.form.isLoading() ) {
                    return; 
                }

                this.form.load();
                
                let data = {
                    quote: this.quote,
                    worksite: this.$store.state.worksite,
                    title: this.form.title,
                    description: this.form.description,
                    line: this.form.line,
                };
                console.log(this.model.toInvoice(data));
                let invoice = await this.$spa.TrustUpPro.storeInvoice( this.model.toInvoice(data) );
                window.open(
                    `${this.$spa.apps.find(app => app.key == 'invoicing').direct_url}/redirection?route=invoices.edit&invoice=${invoice.uuid}`,
                    '_blank'
                );

                await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).invoiced(this.model.uuid, invoice.uuid);

                this.form.stop();
                this.$bus.$emit('worksite:worksite-progress-billing-invoice-form-modal:success', {billing: this.model, invoice: invoice});
                this.$refs.modal.close();
            }
        }
    }
</script>