import BaseCollection from '@spa/apps/trustup-pro-base-js/collections/BaseCollection';
import WorksiteProgressBillingQuote from './../models/WorksiteProgressBillingQuote';

export default class WorksiteProgressBillingQuoteCollection extends BaseCollection
{

    constructor(items, worksite)
    {
        super(items, {
            modelClass: WorksiteProgressBillingQuote
        });

        this.worksite = worksite;
    }

}