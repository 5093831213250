<template>
    <profitability-graph-item color="" :width="width"></profitability-graph-item>
</template>

<script>
    import Item from './Item';

    export default {
        components: {
            'profitability-graph-item': Item,
        },
        props: {
            width: {
                type: Number,
                default: 100
            },
        }
    }
</script>