<template>
    <div :class="`py-4 px-6 rounded-lg h-[140px] ${bgColor} ${textColor}`">
        <h2 class="mb-1 text-sm uppercase"><slot></slot></h2>
        <span class="block text-lg font-thin md:text-xl xl:text-3xl">
            <i class="fal fa-check-circle" v-if="displayIcon && amount > 0" />
            <i class="fal fa-exclamation-circle" v-if="displayIcon && amount <= 0" />
            {{ amount | price }}
        </span>
        <slot name="under-amount"></slot>
    </div>
</template>

<script>
    export default {
        props: {
            bgColor: {
                type: String,
                default: 'bg-gray-200'
            },
            textColor: {
                type: String,
                default: 'text-gray-800'
            },
            amount: {
                type: Number,
                required: true
            },
            displayIcon: {
                type: Boolean,
                default: false
            }
        }
    }
</script>
