<template>
    <div class="p-4 border border-gray-200 rounded-lg">
        <div class="flex items-center justify-between mb-4" v-if="title || button">
            <p class="text-lg" v-if="title">
                {{ title }}
            </p>
            <v-spa-loading-button v-if="button" :small="true" :color="$spa.css.button.color.white" extra-class="border border-gray-200" @clicked="$emit('button-clicked')">
                {{ button }}
            </v-spa-loading-button>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            title: {
                type: String,
                required: false
            },
            button: {
                type: String,
                required: false
            }
        }
    }
</script>