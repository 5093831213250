<template>
    <span class="flex items-center text-gray-600 cursor-pointer" :class="extraClassName" @click.prevent="$emit('clicked')">
        <slot></slot>
        <!-- This is a space to maintain the same size in the sidebar item as the graph lines -->
        <span class="block h-8 w-[1px]"></span>
    </span>
</template>

<script>
    export default {
        props: {
            small: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            extraClassName() {
                return this.small ? 'py-2 text-sm pl-4' : 'py-6 text-lg';
            }
        }
    }
</script>