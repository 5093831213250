<template>
    <layout>
        <template slot="content">
            <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        </template>
    </layout>
</template>

<script>
    import Layout from '~base/components/Layout';

    export default {
        components: {
            Layout
        },
        data() {
            return {
                redirect: null,
            }
        },
        methods: {
            getRedirection()
            {
                let redirect = {name: 'homepage'};
                
                let params = window.getQueryParams(window.location.href);

                let routes = [
                    {
                        external: 'worksites.index',
                        internal: 'account.worksites.index',
                    },
                    {
                        external: 'worksite.show',
                        internal: 'account.worksite.informations',
                    },
                    {
                        external: 'worksite.profitability',
                        internal: 'account.worksite.profitability',
                    },
                    {
                        external: 'worksite.files',
                        internal: 'account.worksite.files',
                    },
                    {
                        external: 'worksite.phases',
                        internal: 'account.worksite.phases',
                    },
                    {
                        external: 'worksite.tables',
                        internal: 'account.worksite.tables',
                    },
                    {
                        external: 'worksite.progress',
                        internal: 'account.worksite.progress',
                    },
                    {
                        external: 'worksite.providers',
                        internal: 'account.worksite.providers',
                    }
                ];

                for ( let i = 0; i < routes.length; i++ ) {
                    let route = routes[i];
                    
                    if ( params.route && params.route == route.external ) {
                        return {name: route.internal, params: {
                            account: this.$spa.TrustUpPro.getAccount('worksite').uuid,
                            worksite: params.worksite
                        }};
                    }
                }

                return redirect;
            }
        },
        created() {
            let redirect = this.getRedirection();

            if ( redirect ) {
                console.log('Redirect found:', redirect);
                return this.$router.push(redirect);
            }

            console.log('Redirect not found.');
            return this.$router.push({name: 'homepage'});
        }
    }
</script>