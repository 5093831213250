<template>
    <div class="mb-8">
        <div
            class="flex items-center px-6 py-2 mb-2 space-x-2 bg-gray-100 rounded-full cursor-pointer"
            @contextmenu.stop.prevent="phase.contextMenu($event)"
            @click.prevent="phase.edit()"
        >
            <div class="flex-1">
                <p class="text-lg">
                    {{ phase.title }}
                    <span class="text-gray-600 font-thin text-[11px]">
                        ({{ $moment(phase.starts_at).format('DD MMMM') }} - {{ $moment(phase.ends_at).format('DD MMMM YYYY') }})
                    </span>
                </p>
            </div>
            <div class="w-[120px]"></div> 
            <div class="w-[120px] text-right text-sm">
                {{ $t('worksite.phases.headers.comments') }}
            </div> 
            <div class="w-[120px] text-right text-sm">
                {{ $t('worksite.phases.headers.timetracking') }}
            </div>  
            <div class="w-[120px] text-right text-sm">
                {{ $t('worksite.phases.headers.due_date') }}
            </div> 
        </div>
        <div v-if="phase.trustup_io.tasks.tasks">
            <task v-for="task in phase.trustup_io.tasks.tasks.get()" :task="task" :phase="phase" :key="task.uuid" />
        </div>
        <div class="w-full h-[48px]" v-for="i in loadingNewTasks" :key="`loading-${i}`">
            <div class="w-[150px] h-full relative">
                <v-spa-loader />
            </div>
        </div>
        <div class="flex px-4 py-2 space-x-2">
            <div class="flex-1">
                <input style="font-size: 13px !important;" :placeholder="$t('worksites.phases.add_task_placeholder')" ref="newTaskInput" class="w-full px-3 py-2 text-xs border border-gray-400 border-dashed" @blur="createNewPhaseTask" @keyup.enter="createNewPhaseTask" />
            </div>
            <div class="w-[120px] flex justify-end">
                <!-- <div class="flex items-center justify-center w-8 h-8 border border-gray-400 border-dashed rounded-full">
                    <i class="text-gray-400 far fa-plus"></i>
                </div> -->
            </div> 
            <div class="w-[120px]"></div> 
            <div class="w-[120px]"></div>  
            <div class="w-[120px]"></div> 
        </div>
    </div>
</template>

<script>

    import Task from '../List/Task.vue'
    import WorksitePhaseTrustUpIOTask from '~spa/apps/trustup-pro-worksite-js/models/WorksitePhaseTrustUpIOTask';

    export default {
        components: {
            Task
        },

        props: {
            worksite: {
                type: Object,
                required: true
            },
            phase: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                loadingNewTasks: 0,
            }
        },

        methods: {
            async createNewPhaseTask()
            {
                let title = this.$refs.newTaskInput.value.trim();
                if ( title == '' ) {
                    return;
                }

                this.$refs.newTaskInput.value = '';

                let data = {
                    ...{
                        title: title,
                        due_date: null,
                        done_at: null,
                        is_having_due_date_time: false,
                        temporaryUuid: Math.random().toString(36),
                        professional_authorization_key: this.$store.state.account.authorization_key,
                        account_uuid: this.$store.state.account.uuid
                    },
                    ...this.phase.trustup_io.model
                };
                
                // this.phase.trustup_io.tasks.tasks.add(
                //     new WorksitePhaseTrustUpIOTask(data)
                // );
                
                this.loadingNewTasks++;
                let task = await window.TrustUpBase.endpoints.trustupIOTask.store(data); 
                this.loadingNewTasks--;
                console.log('Response from task endpoint:', task);
            },
            
            forceUpdateComponent()
            {
                console.log('wow');
                this.$forceUpdate();
            }
        },

        created()
        {
            this.$bus.$on('trustup-io-task:task:created', this.forceUpdateComponent);
        },

        beforeDestroy()
        {
            this.$bus.$off('trustup-io-task:task:created', this.forceUpdateComponent);
        }
    }
</script>