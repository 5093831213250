<template>
    <div class="account-base">
        <layout>
            <template slot="content">
                <div class="relative py-24" v-if="loading">
                    <v-spa-loader></v-spa-loader>
                </div>
                <div class="relative h-full" v-if="! loading && $store.state.account && $store.state.account.uuid">
                    <router-view :key="'account-' + $store.state.account.uuid + '-' + $store.state.account.updated_at + '-' + $route.fullPath"></router-view>
                </div>
            </template>
        </layout>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Layout from '~base/components/Layout';
    export default {
        components: {
            'layout': Layout
        },
        data() {
            return {
                loading: false,
            }
        },
        methods: {
            async getAccount(uuid)
            {
                this.loading = true;

                let account = await this.$store.dispatch('getAccount', uuid)
                this.$store.dispatch('setAccount', account);

                let users = await window.TrustUpPro.getUsers();
                this.$store.commit('setUsers', users);

                this.loading = false;
            },
        },
        computed: {
            ...mapState(['account']),
        },
        
        created() {
            if (this.account && this.users) {
                return this.loading = false;
            }

            this.getAccount(this.$route.params.account);
        }
    }
</script>
