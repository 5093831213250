<template>
    <div>
        <div class="relative py-12" v-if="loading"><v-spa-loader /></div>

        <div v-else>
            <div class="flex items-center justify-between mb-8">
                <h2 class="text-xl text-black">{{ $t('worksite.progress-billings.create.title') }}</h2>
                <div></div>
            </div>

            <div v-if="! quote">
                {{ $t('worksite.progress-billings.create.select_quote.title') }}
                <select @change="setQuote">
                    <option :value="null">{{ $t('worksite.progress-billings.create.select_quote.option') }}</option>
                    <option :value="quote" v-for="quote in quotes" :key="quote.uuid">
                        {{ quote.number }}
                    </option>
                </select>
            </div>

            <div v-if="quote">
                <worksite-progress-billing-form
                    :quote="quote"
                    :billings="billings"
                    :quote-billings="quoteBillings"
                    ref="form"
                ></worksite-progress-billing-form>
            </div>
        </div>
    </div>
</template>

<script>

    import Form from '~spa/classes/Form';
    import WorksiteProgressBillingQuoteEndpoint from './spa/endpoints/WorksiteProgressBillingQuoteEndpoint';
    import WorksiteProgressBillingEndpoint from './spa/endpoints/WorksiteProgressBillingEndpoint';
    import WorksiteProgressBillingForm from './_Form';

    export default {
        components: {
            WorksiteProgressBillingForm
        },
        data() {
            return {
                quotes: null,
                quote: null,
                billings: null,
                loading: true
            }
        },
        computed: {
            quoteBillings()
            {
                return this.quote.getBillings(this.billings);
            }
        },
        methods: {
            async selectQuote(quote)
            {
                this.quote = quote;
                await this.$nextTick();
                this.$refs.form.setQuote(quote);

                if ( this.$refs.form.form.lines.every(line => line.max_quantity === 0) ) {
                    this.$router.push({name: 'account.worksite.progress-billings.index', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid}});
                    window.Toasteo.info(
                        this.$t('worksite.progress-billings.create.nothing-to-bill')
                    );
                    return;
                }
            },
            
        },
        async created()
        {
            // TODO: Move to the SPA package
            window.Worksite.endpoints.progressBillingQuote = new WorksiteProgressBillingQuoteEndpoint();
            window.Worksite.endpoints.progressBilling = new WorksiteProgressBillingEndpoint();

            let {quotes} = await window.Worksite.endpoints.progressBillingQuote.setWorksite(this.$store.state.worksite).index();
            let {billings} = await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).index();
            this.quotes = quotes;
            this.billings = billings;

            if ( this.quotes.isEmpty() ) {
                return this.$router.push({name: 'account.worksite.progress-billings.index', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid}})
            }

            let draft = this.billings.find(b => b.status == 'draft');
            if ( draft ) {
                window.Toasteo.info(
                    this.$t('worksite.progress-billings.create.existing-draft')
                );
                return this.$router.push({name: 'account.worksite.progress-billings.edit', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid, billing: draft.uuid}})
            }
            
            this.loading = false;

            if ( this.quotes.length() === 1 ) {
                this.selectQuote(this.quotes.get()[0]);
            }
        }
    }
</script>
