import BaseModel from '@spa/apps/trustup-pro-base-js/models/BaseModel';
import WorksiteProgressBillingCollection from './../collections/WorksiteProgressBillingCollection';
    
export default class WorksiteProgressBillingQuote extends BaseModel
{

    static getPrefix()
    {
        return 'worksite:worksite-progress-billing-quote';
    }

    getBillings(billings)
    {
        return new WorksiteProgressBillingCollection(
            billings.filter(b => b.worksite_progress_billing_quote_id === this.id)
        );
    }

}