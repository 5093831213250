<template>
    <div class="flex space-x-8">
        <div class="w-1/3">
            <v-spa-panel 
                :title="$t('worksite.informations.contact_panel.title')"
                :button="$t('worksite.informations.contact_panel.button')"
                @button-clicked="$bus.$emit('clients.edit', {uuid: worksite.contact_uuid, options: {hide: {types: true}}})"
            >
                <div v-if="worksite.contact">
                    <text-with-label
                        :label="$t('worksite.informations.panel.worksite_contact')"
                        :text="worksite.contact.name"
                    ></text-with-label>
                    <text-with-label
                        :label="$t('worksite.informations.panel.worksite_contact_email')"
                        :text="worksite.contact.email"
                    ></text-with-label>
                    <text-with-label
                        :label="$t('worksite.informations.panel.worksite_contact_phone_number')"
                        :text="worksite.contact.phone_number"
                    ></text-with-label>
                    <text-with-label
                        :label="$t('worksite.informations.panel.worksite_contact_address')"
                        :text="worksite.getContactAddress()"
                    ></text-with-label>
                </div>
            </v-spa-panel>
        </div>
        <div class="w-2/3">
            <v-spa-panel 
                :title="$t('worksite.informations.panel.title')"
                :button="$t('worksite.informations.panel.button')"
                @button-clicked="worksite.edit()"
            >
                <div class="flex w-full mb-8">
                    <div class="w-full md:w-1/2">
                        <text-with-label
                            :label="$t('worksite.informations.panel.worksite_address')"
                            :text="worksite.getAddress()"
                        ></text-with-label>
                        <text-with-label
                            :label="$t('worksite.informations.panel.worksite_start_date')"
                            :text="worksite.starts_at | moment().format('DD MMMM YYYY')"
                        ></text-with-label>
                        <text-with-label
                            :label="$t('worksite.informations.panel.worksite_end_date')"
                            :text="worksite.ends_at | moment().format('DD MMMM YYYY')"
                        ></text-with-label>
                        <text-with-label
                            :label="$t('worksite.informations.panel.managers')"
                            :text="worksite.getManagersName()"
                        ></text-with-label>
                        <text-with-label
                            :label="$t('worksite.informations.panel.users')"
                            :text="worksite.getUsersName()"
                        ></text-with-label>
                    </div>
                    <div class="w-full md:w-1/2">
                        <div id="worksite-map" class="h-full"></div>
                    </div>
                </div>
                <div>
                    <text-with-label
                        :label="$t('worksite.informations.panel.notes')"
                    >
                        <v-spa-textarea default-height="h-[200px]" :display-label="false" :should-resize="true" class-name="text-sm text-gray-800 min-h-[140px] bg-[#f9f8d1] px-3 py-2 rounded w-full" @input="updateWorksiteNotesDebounced" :label="$t('worksite.form.labels.notes')" id="create-worksite-form-notes" :form="form" input-name="notes"></v-spa-textarea>
                    </text-with-label>
                </div>
            </v-spa-panel>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Form from '~spa/classes/Form';
    import Map from '~spa/classes/Map';
    import Panel from '~base/components/_Partials/Panel';
    import ProfitabilityAmountsPanels from '~base/components/Worksite/Profitability/AmountsPanels';
    import TextWithLabel from '~base/components/_Partials/TextWithLabel';

    export default {
        components: {
            TextWithLabel,
            Panel,
            ProfitabilityAmountsPanels
        },
        data() {
            return {
                map: new Map(18, {disableDefaultUI: false, mapTypeId: 'satellite'}),
                form: new Form({
                    notes: ''
                }),
            }
        },
        computed: {
            ...mapState(['worksite']),
        },

        methods: {
            async toggleWorksiteArchiveStatus() {
                if ( this.$refs.archiveButton.submitted ) {
                    return;
                }

                this.$refs.archiveButton.load();

                let worksite = await window.Worksite.endpoints.worksite[this.worksite.archived_at ? 'unarchive' : 'archive'](this.worksite.uuid);

                this.worksite.archived_at = worksite.archived_at;
                this.$refs.archiveButton.stop();
            },

            updateWorksiteNotesDebounced: _.debounce(function (e)
            {
                console.log('test');
                this.worksite.notes = this.form.notes;
                this.updateWorksiteNotes();
            }, 500),

            async updateWorksiteNotes()
            {
                await window.Worksite.endpoints.worksite.updateNotes(this.worksite.uuid, this.form.notes);
            },

            loadMap()
            {
                if ( ! this.worksite.address ) {
                    return;
                }

                this.$store.dispatch('loadGoogleMaps')
                    .then(() => {
                        this.map.center = {lat: this.worksite.address.latitude, lng: this.worksite.address.longitude};
                        this.map.createMap('worksite-map');

                        console.log([
                            {
                                icon: this.map.getProfessionalIcon(),
                                position: {lat: this.worksite.address.latitude, lng: this.worksite.address.longitude}
                            }
                        ]);

                        this.map.createMarkers([
                            {
                                icon: this.map.getProfessionalIcon(),
                                position: {lat: this.worksite.address.latitude, lng: this.worksite.address.longitude}
                            }
                        ]);
                    });
            },

            async setContact(params)
            {
                this.worksite.contact_uuid = params.contact.uuid;
                this.worksite.contact = params.contact;
                await window.Worksite.endpoints.worksite.updateContact(this.worksite.uuid, this.worksite.contact_uuid);
            },

            refreshContact(params)
            {
                this.worksite.contact_uuid = params.contact.uuid;
                this.worksite.contact = params.contact;
            }
        },

        created() {
            this.loadMap();
            this.form.notes = this.worksite.notes;
            this.$bus.$on('contacts.stored', this.setContact);
            this.$bus.$on('contacts.updated', this.refreshContact);
        },
        beforeDestroy()
        {
            this.$bus.$off('contacts.stored', this.setContact);
            this.$bus.$off('contacts.updated', this.refreshContact);
        }
    }
</script>
