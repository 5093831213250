<template>
    <div class="relative worksite-phases-timeline-container">

        <div class="max-w-[600px] mx-auto" v-if="worksite.phases.isEmpty()">
            <div class="flex justify-center mb-4">
                <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
            </div>
            <div class="text-center">
                <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('worksite.phases.empty.title') }}</h1>
                <p class="mb-8 text-sm text-gray-500">{{ $t('worksite.phases.empty.text') }}</p>
                <div class="flex justify-center">
                    <v-spa-loading-button ref="addPhaseButton" @clicked="displayAddPhaseModal">
                        {{ $t('worksite.phases.empty.button') }}
                    </v-spa-loading-button>
                </div>
            </div>
        </div> 

        <template v-else>
            <div v-if="loading" class="absolute inset-0 z-10 flex items-center justify-center bg-white">
                <div class="relative py-12 w-[200px]">
                    <v-spa-loader></v-spa-loader>
                </div>
            </div>

            <div class="relative w-full overflow-x-scroll worksite-phases-timeline">

                <worksite-phases-gantt
                    :worksite="worksite"
                    :phases="worksite.phases.items"
                    ref="gantt"
                    @initialized="getCalendarEvents"
                    v-if="worksite.phases.length() > 0"
                ></worksite-phases-gantt>
            
                <div class="flex items-stretch" :style="`width: ${totalWidth}px`" v-if="displayDays">
                    <timeline-day
                        :day="day"
                        :index="index"
                        :day-width="dayWidth"
                        :is-first="index == 0"
                        :is-last="index == totalDaysArray.length - 1"
                        v-for="(day, index) in totalDaysArray"
                        :key="$moment(day).format('YYYY-MM-DD')"
                        :ref="`day-${$moment(day).format('YYYY-MM-DD')}`"
                    >
                        <div
                            v-for="calendarEvent in dayCalendarEvents(day)"
                            :key="calendarEvent.uuid"
                        >
                            <calendar-event
                                :phases="worksite.phases.items"
                                :calendar-event="calendarEvent"
                            />
                        </div>

                        <div class="flex items-center justify-center py-2">
                            <a href="#" class="text-gray-600 hover:text-black" @click.prevent="$bus.$emit('planning:calendar-event-form-modal:open', {form: {start: day, step: 'form', eventType: 'worksite', worksite: $store.state.worksite}})">
                                <i class="far fa-plus-circle"></i>
                            </a>
                        </div>
                    </timeline-day>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import VueContext from 'vue-context';
    import GanttView from './Phases/_Gantt.vue';
    import TimelineDay from './Phases/Timeline/Day.vue';
    import CalendarEvent from '~base/models/CalendarEvent';
    import CalendarEventVue from './Resources/_CalendarEvent.vue';

    export default {
        components: {
            VueContext,
            TimelineDay,
            'calendar-event': CalendarEventVue,
            'worksite-phases-gantt': GanttView
        },
        
        data() {
            return {
                loading: true,
                displayDays: false,
                calendarEvents: []
            }
        },

        computed: {
            ...mapState(['worksite']),
            
            phasesByStartDate()
            {
                return this.worksite.phases.sort((a, b) => {
                    return new Date(a.starts_at) - new Date(b.starts_at);
                });
            },

            dayWidth()
            {
                return this.$refs.gantt ? this.$refs.gantt.gantt.dayWidth : 200;
            },

            totalDaysArray()
            {
                if ( this.$refs.gantt ) {
                    return this.$refs.gantt.gantt.totalDaysArray();
                }

                return Array.from(
                    window.moment().range(this.$moment(this.worksite.stats_at), this.$moment(this.worksite.ends_at)).by('day')
                );
            },

            totalWidth()
            {
                if ( this.$refs.gantt ) {
                    return this.$refs.gantt.gantt.totalWidth();
                }

                return this.totalDaysArray.length * this.dayWidth;
            }
        },

        methods: {
            displayAddPhaseModal()
            {
                this.$bus.$emit('worksite:worksite-phase-form-modal:open', {
                    worksite: this.worksite,
                    worksitePhases: this.worksite.phases,
                    defaultTitle: this.$t('modals.worksite-phase.default_title', {
                        index: this.worksite.phases.length() + 1
                    })
                })
            },

            openCalendarEventContextMenu(calendarEvent, event)
            {
                calendarEvent.contextMenu(event, {
                    worksite: this.$store.state.worksite
                })
            },

            dayCalendarEvents(day)
            {
                // Get calendar events where the starts°at is the same as the day
                return this.calendarEvents.filter(event => {
                    return this.$moment(event.starts_at).format('YYYY-MM-DD') == this.$moment(day).format('YYYY-MM-DD');
                });
            },

            async getCalendarEvents()
            {
                this.displayDays = true;
                
                await this.$nextTick();

                console.log('test');
                
                let firstDay = this.$refs.gantt ? this.$refs.gantt.gantt.firstDay() : this.worksite.starts_at;
                let lastDay = this.$refs.gantt ? this.$refs.gantt.gantt.lastDay() : this.worksite.ends_at;
                
                let {events} = await window.Planning.endpoints.calendarEvent.index({
                    start: firstDay,
                    end: lastDay,
                    worksites: this.worksite.uuid
                });
                this.calendarEvents = events;
                console.log(this.calendarEvents);

                this.scroll();
            },
            
            scroll()
            {
                setTimeout(() => {
                    let day = this.findBestDayRef();
                    if ( ! day ) {
                        this.loading = false;
                        return;
                    }

                    day.$el.scrollIntoView({
                        inline: 'start',
                        block: 'nearest'
                    });
                    document.querySelector('.v-spa-layout-content .overflow-y-auto').scrollTop = 0
                    this.loading = false;
                }, 500);
            },

            setup()
            {
                if ( this.$refs.gantt ) {
                    this.$refs.gantt.init();
                }
            },
            
            findBestDayRef()
            {
                if ( ! this.$refs.gantt || ! this.displayDays ) {
                    return;
                }

                let day = this.$refs.gantt.gantt.findBestDay();
                if ( day && this.$refs[`day-${day}`] ) {
                    return this.$refs[`day-${day}`][0]
                }
            }
        },

        mounted()
        {
            this.setup();
        },
    }
</script>