<template>
    <td :class="tdClass">
        <slot></slot>
        <v-spa-submenu-container position="right-0" ref="submenu" @hidden="updated">
            <template v-slot:default="slotProps">
                
                <template v-if="isRange">
                    <div @click.prevent="onClick()" class="relative w-full px-2 py-[5px] overflow-hidden bg-gray-300 rounded-full cursor-pointer">
                        <div class="absolute inset-0 z-0 bg-primary" :style="`width: ${durationRangePercentage}%;`"></div>
                        <p class="relative z-10 text-xs font-medium text-center text-white whitespace-nowrap">{{ value() }}</p>
                    </div>
                </template>
                <template v-else>
                    <div class="flex justify-center">
                        <span @click.prevent="onClick()" class="flex items-center text-sm cursor-pointer" :class="isPast ? 'text-red-600 hover:text-red-500 font-medium' : 'hover:text-black'">
                            <i class="mr-2 fal fa-exclamation-circle" v-if="isPast" />
                            <template v-if="value() == null">
                                {{ $t('worksite.tasks.columns.date.empty') }}
                            </template>
                            <template v-else>
                                {{ value() }}
                            </template>
                        </span>
                    </div>
                </template>
            </template>
            <div class="w-auto" slot="submenu">
                <date-picker
                    class="!border-0"
                    v-model="range"
                    mode="range" 
                    :is-inline="true"
                    :locale="$i18n.locale"
                    :attributes="[
                        {
                            key: 'today',
                            dot: true,
                            dates: new Date(),
                        },
                    ]"
                    :columns="2"
                    :step="1"
                    @input="dateSelected"
                />
            </div>
        </v-spa-submenu-container>
    </td>
</template>

<script>
    import DateRangeDaysDuration from '~spa/classes/DateRangeDaysDuration';
    import DatePicker from 'v-calendar/lib/components/date-picker.umd';
    import Mixin from './mixin';

    export default {
        components: {
            DatePicker,
        },
        mixins: [Mixin],
        data() {
            return {
                range: null
            }
        },
        computed: {
            isPast() {
                // return false; // Disabled for now.
                return window.moment(this.range.start).isBefore(window.moment(), 'days') && ! this.task.isCompleted(this.columns);
            },

            isRange() {
                return this.getBaseValue() !== null && typeof this.getBaseValue() === 'object';
            },

            dateRangeClass()
            {
                return new DateRangeDaysDuration(this.range.start, this.range.end);
            },

            durationRangePercentage() {
                if ( ! this.isRange ) {
                    return 0;
                }

                return new DateRangeDaysDuration(this.range.start, this.range.end).getPercentage();
            },
        },
        methods: {
            getRange()
            {
                let value = this.getBaseValue();

                if ( ! value ) {
                    return null;
                }
                
                if ( typeof value === 'string' ) {
                    return {
                        start: window.moment(value).toDate(),
                        end: window.moment(value).toDate()
                    };
                }

                return {
                    start: window.moment(value.start_date).toDate(),
                    end: window.moment(value.end_date).toDate()
                };
            },
            setRange()
            {
                if ( this.range ) {
                    return;
                }
                this.range = this.getRange();
            },
            onClick()
            {
                this.setRange();
                this.$refs.submenu.show();
            },
            dateSelected(event)
            {
                let value = {start_date: window.moment(event.start).format('YYYY-MM-DD'), end_date: window.moment(event.end).format('YYYY-MM-DD')}
                if ( value.start_date == value.end_date ) {
                    value = value.start_date;
                }
                
                this.task.setColumnValue(this.column.id, value);
                this.updated();
            },
            value()
            {
                let value = this.getBaseValue();

                if ( ! value ) {
                    return null;
                }
                
                if ( typeof value === 'string' ) {
                    if ( window.moment(value).isSame(window.moment(), 'day') ) {
                        return this.$t('worksite.tasks.columns.date.today');
                    }
                    
                    return window.moment(value).format('DD MMM YYYY');
                }

                return window.moment(value.start_date).format('DD MMM YYYY') + ' - ' + window.moment(value.end_date).format('DD MMM YYYY')
            }
        },
        created() {
            this.setRange();
        }
    }
</script>
