<template>
    <div>
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            
            <template v-if="opportunities.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('opportunities.archives.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('opportunities.archives.empty.text') }}</p>
                </div>
            </template> 
            <template v-else>
                <div class="flex mb-2">
                    <div class="px-6 w-[300px]">
                        <span :class="$spa.css.table.th_text">
                            {{ $t('opportunities.archives.labels.title') }}
                        </span>
                    </div>
                    <div class="px-6 w-[300px]">
                        <span :class="$spa.css.table.th_text">
                            {{ $t('opportunities.archives.labels.client') }}
                        </span>
                    </div>
                    <div class="px-6 w-[160px]">
                        <span :class="$spa.css.table.th_text">
                            {{ $t('opportunities.archives.labels.managers') }}
                        </span>
                    </div>
                    <div class="px-6 w-[200px]">
                        <span :class="$spa.css.table.th_text">
                            {{ $t('opportunities.archives.labels.financial') }}
                        </span>
                    </div>
                    <div class="px-6 w-[200px]">

                    </div>
                    <div class="flex justify-end flex-1 px-6">
                        <span :class="$spa.css.table.th_text">
                            {{ $t('opportunities.archives.labels.actions') }}
                        </span>
                    </div>
                </div>  
                <div class="flex flex-col space-y-2">
                    <opportunity-item
                        v-for="opportunity in opportunities"
                        :key="opportunity.uuid"
                        :opportunity="opportunity"
                    />
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import OpportunityItem from '~spa/apps/trustup-pro-worksite-js/components/Opportunity/Item.vue';

    export default {
        components: {
            OpportunityItem
        },
        data() {
            return {
                opportunities: null,
                loading: true
            }
        },
        methods: {
            async getOpportunities()
            {
                let {opportunities} = await window.Worksite.endpoints.opportunity.index({archived: true});
                this.opportunities = opportunities;
                this.loading = false;
            },

            openOpportunity(opportunity)
            {
                opportunity.open();
            },
            
            async unarchiveOpportunity(opportunity)
            {
                this.$refs[`opportunityActionsSubmenu-${opportunity.uuid}`][0].hide();
                await window.Worksite.endpoints.opportunity.unarchive(opportunity.uuid);
            },

            async deleteOpportunity(opportunity)
            {
                if ( ! window.confirm(this.$t('opportunities.archive.delete_opportunity_confirmation'))) {
                    return;
                }

                this.$refs[`opportunityActionsSubmenu-${opportunity.uuid}`][0].hide();
                await window.Worksite.endpoints.opportunity.delete(opportunity.uuid);
            },

        },
        async created() {
            await this.getOpportunities();
        }
    }
</script>