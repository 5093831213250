<template>
    <div class="flex flex-col flex-1 min-h-0">
        <div class="flex flex-col flex-1 min-h-0 overflow-y-auto">
            <div class="flex-auto py-2">
                <template v-if="$store.state.account">
                    <v-spa-layout-sidebar-link section="opportunities" :to="{name: 'account.opportunities.index', params: {account: $store.state.account.uuid}}" icon="far fa-bullseye-arrow">
                        {{ $t('sidebar.opportunities_index') }}
                    </v-spa-layout-sidebar-link>
                    <v-spa-layout-sidebar-link section="worksites" :to="{name: 'account.worksites.homepage', params: {account: $store.state.account.uuid}}" icon="far fa-hard-hat">
                        {{ $t('sidebar.worksites_index') }}
                    </v-spa-layout-sidebar-link>
                    <v-spa-layout-sidebar-link section="planning" :to="{name: 'account.planning.homepage', params: {account: $store.state.account.uuid}}" icon="far fa-stream">
                        {{ $t('sidebar.planning_index') }}
                    </v-spa-layout-sidebar-link>
                    <v-spa-layout-sidebar-link section="profitability" :to="{name: 'account.profitability.homepage', params: {account: $store.state.account.uuid}}" icon="far fa-chart-line">
                        {{ $t('sidebar.profitability_index') }}
                    </v-spa-layout-sidebar-link>
                </template>
            </div>
            <div class="px-3 pt-6 pb-2">
                <div class="flex justify-center mt-2" v-if="$store.state.account && ($store.state.account.chargebee_subscription_status == 'in_trial' || ($store.state.account.chargebee && $store.state.account.chargebee.is_trial)) && $store.state.user && $store.state.user.links.activate_subscription">
                    <a :href="$store.state.user.links.activate_subscription" class="p-2 text-sm font-thin text-white bg-green-600 rounded hover:bg-green-500 sm:block">
                        <i class="lg:mr-2 far fa-check"></i>
                        <span class="hidden lg:inline-block">{{ $t('sidebar.activate_account') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
e
<script>
    export default {
        
    }
</script>
