<template>
    <div class="w-[200px] relative">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        //
    }
</script>