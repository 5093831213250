export default {
    props: {
        task: {
            type: Object,
            required: true
        },
        columns: {
            type: Object,
            required: true
        },
        column: {
            type: Object,
            required: true
        },
        defaultClass: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            clicked: false
        }
    },
    computed: {
        tdClass() {
            return this.defaultClass;
        }
    },
    methods: {
        getBaseValue()
        {
            return this.task.getColumnValue(this.column.id);
        },
        value()
        {
            return this.getBaseValue();
        },
        updated()
        {
            this.$emit('updated', this.task);
        }
    }
}
