export default class WorksiteTask {
    constructor(data = {}) {
        this.originalData = data;

        for (let field in data) {
            this[field] = data[field];
        }
    }

    data() {
        return {
            id: this.id,
            uuid: this.uuid,
            group_id: this.group_id,
            column_values: this.column_values,
            position: this.position,
        }
    }

    static default(columns, data)
    {
        data = {...{first: false, position: 1, group_id: null}, ...data};

        data.column_values = columns.map(column => {
            return {
                column_id: column.id,
                value: column.getDefaultValue(data.first)
            }
        });

        return new this(data);
    }

    getColumnValueModel(columnId)
    {
        return this.column_values.find(val => val.column_id == columnId);
    }

    getColumnValue(columnId)
    {
        return this.getColumnValueModel(columnId).value;
    }

    setColumnValue(columnId, newValue)
    {
        let model = this.getColumnValueModel(columnId);
        if ( ! model ) {
            this.column_values.push({
                column_id: columnId,
                value: newValue
            });
        } else {
            this.getColumnValueModel(columnId).value = newValue;
        }
    }

    getStatus(columns)
    {
        return this.getColumnValue(
            columns.find(c => c.type === 'status').id
        );
    }

    isCompleted(columns)
    {
        return this.getStatus(columns) == window.App.$t('worksite.tasks.columns.status.values.completed');
    }

};