import BaseEndpoint from '@spa/apps/trustup-pro-base-js/endpoints/BaseEndpoint';
import WorksiteProgressBillingCollection from './../collections/WorksiteProgressBillingCollection';
import WorksiteProgressBilling from './../models/WorksiteProgressBilling';

export default class WorksiteProgressBillingEndpoint extends BaseEndpoint
{

    constructor()
    {
        super(
            'worksite',
            {
                'path': 'worksites/worksite-uuid/progress-billings',
                'responseKey': 'billing',
                'responseKeyPlural': 'billings',
                'modelClass': WorksiteProgressBilling,
                'collectionClass': WorksiteProgressBillingCollection
            }
        );
    }

    setWorksite(worksite)
    {
        this.worksite = worksite;
        return this;
    }

    getPath()
    {
        return this.path.replace('worksite-uuid', this.worksite.uuid);
    }

    newCollection(models)
    {
        return new this.collectionClass(
            models,
            this.worksite
        );
    }

    async requestValidation(uuid, data)
    {   
        return await this.update({
            url: `${this.getPath()}/${uuid}/status/request-validation`,
            uuid,
            data
        });
    }

    async validate(uuid)
    {   
        return await this.update({
            url: `${this.getPath()}/${uuid}/status/validated`,
            uuid
        });
    }

    async invoiced(uuid, invoiceUuid)
    {   
        return await this.update({
            url: `${this.getPath()}/${uuid}/status/invoiced`,
            uuid,
            data: {
                invoice_uuid: invoiceUuid
            }
        });
    }

    async pdf(uuid)
    {
        let data = await this.request(`${this.getPath()}/${uuid}/pdf`);
        return data.pdf;
    }

}