<template>
    <div class="flex w-full" :class="verticalPadding">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            small: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            verticalPadding() {
                return this.small ? 'py-2' : 'py-6';
            }
        }
    }
</script>
