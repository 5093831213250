<template>
    <td :class="`${tdClass} h-[1px]`">
        <slot></slot>
        <v-spa-submenu-container class="h-full" position="right-0" ref="submenu" @hidden="updated">
            <template v-slot:default="slotProps">
                <div @click.prevent="slotProps.toggle()" class="h-full flex items-center justify-center px-3 py-2 text-[12px] text-center uppercase cursor-pointer" :style="`background-color: ${status.background_color}; color: ${status.color}`">
                    <span>{{ value() }}</span>
                </div>
            </template>
            <div class="w-[250px] p-2" slot="submenu">
                <a 
                    :class="value() == status.label ? 'border-2 border-primary' : ''"
                    class="block px-3 py-2 mb-2 text-[12px] text-center uppercase cursor-pointer"
                    href="#"
                    @click.prevent="selectStatus(status)"
                    :style="`background-color: ${status.background_color}; color: ${status.color}`"
                    v-for="status in column.accepted_values"
                    :key="status.label"
                >
                    {{ status.label }}
                </a>
            </div>
        </v-spa-submenu-container>
    </td>
</template>

<script>
    import Mixin from './mixin';
    
    export default {
        mixins: [Mixin],
        data() {
            return {
                status: null
            };
        },
        methods: {
            value()
            {
                return this.getBaseValue() ?? this.$t('worksite.tasks.columns.status.empty');
            },

            selectStatus(status)
            {
                this.status = status;
                this.task.setColumnValue(this.column.id, status.label);
                this.$refs.submenu.hide();
                this.updated();
            }
        },
        created() {
            this.status = this.column.accepted_values.find(status => status.label == this.value());
            if ( ! this.status ) {
                this.status = {
                    background_color: '#fff',
                    color: '#000'
                }
            }
        }
    }
</script>
