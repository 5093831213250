<template>
    <td :class="tdClass">
        <slot></slot>
        <v-spa-submenu-container position="left-0" ref="submenu" @hidden="updated">
            <template v-slot:default="slotProps">
                <div @click.prevent="slotProps.toggle()" class="cursor-pointer">
                    <div class="block min-h-[40px] min-w-[40px]" v-if="getBaseValue().length == 0">
                        <!-- <i class="mr-1 text-sm text-red-600 far fa-exclamation-circle" v-if="containsUndefinedUsers()"></i>
                        {{ $t('worksite.tasks.columns.user.empty') }} -->
                    </div>
                    <users-list-avatar v-else class="justify-center" :users="getUsers()"></users-list-avatar>
                </div>
            </template>
            <div class="w-[260px] p-2" slot="submenu">
                <span class="mb-4 block text-gray-800 text-[15px] font-medium">{{ $t('worksite.tasks.columns.user.add_user') }}</span>
                <input v-model="query" placeholder="Rechercher par nom" type="text" class="w-full px-3 py-2 mb-2 text-gray-800 transition bg-gray-100 border border-none rounded outline-none hover:border-gray-200 focus:border-primary">
                <div class="overflow-y-auto max-h-40">
                    <a :class="userIsSelected(user) ? 'text-primary' : 'text-gray-700'" class="flex items-center w-full p-[2px] text-sm rounded hover:bg-gray-100" href="#" @click.prevent="toggleUser(user)" v-for="user in queriedUsers" :key="user.id">
                        <div class="relative border-2 rounded-full" :class="userIsSelected(user) ? 'border-primary' : 'border-transparent'">
                            <div class="w-8 h-8 border-2 border-transparent rounded-full">
                                <figure class="w-full h-full">
                                    <img :src="user.avatar" :alt="user.getDisplayName()" class="object-cover w-full h-full rounded-full" />
                                </figure>
                            </div>
                            <div v-if="userIsSelected(user)" class="absolute right-0 bottom-0 transform translate-x-1/2 translate-y-[.15rem] h-4 w-4 rounded-full flex bg-primary items-center justify-center">
                                <i class="fas fa-check text-[.5rem] text-white"></i>
                            </div>
                        </div>
                        <span class="ml-3" :class="{'font-medium': userIsSelected(user)}">{{ user.getFullName() }}</span>
                    </a>
                </div>
            </div>
        </v-spa-submenu-container>
    </td>
</template>

<script>
    import Mixin from './mixin';
    import {xor} from 'lodash';
    import UsersListAvatar from '~spa/apps/trustup-pro-base-js/components/_Partials/UsersListAvatar';

    export default {
        components: { UsersListAvatar },
        
        mixins: [Mixin],
        
        data() {
            return {
                query: '',
            };
        },

        computed: {
            queriedUsers() {
                if ( ! this.query ) {
                    return this.$store.state.models.users;
                }

                return this.$store.state.models.users.filter(user => {
                    return user.getFullName().toLowerCase().includes(this.query.toLowerCase());
                });
            }
        },

        methods: {
            getBaseValueWithoutUndefinedUsers()
            {
                return this.getBaseValue().filter(id => {
                    return this.$store.state.models.users.find(user => user.id == id) !== undefined;
                });
            },
            
            containsUndefinedUsers()
            {
                if ( this.getBaseValue().length == 0 ) {
                    return false;
                }

                return this.getBaseValue() > this.getBaseValueWithoutUndefinedUsers();
            },
            
            value()
            {
                return null;
                if ( this.getBaseValue().length == 0 ) {
                    return this.$t('worksite.tasks.columns.user.empty');
                }
                
                let value = this.getBaseValue().slice(0, 2).map(id => {
                    let user = this.$store.state.models.users.find(user => user.id == id);
                    return user ? user.getDisplayName() : this.$t('worksite.tasks.columns.user.not_found');
                }).join(', ');

                if ( this.getBaseValue().length > 2 ) {
                    value += ' et plus';
                }

                return value;
            },

            getUsers()
            {
                return this.getBaseValue().map(id => {
                    return this.$store.state.models.users.find(user => user.id == id);
                }).filter(u => u !== undefined);
            },

            userIsSelected(user)
            {
                if ( ! this.getBaseValue() ) {
                    return false;
                }
                
                return this.getBaseValue().some(id => id == user.id);
            },

            toggleUser(user)
            {
                this.task.setColumnValue(this.column.id, xor(this.getBaseValueWithoutUndefinedUsers(), [user.id]));
            },

            updated()
            {
                this.$emit('updated', this.task);
                this.query = '';
            }
        }
    }
</script>
