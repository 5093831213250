<template>
    <div>
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            <template v-if="worksites.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('profitability.index.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('profitability.index.empty.text') }}</p>
                </div>
            </template> 
            <template v-else>
                <h2 class="mb-4 text-2xl text-medium">{{ $t('profitability.index.profitability.title') }}</h2>
                <amounts-panels 
                    ref="amountsPanels"

                    :display-links="false"

                    :total-quoted="profitability.quoted"
                    :title-quoted="$t('profitability.index.panels.quoted.title')"

                    :total-costs="profitability.costs"
                    :title-costs="$t('profitability.index.panels.costs.title')"

                    :positive-liquidity="profitability.liquidity > 0"
                    :total-liquidity="profitability.liquidity"
                    :title-liquidity="$t('profitability.index.panels.liquidity.title')"

                    :positive-margin="profitability.margin > 0"
                    :total-margin="profitability.margin"
                    :title-margin="$t('profitability.index.panels.margin.title')"
                ></amounts-panels>
                <div class="mb-8" v-if="currentWorksites.length > 0">
                    <h2 class="mb-4 text-2xl text-medium">{{ $t('profitability.index.current.title') }}</h2>
                    <worksites-index-list @hidden="hideWorksite" :worksites="currentWorksites"></worksites-index-list>
                </div>
                <div class="mb-8" v-if="futureWorksites.length > 0">
                    <h2 class="mb-4 text-2xl text-medium">{{ $t('profitability.index.future.title') }}</h2>
                    <worksites-index-list @hidden="hideWorksite" :worksites="futureWorksites"></worksites-index-list>
                </div>
            </template>
        </template>
    </div>
    
</template>

<script>
    import AmountsPanels from './AmountsPanels';
    import WorksitesIndexList from './Index/_Worksites';

    export default {
        components: {
            AmountsPanels,
            WorksitesIndexList
        },
        data() {
            return {
                worksites: null,
                profitability: null,
                year: null,
                loading: true
            }
        },
        computed: {
            currentWorksites()
            {
                return this.worksites.filter(w => w.isCurrent()).sort((a, b) => {
                    return new Date(a.starts_at) - new Date(b.starts_at);
                });
            },
            futureWorksites()
            {
                return this.worksites.filter(w => w.isFuture()).sort((a, b) => {
                    return new Date(a.starts_at) - new Date(b.starts_at);
                });
            }
        },
        methods: {
            async getProfitability()
            {
                let {worksites} = await window.Worksite.endpoints.worksite.index();
                this.worksites = worksites;
                this.calculateProfitability();
            },
            calculateProfitability()
            {
                this.profitability = {
                    quoted: this.currentWorksites.reduce((acc, w) => {
                        return acc + w.total_quoted;
                    }, 0),
                    costs: this.currentWorksites.reduce((acc, w) => {
                        return acc + w.total_costs;
                    }, 0),
                    invoiced: this.currentWorksites.reduce((acc, w) => {
                        return acc + w.total_invoiced;
                    }, 0),
                    invoiced_paid: this.currentWorksites.reduce((acc, w) => {
                        return acc + w.total_invoiced_paid;
                    }, 0),
                };
                this.profitability.liquidity = this.profitability.invoiced_paid - this.profitability.costs;
                this.profitability.margin = this.profitability.quoted - this.profitability.costs;
            },
            hideWorksite()
            {
                //
            }
        },
        async created() {
            this.year = window.moment().year();
            await this.getProfitability();
            this.loading = false;
        }
    }
</script>