 Z<template>
    <div class="mb-12">
        <div class="-mb-10">
            <h2 class="text-xl font-semibold">
                <v-spa-submenu-container class="inline-block h-full group" :container="$spa.css.submenu.container.default" position="left-0" ref="groupActionsSubmenu">
                    <template v-slot:default="slotProps">
                        <span class="relative inline-block py-2 pr-8">
                            <span v-if="! renaming">{{ group.title }}</span>
                            <input ref="input" v-if="renaming" class="text-[14px] w-full py-0.5 px-[3px] bg-white text-gray-700 border border-dashed border-gray-600 focus:outline-none" v-model="group.title" @blur="updateGroupTitle" @keyup.enter="updateGroupTitle" @keyup.esc="updateGroupTitle" />
                            <div class="absolute top-0 bottom-0 items-center hidden -ml-4 space-x-1 text-sm cursor-pointer group-hover:flex left-full" @click.prevent="slotProps.toggle()">
                                <i class="text-sm fas fa-ellipsis-h" />
                            </div> 
                        </span>
                        <div class="absolute top-0 bottom-0 items-center hidden pr-2 cursor-move group-handle group-hover:flex right-full">
                            <i class="-mt-[2px] text-sm fas fa-arrows" />
                        </div>
                    </template>
                        
                    <div class="relative w-auto" :container="$spa.css.submenu.container.default" slot="submenu">
                        <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="startRenamingGroup()">
                            <i :class="`${$spa.css.contextmenu.icon} far fa-pencil`" />
                            {{ $t('worksite.tasks.group_actions.rename') }}
                        </a>
                        <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="duplicateGroup()">
                            <i :class="`${$spa.css.contextmenu.icon} far fa-copy`" />
                            {{ $t('worksite.tasks.group_actions.duplicate') }}
                        </a>
                        <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="duplicateGroupWithItems()">
                            <i :class="`${$spa.css.contextmenu.icon} far fa-copy`" />
                            {{ $t('worksite.tasks.group_actions.duplicate_with_items') }}
                        </a>
                        <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="deleteGroup()" v-if="index > 0">
                            <i :class="`${$spa.css.contextmenu.icon} far fa-trash`" />
                            {{ $t('worksite.tasks.group_actions.delete') }}
                        </a>
                    </div>
                </v-spa-submenu-container>
            </h2>
        </div>
        <table class="w-full border-separate border-spacing-[5px] -mx-[5px]" style="width: calc(100% + 10px)">
            <thead>
                <draggable
                    tag="tr"
                    @end="onColumnsReorder"
                    v-model="columns.items"
                    handle=".column-handle"
                >
                    <worksite-task-column :display-title="index > 1" :column="column" @updated="refreshColumn" @duplicated="duplicateColumn" @deleted="deleteColumn" v-for="(column, index) in columns" :key="column.id"></worksite-task-column>
                    <worksite-add-task-column ref="addColumn" @clicked="addColumnOfType" :loading="loading.column" :column-types="columnTypes"></worksite-add-task-column>
                </draggable>
            </thead>
            <draggable
                tag="tbody"
                @change="onTasksReorder"
                group="group-tasks"
                v-model="group.tasks.items"
                handle=".row-handle"
            >
                <tr class="group" v-for="task in group.tasks" :key="task.id">
                    <component
                        :is="`worksite-task-${column.type}-value`"
                        :task="task"
                        :column="column"
                        :columns="columns"
                        v-for="(column, index) in columns"
                        :key="`${task.id}-${column.id}`"
                        :default-class="columnValueClass(column, task)"
                        @updated="refreshTask"
                    >
                        <div v-if="index == 0" class="absolute pr-2 opacity-0 group-hover:opacity-100 right-full">
                            <div class="items-center flex justify-center w-[26px] h-[26px] bg-white rounded cursor-move row-handle text-gray-6 hover:text-black">
                                <i class="-mt-[2px] text-sm fas fa-arrows" />
                            </div>
                        </div>
                    </component>
                    <td :class="`${columnValueClass()} h-[1px]`">
                        <v-spa-submenu-container class="h-full" :container="$spa.css.submenu.container.default" position="right-0" :ref="`taskActionsSubmenu-${task.uuid}`">
                            <template v-slot:default="slotProps">
                                <div class="flex items-center justify-center h-full px-4 py-2 text-gray-600 bg-gray-100 cursor-pointer hover:bg-gray-200 hover:text-black" @click.prevent="slotProps.toggle()">
                                    <i class="text-sm fas fa-ellipsis-h" />
                                </div>
                            </template>
                            
                            <div class="relative w-auto" :container="$spa.css.submenu.container.default" slot="submenu">
                                <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="duplicateTask(task)">
                                    <i :class="`${$spa.css.contextmenu.icon} far fa-copy`" />
                                    {{ $t('worksite.tasks.task_actions.duplicate') }}
                                </a>
                                <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="deleteTask(task)">
                                    <i :class="`${$spa.css.contextmenu.icon} far fa-trash`" />
                                    {{ $t('worksite.tasks.task_actions.delete') }}
                                </a>
                            </div>
                        </v-spa-submenu-container>
                    </td>
                </tr>
                <tr class="relative bg-gray-100" v-if="loading.task">
                    <td class="py-6 bg-gray-100"><v-spa-loader background-color="bg-gray-100"></v-spa-loader></td>
                </tr>
            </draggable>
        </table>
        <div class="mt-2">
            <v-spa-loading-button color="bg-gray-100 hover:bg-gray-300 text-gray-600 hover:text-black" @clicked="addNewTask">{{ $t('worksite.tasks.add_button') }}</v-spa-loading-button>
        </div>
    </div>
</template>


<script>
    import draggable from 'vuedraggable'
    import WorksiteTask from '~base/models/WorksiteTask';
    import WorksiteTaskColumn from '~base/models/WorksiteTaskColumn';
    import WorksiteAddTaskColumn from './AddColumn';
    import WorksiteTaskColumnComponent from './Column';
    import WorksiteTaskDateValue from './Value/Date';
    import WorksiteTaskTextValue from './Value/Text';
    import WorksiteTaskCommentValue from './Value/Comment';
    import WorksiteTaskUserValue from './Value/User';
    import WorksiteTaskStatusValue from './Value/Status';
    
    export default {

        components: {
            draggable,
            'worksite-add-task-column': WorksiteAddTaskColumn,
            'worksite-task-column': WorksiteTaskColumnComponent,
            'worksite-task-date-value': WorksiteTaskDateValue,
            'worksite-task-text-value': WorksiteTaskTextValue,
            'worksite-task-comment-value': WorksiteTaskCommentValue,
            'worksite-task-user-value': WorksiteTaskUserValue,
            'worksite-task-status-value': WorksiteTaskStatusValue
        },
        
        props: {
            group: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            columns: {
                type: Object,
                required: true
            },
            columnTypes: {
                type: Array,
                required: true
            }
        },
        
        data() {
            return {
                renaming: false,
                loading: {
                    base: true,
                    task: false,
                    column: false
                },
            }
        },

        methods: {
            columnValueClass(column = null, task = null)
            {
                if ( ! column || column.type == 'status' ) {
                    return '';
                }
                
                return 'px-4 py-2 bg-gray-100';
            },

            async createColumns()
            {
                let columns = WorksiteTaskColumn.defaults();
                let promises = [];
                for ( let i = 0; i < columns.length(); i++ ) {
                    promises.push(
                        window.Worksite.endpoints.worksiteTaskColumn.setWorksite(this.$store.state.worksite).store( columns[i].data() )
                    );
                }

                this.columns = await Promise.all(promises);
            },

            async addColumnOfType(type)
            {
                this.loading.column = true;
                let data = WorksiteTaskColumn[type]({position: this.columns.length() + 1}).data();
                let column = await window.Worksite.endpoints.worksiteTaskColumn.setWorksite(this.$store.state.worksite).store(data)
                await this.addDefaultValueToExistingTasksForNewColumn(column);
                this.loading.column = false;
                this.$refs.addColumn.$refs.submenu.hide();
            },

            async storeTask(data)
            {
                return await window.Worksite.endpoints.worksiteTask.setWorksite(this.$store.state.worksite).store(data);
            },

            async addTask(data = WorksiteTask.default(this.columns, {group_id: this.group.id, position: this.group.tasks.length() + 1}).data())
            {
                await this.storeTask(data);
            },
            
            async addNewTaskInGroup(group)
            {
                await this.addNewTask();
            },

            async addNewTask()
            {
                this.loading.task = true;
                await this.addTask();
                this.loading.task = false;
            },

            async deleteTask(task)
            {
                this.$refs[`taskActionsSubmenu-${task.uuid}`][0].hide();
                
                this.group.tasks = this.group.tasks.filter(c => c.uuid !== task.uuid);
                await window.Worksite.endpoints.worksiteTask.setWorksite(this.$store.state.worksite).delete(task.uuid);
            },

            async duplicateTask(task)
            {
                this.$refs[`taskActionsSubmenu-${task.uuid}`][0].hide();
                
                this.loading.task = true;
                await this.addTask({...task.data(), ...{position: this.group.tasks.length() + 1}});
                this.loading.task = false;
            },

            async addDefaultValueToExistingTasksForNewColumn(column)
            {
                let promises = [];
                for ( let i = 0; i < this.group.tasks.length(); i++ ) {
                    this.group.tasks[i].setColumnValue(column.id, column.getDefaultValue());
                    promises.push( this.refreshTask(this.group.tasks[i]) );
                }

                await Promise.all(promises);
            },

            async refreshTask(updatedTask)
            {
                this.group.tasks = this.group.tasks.map(task => task.id !== updatedTask.id ? task : updatedTask);

                await window.Worksite.endpoints.worksiteTask.setWorksite(this.$store.state.worksite).update({uuid: updatedTask.uuid, data: updatedTask.data()});
            },

            async refreshColumn(updatedColumn)
            {
                this.columns = this.columns.map(column => column.id !== updatedColumn.id ? column : updatedColumn);

                await window.Worksite.endpoints.worksiteTaskColumn.setWorksite(this.$store.state.worksite).update({uuid: updatedColumn.uuid, data: updatedColumn.data()});
            },

            async duplicateColumn(column)
            {
                this.loading.column = true;
                let data = WorksiteTaskColumn[column.type]({position: this.columns.length() + 1}).data();
                let newColumn = await window.Worksite.endpoints.worksiteTaskColumn.setWorksite(this.$store.state.worksite).store(data);
                await this.addDefaultValueToExistingTasksForNewColumn(newColumn);
                this.loading.column = false;
            },

            async deleteColumn(column)
            {
                this.columns = this.columns.filter(c => c.uuid !== column.uuid);

                await window.Worksite.endpoints.worksiteTaskColumn.setWorksite(this.$store.state.worksite).delete(column.uuid);
            },

            startRenamingGroup()
            {
                this.renaming = true;
                this.$refs.groupActionsSubmenu.hide();
            },

            async duplicateGroup()
            {
                this.$refs.groupActionsSubmenu.hide();
                this.$emit('duplicate', {group: this.group, position: this.index + 1});
            },

            async duplicateGroupWithItems()
            {
                this.$refs.groupActionsSubmenu.hide();
                this.$emit('duplicate-with-items', {group: this.group, position: this.index + 1});
            },

            async deleteGroup()
            {
                if ( ! window.confirm( this.$t('worksite.tasks.delete_group_confirm') ) ) {
                    return;
                }
                
                this.$emit('deleted', this.group);
                await window.Worksite.endpoints.worksiteTaskGroup.setWorksite(this.$store.state.worksite).delete(this.group.uuid);
            },
            
            onTasksReorder(event)
            {
                console.log('Wow, tasks reordered event', event, this.group);
                window.Worksite.endpoints.worksiteTask.setWorksite(this.$store.state.worksite).reorder({group_id: this.group.id, tasks: this.group.tasks.map(s => s.uuid)});
            },

            onColumnsReorder()
            {
                window.Worksite.endpoints.worksiteTaskColumn.setWorksite(this.$store.state.worksite).reorder(this.columns.map(c => c.uuid));
            },

            async updateGroupTitle()
            {
                this.renaming = false;
                window.Worksite.endpoints.worksiteTaskGroup.setWorksite(this.$store.state.worksite).update({uuid: this.group.uuid, data: this.group.data()});
            }
        }
    }
</script>

<style>
    .sortable-chosen.sortable-ghost {
        opacity: 0;
    }
    .sortable-fallback {
        opacity: .85 !important;
    }
    .sortable-drag {
        background: white;
        border-radius: 0.5rem;
    }
    .sortable-drag td {
        background: white;
    }
    .sortable-drag td:first-of-type {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
    .sortable-drag td:last-of-type {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
</style>
