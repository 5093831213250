<template>
    <v-spa-layout-page>
        <template slot="title">{{ $t('profitability.index.title') }}</template>
        <router-view slot="content" :key="'profitability-' + $route.fullPath"></router-view>
    </v-spa-layout-page>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        }
    }
</script>
