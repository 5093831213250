<template>
    <div class="flex w-full" :class="borderBottom">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            borderBottom: {
                type: String,
                default: 'border-b border-gray-300'
            }
        }
    }
</script>
