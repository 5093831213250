<template>
    <v-spa-layout-page
        :tabs="tabs"
    >
        <template slot="title">{{ $t('worksites.index.title') }}</template>
        <v-spa-loading-button slot="title-button" v-if="$route.name === 'account.worksites.index'" extra-class="ml-4" @clicked="$bus.$emit('worksite:worksite-form-modal:open')">
            {{ $t('worksites.index.create_button') }}
        </v-spa-loading-button>

        <router-view slot="content" :key="'worksites-' + $route.fullPath"></router-view>
    </v-spa-layout-page>
</template>

<script>
    import {mapState} from 'vuex';
    import TitleTab from '~base/components/_Partials/TitleTab.vue';

    export default {
        components: {
            TitleTab
        },
        data() {
            return {
                tabs: [
                    {
                        name: 'account.worksites.index',
                        label: this.$t('worksites.index.tabs.index'),
                    },
                    {
                        name: 'account.worksites.archives',
                        label: this.$t('worksites.index.tabs.archives'),
                    }
                ]
            }
        },
        methods: {
        }
    }
</script>
