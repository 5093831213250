import BaseCollection from '@spa/apps/trustup-pro-base-js/collections/BaseCollection';
import WorksiteProgressBilling from './../models/WorksiteProgressBilling';

export default class WorksiteProgressBillingCollection extends BaseCollection
{

    constructor(items, worksite)
    {
        super(items, {
            modelClass: WorksiteProgressBilling
        });

        this.worksite = worksite;
    }

    getQuoteLineData(line)
    {
        return this.items.map(i => i.lines.find(l => l.worksite_progress_billing_quote_line_id == line.id));
    }

    getLineUnitInternal(line)
    {
        let data = this.getQuoteLineData(line);
        return data.find(l => l.is_percentage == true)
            ? 'custom-unit-percentage'
            : line.unit;
    }

    getLineUnit(line)
    {
        let unit = this.getLineUnitInternal(line);
        return unit == 'custom-unit-percentage'
            ? '%'
            : unit;
    }

    getLineQuantity(line)
    {
        return this.getQuoteLineData(line).reduce((quantity, l) => quantity + l.quantity, 0);
    }

    getLineTotal(line)
    {
        return this.getQuoteLineData(line).reduce((total, l) => total + l.total, 0);
    }

}