<template>
    <div :class="{'p-12': loading || (worksites && worksites.isEmpty())}">
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            
            <template v-if="worksites.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('planning.worksites.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('planning.worksites.empty.text') }}</p>
                </div>
            </template> 
            <template v-else>
                <timeline :resources="timelineResources" :events="timelineEvents"></timeline>
            </template>
        </template>
    </div>
    
</template>

<script>
    import Timeline from './_Timeline';

    export default {
        components: {
            Timeline
        },
        data() {
            return {
                worksites: [],
                timelineResources: null,
                timelineEvents: null,
                loading: true
            }
        },
        methods: {
            async getWorksites()
            {
                let {worksites} = await window.Worksite.endpoints.worksitePlanning.index();
                this.worksites = worksites;
                this.worksites.map((worksite) => {
                    return this.getWorksiteTimelineData(worksite);
                });
            },

            getWorksiteTimelineData(worksite)
            {
                worksite.children = this.getWorksiteChildren(worksite);
                worksite.timeline_events = this.getWorksiteTimelineEvents(worksite);

                return worksite;
            },

            getWorksiteChildren(worksite)
            {
                let children = [];

                // worksite.tasks.map((task, index) => {
                //     children.push({
                //         id: `task-${task.uuid}`,
                //         title: task.title,
                //         subtitle: task.group,
                //         model: {
                //             type: 'task',
                //             data: task
                //         }
                //     });
                // });

                worksite.phases.items.map((phase, index) => {
                    // children.push({
                    //     id: `phase-${phase.uuid}`,
                    //     title: phase.title,
                    //     model: {
                    //         type: 'phase',
                    //         data: phase
                    //     }
                    // });

                    phase.trustup_io_tasks.filter(trustupIoTask => trustupIoTask.due_date !== null).map((trustupIOTask, taskIndex) => {
                        children.push({
                            id: `trustup-io-task-${trustupIOTask.uuid}`,
                            title: trustupIOTask.title,
                            subtitle: phase.title,
                            model: {
                                type: 'trustup-io-task',
                                data: trustupIOTask
                            }
                        });
                    });
                });

                return children.map((child, index) => {
                    return {
                        ...child,
                        position: index,
                        borderId: index == children.length - 1 ? null : worksite.uuid,
                        isLastChild: index == children.length - 1,
                        classNames: 'fc-timeline-resource-child',
                        is_child: true,
                    }
                });
            },

            getWorksiteTimelineEvents(worksite)
            {
                return worksite.children.map((child, index) => {
                    let event = {
                        position: index,
                        resourceId: child.id,
                        borderId: child.borderId,
                        backgroundColor: '#e2e2e2',
                        borderColor: '#e2e2e2',
                        textColor: 'black',
                        classNames: `fc-timeline-event-${child.model.type}`
                    };

                    if (child.model.type == 'task') {
                        event.title = child.model.data.status.label;
                        event.start = child.model.data.starts_at;
                        event.end = child.model.data.ends_at;
                        event.status = child.model.data.status;
                        event.link = this.$router.resolve({name: 'account.worksite.tables', params: {worksite: worksite.uuid}}).href;
                    }

                    if (child.model.type == 'trustup-io-task') {
                        event.title = child.model.data.title;
                        event.link = this.$router.resolve({name: 'account.worksite.phases', params: {worksite: worksite.uuid}}).href;
                        if ( child.model.data.due_date ) {
                            event.start = child.model.data.due_date;
                            event.end = child.model.data.due_date;
                        } else {
                            event.start = worksite.starts_at;
                            event.end = worksite.starts_at;
                        }
                        event.status = child.model.data.status;
                        event.textColor = child.model.data.status.background_color;
                        console.log(event);
                    }

                    if (child.model.type == 'phase') {
                        event.title = child.model.data.title;
                        event.start = child.model.data.starts_at;
                        event.end = child.model.data.ends_at;
                        event.link = this.$router.resolve({name: 'account.worksite.phases', params: {worksite: worksite.uuid}}).href;
                        event.backgroundColor = '#6b88ef';
                        event.borderColor = '#6b88ef';
                        event.textColor = '#FFFFFF';
                    }

                    return event;
                });
            },

            async setupTimeline()
            {
                this.timelineResources = this.worksites.map((worksite, index) => {
                    return {
                        id: `worksite-${worksite.uuid}`,
                        title: worksite.title,
                        hasChildren: worksite.children.length > 0,
                        classNames: 'fc-timeline-resource-parent',
                        borderId: worksite.uuid,
                        position: index,
                        children: worksite.children
                    }
                });
                this.timelineEvents = this.worksites.map((worksite, index) => {
                    return {
                        position: index,
                        resourceId: `worksite-${worksite.uuid}`,
                        borderId: worksite.uuid,
                        title: this.$t('misc.date_range', {start: this.$moment(worksite.starts_at).format('DD MMMM YYYY'), end: this.$moment(worksite.ends_at).format('DD MMMM YYYY')}),
                        start: worksite.starts_at,
                        end: worksite.ends_at,
                        link: this.$router.resolve({name: 'account.worksite.homepage', params: {worksite: worksite.uuid}}).href,
                    }
                });
                for ( let i = 0; i < this.worksites.items.length; i++ ) {
                    for ( let n = 0; n < this.worksites.items[i].timeline_events.length; n++ ) {
                        this.timelineEvents.push(this.worksites.items[i].timeline_events[n]);
                    }
                }
            }
        },
        async created() {
            await this.getWorksites();
            await this.setupTimeline();
            this.loading = false;
        }
    }
</script>