<template>
    <div class="relative worksite-phases-timeline-container">
        <div v-if="innerLoading" class="absolute inset-0 z-10 flex items-center justify-center bg-white">
            <div class="relative py-12 w-[200px]">
                <v-spa-loader></v-spa-loader>
            </div>
        </div>

        <div class="relative w-full overflow-x-scroll worksite-phases-timeline">

            <worksite-phases-gantt
                :worksite="worksite"
                :phases="phases"
                ref="gantt"
                @initialized="displayDays = true"
            ></worksite-phases-gantt>
        
            <div class="flex items-stretch" :style="`width: ${$refs.gantt.gantt.totalWidth()}px`" v-if="displayDays">
                <timeline-day
                    :day="day"
                    :index="index"
                    :day-width="$refs.gantt.gantt.dayWidth"
                    :is-first="index == 0"
                    :is-last="index == $refs.gantt.gantt.totalDaysArray().length - 1"
                    v-for="(day, index) in $refs.gantt.gantt.totalDaysArray()"
                    :key="$moment(day).format('YYYY-MM-DD')"
                    :ref="`day-${$moment(day).format('YYYY-MM-DD')}`"
                >
                    <div
                        class="flex p-2 space-x-2 bg-white rounded shadow cursor-pointer hover:shadow-lg"
                        v-for="task in dayTasks(day)"
                        :key="task.uuid"
                        @contextmenu.prevent="task.contextMenu($event)"
                        @click.prevent="task.preview()"
                    >
                        <div @click.stop.prevent="updateTaskDoneAt(task)" class="flex-shrink-0 cursor-pointer h-[20px] w-[20px] rounded-full flex items-center justify-center" :class="task.done_at ? 'bg-green-500' : 'border border-gray-200'">
                            <i class="text-xs text-white far fa-check"></i>
                        </div>

                        <div class="flex-col flex-1 space-y-1">
                            <span class="block text-sm leading-tight" :class="{'line-through': task.done_at}">
                                {{ task.title }}
                            </span>
                            <users-list-avatar negative-margin="-ml-2" size="w-[20px] h-[20px]" v-if="task.users && task.users.length > 0" :users="task.users" />
                            <task-due-date :task="task" class-name="text-[11px]" v-if="task.due_date" />
                        </div>
                    </div>
                    <div class="flex items-center justify-center py-2">
                        <a
                            href="#"
                            class="text-gray-600 hover:text-black"
                            @click.prevent="$bus.$emit('worksite:worksite-phase-trustup-io-task-form-modal:open', {
                                form: {due_date: day.format('YYYY-MM-DD'), users: [$store.state.user]}
                            })"
                        >
                            <i class="far fa-plus-circle"></i>
                        </a>
                    </div>
                </timeline-day>
            </div>
        </div>
    </div>
</template>

<script>
    import GanttView from '../_Gantt.vue';
    import TimelineDay from './Day.vue';
    import TaskDueDate from '~spa/apps/trustup-pro-base-js/components/TrustUpIO/Task/Item/DueDate.vue';
    import UsersListAvatar from '~spa/apps/trustup-pro-base-js/components/_Partials/UsersListAvatar';

    export default {
        components: {
            TimelineDay,
            TaskDueDate,
            UsersListAvatar,
            'worksite-phases-gantt': GanttView
        },
        props: {
            worksite: {
                type: Object,
                required: true
            },
            phases: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                innerLoading: true,
                phaseHeight: 40,
                phaseHeightSeparation: 10,
                gantt: null,
                displayDays: false
            }
        },
        watch: {
            phases() {
                console.log('Watch');
                this.setup();
            }
        },
        methods: {
            dayTasks(day)
            {
                // Get tasks where the due_date is the same as the day on each phase
                return this.phases.reduce((tasks, phase) => {
                    return tasks.concat(
                        phase.trustup_io.tasks.tasks.filter(task => this.$moment(task.due_date).isSame(day, 'day')).map(task => {
                            task.phase = phase;
                            return task;
                        })
                    );
                }, []);
            },
            
            findBestDayRef()
            {
                if ( ! this.$refs.gantt || ! this.displayDays ) {
                    return;
                }

                let day = this.$refs.gantt.gantt.findBestDay();
                if ( day && this.$refs[`day-${day}`] ) {
                    return this.$refs[`day-${day}`][0]
                }
            },

            setup()
            {
                if ( this.$refs.gantt ) {
                    this.$refs.gantt.init();
                }
            },
            
            async updateTaskDoneAt(task)
            {
                task.done_at = task.done_at ? null : this.$moment().format('YYYY-MM-DD HH:mm:ss');
                await this.task.getEndpoint().update({uuid: this.task.uuid, data: this.task.data()});
            },
        },

        mounted()
        {
            this.setup();
            
            setTimeout(() => {
                let day = this.findBestDayRef();
                console.log('Found day ref', day);
                if ( ! day ) {
                    this.innerLoading = false;
                    return;
                }

                day.$el.scrollIntoView({
                    inline: 'start',
                    block: 'nearest'
                });
                document.querySelector('.v-spa-layout-content .overflow-y-auto').scrollTop = 0
                this.innerLoading = false;
            }, 500);
        }
    }
</script>