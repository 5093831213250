<template>
    <div
        class="fixed inset-0 z-[1011] flex items-center justify-center w-full h-full"
        @dragenter.prevent
        @dragover.prevent
        @drop="onDrop"
        v-if="$store.state.worksite && ($store.state.pages.dropzone.dropping || $store.state.pages.dropzone.uploading)"
    >
        <div class="fixed inset-0 z-[21]" v-show="$store.state.pages.dropzone.uploading">
            <lottie json="https://shared-assets.trustup.io/lottie/uploading.json" container-class-name="lottie-uploading-animation-container"></lottie>
        </div>
        <div class="relative z-20 text-center cursor-pointer">
            <form>
                <label for="dropdown-file-input" class="cursor-pointer">
                    <div class="max-w-[300px] min-h-[300px]" v-show="! $store.state.pages.dropzone.uploading">
                        <div class="flex justify-center mb-4">
                            <lottie json="https://shared-assets.trustup.io/lottie/upload.json" container-class-name="lottie-upload-animation-container"></lottie>
                            <input ref="uploadFileInput" multiple id="dropdown-file-input" class="hidden" name="dropdown-file-input" type="file" @change="onFileUpload" />
                        </div>
                    </div>
                    <!-- <i class="fal fa-cloud-upload mb-4 text-[100px] text-gray-600"></i> -->
                    <p class="text-xl text-gray-800">
                        {{ $store.state.pages.dropzone.uploading ? $t('worksite.files.drop.uploading') : $t('worksite.files.drop.text') }}
                    </p>
                    <!-- <v-spa-loader v-if="$store.state.pages.dropzone.uploading"></v-spa-loader> -->
                </label>
            </form>
        </div>
        <div class="absolute top-0 right-0 z-20 pt-12 pr-12">
            <i class="text-xl text-gray-600 cursor-pointer far fa-times" @click.prevent="$store.state.pages.dropzone.dropping = false"></i>
        </div>
        <div class="absolute inset-0 bg-white opacity-80"></div>
    </div>
</template>

<script>

    import Lottie from '~spa/apps/trustup-pro-base-js/components/Lottie';

    export default {
        components: {
            Lottie,
        },
        methods: {
            async onDrop(event)
            {
                event.preventDefault();

                if ( event.dataTransfer.getData('URL') ) {
                    await this.uploadFileFromUrl( event.dataTransfer.getData('URL') );
                    return;
                }

                await this.uploadFiles(event.dataTransfer.files);
            },

            async uploadFileFromUrl(url)
            {
                try {
                    var blob = await fetch(url).then(r => r.blob());
                } catch (error) {
                    console.error(error);
                    this.$store.state.pages.dropzone.uploading = false;
                    this.$store.state.pages.dropzone.dropping = false;
                    window.Toasteo.error('Une erreur est survenue lors du téléchargement du fichier (t)');
                    return;
                }
            
                let file = new File([blob], url.split('/').pop(), {type: blob.type})
                console.log(url, file, blob);
                await this.uploadFiles([file]);
            },

            async onFileUpload(event)
            {
                event.preventDefault();
                await this.uploadFiles(event.target.files);
            },

            async uploadFiles(files)
            {
                this.$store.state.pages.dropzone.uploading = true;

                let promises = [];
                for ( let i = 0; i < files.length; i++ ) {
                    promises.push( this.uploadFile( files[i] ) );
                }

                let uploadedFiles = await Promise.all(promises);
                this.$store.state.pages.dropzone.dropping = false;
                this.$store.state.pages.dropzone.uploading = false;
                // this.onFilesCreation(uploadedFiles);
            },

            async uploadFile(file)
            {
                let data = new FormData;
                data.append('file', file);
                data.append('folder', this.$store.state.pages.worksite.files.folder);
                
                try {
                    let uploadedFile = await window.Worksite.endpoints.worksiteFile
                        .setWorksite(this.$store.state.worksite)
                        .store(data);
                    return uploadedFile
                } catch (error) {
                    console.log(error);
                    window.Toasteo.error('Une erreur est survenue lors du téléchargement du fichier (t)');
                    return null;
                }
            },

            onFileCreation()
            {
                window.Toasteo.success(
                    this.$t('worksite.files.uploaded')
                );
            },

            async startUploading()
            {
                this.$store.state.pages.dropzone.dropping = true;
                await this.$nextTick();
                this.$refs.uploadFileInput.click();
            },

            closeOnEscape(event)
            {
                if ( event.keyCode === 27 ) {
                    this.$store.state.pages.dropzone.dropping = false;
                }
            }
        },
        created()
        {
            var body = document.querySelector("body");
            body.ondragleave = (e) => {
                console.log('dropping right now', e.clientX > 0 && e.clientX < body.clientWidth && e.clientY > 0 && e.clientY < body.clientHeight);
                this.$store.state.pages.dropzone.dropping = e.clientX > 0 && e.clientX < body.clientWidth && e.clientY > 0 && e.clientY < body.clientHeight;
            }
            this.$bus.$on('worksite:worksite-file:created', this.onFileCreation);
            this.$bus.$on('worksite:worksite-file:upload', this.startUploading);
            document.addEventListener('keyup', this.closeOnEscape);
        },
        beforeDestroy()
        {
            document.removeEventListener('dragstart', this.handleBodyDrag);
            document.removeEventListener('keyup', this.closeOnEscape);
            this.$bus.$off('worksite:worksite-file:upload', this.startUploading);
            this.$bus.$off('worksite:worksite-file:created', this.onFileCreation);
        }
    }
</script>