export default class Account {
    constructor(data = {}) {

        for (let field in data) {
            this[field] = data[field];
        }

    }

    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }
};