<template>
    <td :class="tdClass">
        <slot></slot>
        <span @click.prevent="onClick()" class="py-0.5 text-center justify-center px-[3px] flex items-center cursor-pointer hover:text-black text-sm" :class="{'text-primary': count() > 0}">
            <template v-if="count() == 0">
                {{ $t('worksite.tasks.columns.comment.empty') }}
            </template>
            <template v-else>
                <i class="mr-2 text-primary fas fa-comment" />
                {{ $t('worksite.tasks.columns.comment.open') }}
            </template>
        </span>
        <portal to="worksite-task-comments-modal-content">
            <div v-if="clicked">
                <h2 class="mb-8 text-xl font-medium text-gray-800">{{ $t('worksite.tasks.columns.comment.modal.title') }}</h2>
                <div class="flex flex-col space-y-4">
                    <div class="flex w-full p-4 bg-white border border-gray-200 rounded group" v-for="comment in comments()" :key="comment.uuid">
                        <div class="flex-shrink-0 w-10">
                            <div class="w-6 h-6 mt-2 bg-cover rounded-full" :style="`background-image: url(${$store.getters.getUserAvatar(comment.user_id)})`"></div>
                        </div>
                        <div class="w-full">
                            <div class="flex justify-between w-full h-[24px]">
                                <p class="text-sm text-gray-800">{{ $store.getters.getUserFullName(comment.user_id) }}</p>
                                <div class="hidden group-hover:block">
                                    <i class="text-[11px] text-gray-600 fas fa-pencil hover:text-black cursor-pointer mr-2" @click.prevent="editComment(comment)"></i>
                                    <i class="text-[11px] text-gray-600 fas fa-trash hover:text-red-600 cursor-pointer" @click.prevent="deleteComment(comment)"></i>
                                </div>
                            </div>
                            <p class="mb-1 text-gray-500 text-[11px] -mt-[4px]">{{ comment.created_at | moment().format('DD MMM YYYY HH[h]mm') }}</p>
                            <p class="text-xs text-gray-700"  style="white-space: pre-line">{{ getText(comment) }}</p>
                        </div>
                    </div>
                </div>
                <div class="flex justify-center mt-6">
                    <form class="w-full max-w-input" @submit.prevent="submitComment">
                        <v-spa-textarea container-class-name="w-full mb-6" input-container-class-name="w-full" ref="textarea" :placeholder="$t('worksite.tasks.columns.comment.modal.text_placeholder')" :display-label="false" :form="form" input-name="text" id="new-comment-form-text"></v-spa-textarea>
                        <div class="flex items-center justify-between w-full">
                            <span class="text-xs text-gray-600 cursor-pointer hover:text-gray-800" @click="cancelCommentEdit">
                                <template v-if="form.uuid">{{ $t('worksite.tasks.columns.comment.modal.cancel_button') }}</template>
                            </span>
                            
                            <v-spa-loading-button :disabled="form.text.trim() == ''" @clicked="submitComment">
                                <template v-if="form.uuid">{{ $t('worksite.tasks.columns.comment.modal.update_button') }}</template>
                                <template v-else>{{ $t('worksite.tasks.columns.comment.modal.add_button') }}</template>
                            </v-spa-loading-button>
                        </div>
                    </form>
                </div>
            </div>
        </portal>
    </td>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import Form from '~spa/classes/Form';
    import Mixin from './mixin';
    
    export default {
        mixins: [Mixin],
        data() {
            return {
                form: new Form({
                    uuid: null,
                    text: ''
                })
            }
        },
        methods: {
            count()
            {
                return this.comments().length;
            },

            onClick()
            {
                console.log('Trying to open modal: ' + 'worksite-task-comments-modal');
                this.$modal.show('worksite-task-comments-modal');
                this.clicked = true;
            },

            comments() {
                return this.value() || [];
            },

            cancelCommentEdit()
            {
                this.form.uuid = null;
                this.form.text = '';
            },

            editComment(comment)
            {
                if ( this.form.text && this.form.text !== comment.text ) {
                    let message = this.form.uuid   
                        ? this.$t('worksite.tasks.columns.comment.modal.confirms.cancel_new')
                        : this.$t('worksite.tasks.columns.comment.modal.confirms.cancel_update');
                    if ( ! window.confirm(message) ) {
                        return;
                    }
                }

                this.form.text = comment.text;
                this.form.uuid = comment.uuid;
                this.$refs.textarea.$refs.textarea.focus();
            },

            submitComment()
            {
                return this.form.uuid
                    ? this.updateComment()
                    : this.storeComment();
            },

            storeComment()
            {
                let comments = this.comments();

                comments.push({
                    user_id: this.$store.state.user.id,
                    created_at: window.moment().format('YYYY-MM-DD HH:mm:ss'),
                    text: this.form.text,
                    uuid: uuidv4()
                });

                this.updateValue(comments);
            },

            updateComment()
            {
                let comments = this.comments().map(comment => {
                    if ( comment.uuid === this.form.uuid ) {
                        comment.text = this.form.text;
                    }
                    
                    return comment;
                });

                this.updateValue(comments);
            },

            deleteComment(comment)
            {
                if ( ! window.confirm( this.$t('worksite.tasks.columns.comment.modal.confirms.delete') ) ) {
                    return;
                }
                
                this.updateValue(
                    this.comments().filter(c => c.uuid !== comment.uuid)
                );
            },

            updateValue(comments)
            {
                this.form.uuid = null;
                this.form.text = '';
                this.task.setColumnValue(this.column.id, comments);
                this.$nextTick(() => this.updated());
            },

            getText(comment)
            {
                return comment.text.replace(/\n\s*\n\s*\n/g, '\n\n');;
            }
        },
        created() {
            this.$bus.$on('worksite-task-comments-modal-before-close', () => this.clicked = false);
        }
    }
</script>
