<template>
    <th :class="`px-4 py-1 text-xs uppercase font-normal text-left text-gray-500 group`" :style="`width: ${column.getWidth()}`">
        <span class="relative flex items-center">
            <span v-if="! clicked" class="inline-block" :class="{'text-[13px] border border-white cursor-pointer hover:text-black': displayTitle}">
                {{ displayTitle ? (column.title || '') : '' }}
            </span>
            <input ref="input" v-if="clicked" class="min-w-[150px] w-full py-0.5 px-[3px] bg-white text-gray-700 border border-dashed border-gray-600 focus:outline-none text-sm" v-model="title" @blur="updateTitle" @keyup.enter="updateTitle" @keyup.esc="updateTitle" />
            
            <!-- <v-spa-submenu-container :container="$spa.css.submenu.container.default" position="left-0 w-[200px]" ref="submenu">
                <template v-slot:default="slotProps">
                    <div class="absolute top-0 bottom-0 items-center hidden ml-2 cursor-move left-full group-hover:flex column-handle hover:text-primary" v-if="! clicked">
                        <i class="-mt-[2px] fas fa-sort-down" @click.prevent="slotProps.toggle()" />
                    </div>
                </template>
                <div class="relative w-auto" slot="submenu">
                    <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="editTitle()">
                        <i :class="`${$spa.css.contextmenu.icon} far fa-pencil`" />
                        {{ $t('worksite.tasks.column_actions.rename') }}
                    </a>
                    <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="duplicateColumn">
                        <i :class="`${$spa.css.contextmenu.icon} far fa-copy`" />
                        {{ $t('worksite.tasks.column_actions.duplicate') }}
                    </a>
                    <a :class="$spa.css.contextmenu.link" href="#" @click.prevent="deleteColumn">
                        <i :class="`${$spa.css.contextmenu.icon} far fa-trash`" />
                        {{ $t('worksite.tasks.column_actions.delete') }}
                    </a>
                </div>
            </v-spa-submenu-container> -->
        </span>
    </th>
</template>

<script>
    export default {
        props: {
            column: {
                type: Object,
                required: true,
            },
            displayTitle: {
                type: Boolean,
                default: true
            }
        },

        data() {
            return {
                clicked: false,
                title: ''
            }
        },

        methods: {
            editTitle() {
                this.$refs.submenu.hide();
                this.title = this.column.title;
                this.clicked = true;
                this.$refs.submenu.hide();

                this.$nextTick(() => this.$refs.input.focus());
            },

            updateTitle() {
                this.clicked = false;
                
                if ( this.title !== this.column.title ) {
                    this.column.title = this.title;
                    this.$emit('updated', this.column);
                }
            },

            duplicateColumn() {
                this.$emit('duplicated', this.column);
                this.$refs.submenu.hide();
            },

            deleteColumn() {
                this.$emit('deleted', this.column);
                this.$refs.submenu.hide();
            }
        }
    }
</script>
