<template>
    <div
        class="flex flex-col flex-shrink-0"
        :class="[day.isSame($moment(), 'day') ? 'bg-gray-200' : 'bg-gray-100', {'rounded-tl-2xl': isFirst, 'rounded-rl-2xl': isLast}]"
        :style="`width: ${dayWidth}px`"
    >
        <div
            class="px-4 py-4 text-[11px] text-center border-b border-gray-300"
            :class="day.isSame($moment(), 'day') ? 'text-black font-bold' : 'text-[#545454]'"
        >
            {{ day.format('dddd DD MMMM YYYY').capitalize() }}
        </div>
        <div
            class="flex flex-col flex-1 px-4 py-2 space-y-2"
            :class="{'border-l border-gray-300': index !== 0}"
        >
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            dayWidth: {
                type: Number,
                required: true
            },
            day: {
                type: Object,
                required: true
            },
            index: {
                type: Number,
                required: true
            },
            isFirst: {
                type: Boolean,
                required: true
            },
            isLast: {
                type: Boolean,
                required: true
            },
        }
    }
</script>