import Base from './actions/base';
import Auth from './actions/auth';
import Accounts from './actions/accounts';

const actions = Object.assign(
    Base,
    Auth,
    Accounts,
);

/**
 * These are methods can be be used globally.
 * They can contain asynchronous operations.
 * They will commit a mutation instead of mutating the state directly.
 */
export default actions;