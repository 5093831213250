<template>
    <div>
        <div class="px-8 py-3 mb-4 text-center text-red-600 bg-red-100 rounded" v-if="! canInvoice">
            <p>{{ $t('worksite.progress-billings.form.quote_contains_multiple_vat') }}</p>
        </div>

        <div class="px-8 py-3 mb-4 text-center text-red-600 bg-red-100 rounded" v-if="! $store.state.user.default_professional.address">
            <p>{{ $t('worksite.progress-billings.form.professional_without_address') }}</p>
        </div>

        <div class="px-8 py-3 mb-4 text-center text-orange-600 bg-orange-100 rounded" v-if="! $store.state.user.default_professional.logo || $store.state.user.default_professional.logo.startsWith('data:image')">
            <p>{{ $t('worksite.progress-billings.form.professional_without_logo') }}</p>
        </div>

        <div class="flex w-full pb-8 mb-8 overflow-x-scroll text-xs border-b border-gray-200 xl:text-sm 2xl:text-base">

            <div class="flex flex-1">

                <!-- First Column: Lines Description -->
                <div class="flex-1">
                    <div class="flex items-center text-sm text-gray-800 h-[50px] border-b border-gray-200 pr-4">
                        <span>
                            {{ $t('worksite.progress-billings.form.headings.description') }}
                        </span>
                    </div>
                    <div class="w-full flex items-center h-[50px] whitespace-nowrap border-b border-gray-200 text-ellipsis overflow-hidden pr-4" v-for="line in quote.lines" :key="line.uuid">
                        <span>
                            {{ line.title }}
                        </span>
                    </div>
                </div>
    
                <!-- Second Column: Lines Quantity, Unit & Price -->
                <div class="flex-1 max-w-[300px]">
                    <div class="flex items-center justify-center text-sm text-gray-800 h-[50px] border-b border-gray-200 px-4">
                        <span>
                            {{ $t('worksite.progress-billings.form.headings.quantities') }}
                        </span>
                    </div>
                    <div class="w-full flex items-center justify-between border-b border-gray-200 h-[50px] space-x-4 px-4" v-for="line in quote.lines" :key="line.uuid">
                        <span class="text-right whitespace-nowrap">{{ line.quantity }} {{ line.unit }}</span>
                        <span class="text-right">{{ line.price | price }}</span>
                        <span class="text-right">{{ line.total | price }}</span>
                    </div>
                </div>
            </div>

            <!-- Third Column (optional): Existing billings lines Quantity, Unit & Price -->
            <div class="w-[220px] lg:w-[240px] 2xl-w-[280px]" v-if="quoteBillings.isNotEmpty()">
                <div class="flex items-center justify-center text-sm text-gray-800 h-[50px] border-b border-gray-200 px-4">
                    <span>
                        {{ $t('worksite.progress-billings.form.headings.existing_billings') }}
                    </span>
                </div>
                <div class="flex items-center justify-between border-b border-gray-200 px-4 h-[50px] space-x-4" v-for="line in quote.lines" :key="line.uuid">
                    <div class="flex items-center bg-gray-100 border border-gray-300 rounded">
                        <span class="inline-block w-[60px] xl:w-[80px] px-2 py-1 rounded-l border-r border-gray-300 text-right">{{ quoteBillings.getLineQuantity(line) }}</span>
                        <span class="text-right inline-block w-[30px] xl:w-[40px] px-2 py-1 rounded-r">{{ quoteBillings.getLineUnit(line) }}</span>
                    </div>
                    <span>{{ quoteBillings.getLineTotal(line) | price }}</span>
                </div>
            </div>

            <!-- Fourth Column: New Billing Form -->
            <div class="border-2 rounded-lg shadow-xl w-[240px] lg:w-[280px] 2xl-w-[300px]" :class="[billing ? billing.getStatusBorderColor() : 'border-gray-200']">
                <div class="rounded-t flex items-center justify-center text-white text-sm h-[50px] border-b border-gray-200 px-4" :class="[billing ? billing.getStatusColor() : 'bg-gray-600']">
                    <span v-if="! billing">
                        {{ $t('worksite.progress-billings.form.headings.new_billing') }}
                    </span>
                    <span v-if="billing && billing.status == 'draft'">
                        {{ $t('worksite.progress-billings.form.headings.draft_billing') }}
                    </span>
                    <span v-if="billing && billing.status !== 'draft'">
                        {{ $t('worksite.progress-billings.form.headings.existing_billing', {number: billing.number}) }}
                    </span>
                </div>
                <div class="px-4 flex items-center justify-between border-b border-gray-200 h-[50px] space-x-4" v-for="line in form.lines" :key="line.worksite_progress_billing_quote_line.uuid">
                    <div class="flex items-center border border-gray-300 rounded">
                        <input :type="formIsDisabled(line) ? 'text' : 'number'" v-model.number="line.quantity" class="rounded-l px-2 py-1 border-r border-gray-300 min-w-[60px] xl:w-[80px] text-right" :class="{'bg-gray-100 w-[60px] xl:w-[80px]': formIsDisabled(line)}" :disabled="formIsDisabled(line)" min="0" :max="line.max_quantity" />
                        <select class="text-right rounded-r px-2 py-1 w-[30px] xl:w-[40px]" :class="{'bg-gray-100': quoteBillings.isNotEmpty() || formIsDisabled(line)}" :disabled="quoteBillings.isNotEmpty() || formIsDisabled(line)" @change="changeLineUnit(line, $event)">
                            <option :value="line.unit" :selected="line.is_percentage">{{ line.unit }}</option>
                            <option value="custom-unit-percentage" :selected="line.is_percentage">%</option>
                        </select>
                    </div>
                    <span>
                        {{ getFormLineTotal(line) | price }}
                    </span>
                </div>
                <div class="px-4 h-[50px] flex justify-between items-center">
                    <span>{{ $t('worksite.progress-billings.form.total') }}</span>
                    <span>{{ getFormTotal() | price }}</span>
                </div>
            </div>

            <!-- Fifth Column: Total combined -->
            <div class="w-[220px] lg:w-[240px] 2xl-w-[280px]">
                <div class="flex items-center justify-center text-sm text-gray-800 h-[50px] border-b border-gray-200 px-4">
                    <span>
                        {{ $t('worksite.progress-billings.form.headings.total') }}
                    </span>
                </div>
                <div class="flex items-center justify-between border-b border-gray-200 px-4 h-[50px] space-x-4" v-for="line in quote.lines" :key="line.uuid">
                    <div class="flex items-center bg-gray-100 border border-gray-300 rounded">
                        <span class="inline-block w-[60px] xl:w-[80px] px-2 py-1 rounded-l border-r border-gray-300 text-right">
                            {{ getLineTotalQuantity(line) }}
                        </span>
                        <span class="text-right inline-block w-[30px] xl:w-[40px] px-2 py-1 rounded-r">
                            {{ getLineTotalUnit(line) }}
                        </span>
                    </div>
                    <span>{{ getLineTotal(line) | price }}</span>
                </div>
            </div>
        </div>
        <div class="flex justify-between">
            <div class="p-4 bg-gray-100 rounded w-[350px]">
                <span class="block" v-if="billing">
                    {{ $t('worksite.progress-billings.form.summary.number', { number: billing.number }) }}
                </span>
                <span class="block">
                    {{ $t('worksite.progress-billings.form.summary.quote', { quote: quote.number }) }}
                </span>
                <span class="block">
                    {{ $t('worksite.progress-billings.form.summary.total', { total: getFormTotalDisplay() }) }}
                </span>
            </div>
            <div class="flex items-center space-x-4">
                <template v-if="! billing">
                    <v-spa-loading-button :loading="form.isLoading()" @clicked="submit" :prevent-default="true">
                        <i class="mr-2 fal fa-check"></i>
                        {{ $t('worksite.progress-billings.create.submit') }}
                    </v-spa-loading-button>
                </template>
                <template v-else>
                    <v-spa-loading-button ref="pdfButton" :color="$spa.css.button.color.white_border" @clicked="pdf" :prevent-default="true" v-if="$store.state.user.default_professional.address">
                        <i class="mr-2 fal fa-download"></i>
                        {{ $t('worksite.progress-billings.edit.pdf') }}
                    </v-spa-loading-button>
                    <template v-if="billing.status == 'draft'">
                        <v-spa-loading-button ref="destroyButton" :color="$spa.css.button.color.white_border" @clicked="destroy" :prevent-default="true">
                            <i class="mr-2 fal fa-times"></i>
                            {{ $t('worksite.progress-billings.edit.delete') }}
                        </v-spa-loading-button>
                        <v-spa-loading-button :color="$spa.css.button.color.white_border" :loading="form.isLoading()" @clicked="submit" :prevent-default="true">
                            <i class="mr-2 fal fa-save"></i>
                            {{ $t('worksite.progress-billings.edit.submit') }}
                        </v-spa-loading-button>
                        <v-spa-loading-button ref="requestValidationButton" :loading="form.isLoading()" @clicked="requestValidation" :prevent-default="true">
                            <i class="mr-2 fal fa-check"></i>
                            {{ $t('worksite.progress-billings.edit.request_validation') }}
                        </v-spa-loading-button>
                    </template>
                    <template v-if="billing.status == 'validation_requested'">
                        <v-spa-loading-button ref="validateButton" :loading="form.isLoading()" @clicked="validate" :prevent-default="true">
                            <i class="mr-2 fal fa-check"></i>
                            {{ $t('worksite.progress-billings.edit.validate') }}
                        </v-spa-loading-button>
                    </template>
                    <template v-if="billing.status == 'validated' && billing.getInvoicedStatus() == 'todo' && canInvoice">
                        <v-spa-loading-button ref="d" :loading="form.isLoading()" @clicked="invoice" :prevent-default="true">
                            <i class="mr-2 fal fa-check"></i>
                            {{ $t('worksite.progress-billings.edit.invoice') }}
                        </v-spa-loading-button>
                    </template>
                </template>
            </div>
        </div>

        <worksite-progress-billing-request-validation-modal-form />
        <worksite-progress-billing-invoice-modal-form />
    </div>
</template>

<script>
    
    import Form from '@spa/classes/Form';
    import WorksiteProgressBillingRequestValidationModalForm from './spa/components/WorksiteProgressBillingRequestValidationModalForm.vue';
    import WorksiteProgressBillingInvoiceModalForm from './spa/components/WorksiteProgressBillingInvoiceModalForm.vue';
    
    export default {
        components: {
            WorksiteProgressBillingRequestValidationModalForm,
            WorksiteProgressBillingInvoiceModalForm
        },
        props: {
            quote: {
                type: Object,
                required: true
            },
            billings: {
                type: Object,
                required: true
            },
            billing: {
                type: Object,
                default: null
            },
            quoteBillings: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                form: new Form({
                    worksite_progress_billing_quote_id: null,
                    lines: []
                }),
            }
        },
        computed: {
            formIsEditable()
            {
                return ! this.billing || this.billing.status === 'draft';
            },

            /**
             * TODO: Remove this when we can invoice multiple VAT options.
             */
            canInvoice()
            {
                return new Set( this.quote.lines.map(l => l.vat_option_id) ).size === 1;
            }
        },
        methods: {
            formIsDisabled(line)
            {
                if ( ! this.formIsEditable) {
                    return true;
                }
                
                return line.max_quantity == 0;
            },
            
            setQuote(quote)
            {
                this.form.worksite_progress_billing_quote_id = quote.id;
                this.form.lines = quote.lines.map(line => {
                    let unit = this.quoteBillings.getLineUnit(line);
                    let unitInternal = this.quoteBillings.getLineUnitInternal(line);
                    let isPercentage = unitInternal === 'custom-unit-percentage';
                    let quantity = isPercentage ? 100 : line.quantity;
                    return {
                        title: line.title,
                        unit: unit,
                        max_quantity: quantity - this.quoteBillings.getLineQuantity(line),
                        quantity: 0,
                        is_percentage: isPercentage,
                        worksite_progress_billing_quote_line: line,
                        worksite_progress_billing_quote_line_id: line.id
                    }
                });
            },

            getFormLineTotal(line)
            {
                return line.is_percentage
                    ? (line.worksite_progress_billing_quote_line.total / 100) * line.quantity
                    : line.worksite_progress_billing_quote_line.price * line.quantity;
            },

            getFormTotal()
            {
                return this.form.lines.reduce((total, line) => {
                    return total + this.getFormLineTotal(line);
                }, 0);
            },

            getFormTotalDisplay()
            {
                return window.centsToPrice(this.getFormTotal());
            },

            getFormLineFromQuoteLine(line)
            {
                return this.form.lines.find(l => l.worksite_progress_billing_quote_line_id === line.id);
            },

            getLineTotalQuantity(line)
            {
                return this.quoteBillings.getLineQuantity(line) + this.getFormLineFromQuoteLine(line).quantity;
            },

            getLineTotalUnit(line)
            {
                return this.getFormLineFromQuoteLine(line)?.is_percentage
                    ? '%'
                    : this.quoteBillings.getLineUnit(line);
            },

            getLineTotal(line)
            {
                return this.quoteBillings.getLineTotal(line) + this.getFormLineTotal(this.getFormLineFromQuoteLine(line));
            },

            getFormData()
            {
                return {
                    worksite_progress_billing_quote_id: this.quote.id,
                    lines: this.form.lines.map(line => {
                        return {
                            worksite_progress_billing_quote_line_id: line.worksite_progress_billing_quote_line_id,
                            quantity: line.quantity,
                            is_percentage: line.is_percentage
                        }
                    })
                };
            },

            /**
             * Currently only available for the first billing progress created.
             * We can therefor safely reset the value of the field to the max quantity or 100%.
             */
            changeLineUnit(line, event)
            {
                if ( this.quoteBillings.isNotEmpty() ) {
                    return;
                }
                
                line.is_percentage = event.target.value == 'custom-unit-percentage';
                line.quantity = line.is_percentage ? 100 : line.max_quantity;
            },

            async pdf()
            {
                if ( this.$refs.pdfButton.loading ) {
                    return; 
                }

                this.$refs.pdfButton.load();

                let pdf = await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).pdf(this.billing.uuid);
                window.open(pdf, '_blank');
                
                this.$refs.pdfButton.stop();
            },

            async destroy()
            {
                if ( this.$refs.destroyButton.loading ) {
                    return; 
                }

                this.$refs.destroyButton.load();

                await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).delete(this.billing.uuid);
                this.$router.push({name: 'account.worksite.progress-billings.index', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid}});
            },

            async requestValidation()
            {
                this.$bus.$emit('worksite:worksite-progress-billing-request-validation-form-modal:open', {model: this.billing, send_to: this.$store.state.worksite.contact?.email || this.$store.state.user.email});
                return;
            },

            async validate()
            {
                if ( this.$refs.validateButton.loading ) {
                    return; 
                }

                this.$refs.validateButton.load();

                await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).validate(this.billing.uuid);
                this.$router.push({name: 'account.worksite.progress-billings.index', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid}});
            },

            async invoice()
            {
                this.$bus.$emit('worksite:worksite-progress-billing-invoice-form-modal:open', {model: this.billing, quote: this.quote});
                return;
            },

            async submit()
            {
                if ( this.form.isLoading() ) {
                    return;
                }

                let data = this.getFormData();
                let method = 'store';

                if ( data.lines.every(line => line.quantity === 0) ) {
                    return window.Toasteo.error(
                        this.$t('worksite.progress-billings.form.errors.requires_quantity')
                    );
                }

                if ( this.billing ) {
                    data = {uuid: this.billing.uuid, ...{data: data}};
                    method = 'update';
                }

                console.log(data, method);

                this.form.load();
                await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite)[method](data);
                this.$router.push({name: 'account.worksite.progress-billings.index', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid}});
                window.Toasteo.success(
                    this.billing ? this.$t('worksite.progress-billings.edit.success') : this.$t('worksite.progress-billings.create.success')
                );
            },

            redirectAfterInvoice()
            {
                window.Toasteo.success(
                    this.$t('worksite.progress-billings.invoice.success')
                );
                this.$router.push({name: 'account.worksite.progress-billings.index', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid}});
            }
        },
        created()
        {
            this.$bus.$on('worksite:worksite-progress-billing-invoice-form-modal:success', this.redirectAfterInvoice);
        },
        beforeDestroy()
        {
            this.$bus.$off('worksite:worksite-progress-billing-invoice-form-modal:success', this.redirectAfterInvoice);
        }
    }
</script>