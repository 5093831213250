<template>
    <div
        class="flex items-center px-6 py-2 space-x-2 cursor-pointer"
        @contextmenu.prevent="isActionnable ? task.contextMenu($event, {phases: $store.state.worksite.phases, currentPhase: phase}) : null"
        @click.prevent="isActionnable ? task.preview() : null"
    >
        <div class="flex-1 text-sm">
            <div class="flex items-center space-x-2" v-if="! editTitle">
                <div
                    class="cursor-pointer h-[24px] w-[24px] rounded-full flex items-center justify-center flex-shrink-0"
                    :class="task.done_at ? 'bg-green-500 hover:bg-gray-600' : 'border border-gray-200 hover:bg-gray-200'"
                    @click.stop.prevent="task.uuid ? updateDoneAt() : null"
                >
                    <i class="text-white far fa-check"></i>
                </div>

                <div
                    class="block w-full hover:text-gray-600 cursor-text"
                    :class="{'line-through': task.done_at}"
                    @click.stop.prevent="startEditingTitle"
                >
                    {{ task.title }}
                </div>
            </div>
            
            <input v-else style="font-size: 13px !important;" :value="task.title" :placeholder="task.title" ref="taskInput" class="w-full px-3 py-2 text-xs border border-gray-400 border-dashed" @blur="updateTitle" @keyup.enter="updateTitle" />
        </div>
        <div class="w-[120px] flex justify-end">
            <select-user :initial-value="task.users_id" :multiple="true" @updated="updateUsers"></select-user>
        </div> 
        <div class="w-[120px] text-right text-sm">
            <i class="text-gray-600 far fa-comment"></i>
        </div> 
        <div class="w-[120px] text-right text-sm">
            <i class="text-gray-600 far fa-stopwatch"></i>
        </div>  
        <div class="w-[120px] text-right text-sm -mr-2">
            <task-due-date-picker :task="task" @change="updateDueDate" ref="dueDatePicker">
                <task-due-date :task="task" class-name="justify-end" />
            </task-due-date-picker>
        </div> 
    </div>
</template>

<script>
    import UsersListAvatar from "~spa/apps/trustup-pro-base-js/components/_Partials/UsersListAvatar";
    import SelectUser from "~spa/apps/trustup-pro-base-js/components/_Partials/Selects/User.vue";
    import TaskDueDate from '~spa/apps/trustup-pro-base-js/components/TrustUpIO/Task/Item/DueDate.vue';
    import TaskDueDatePicker from '~spa/apps/trustup-pro-base-js/components/TrustUpIO/Task/Item/DueDatePicker.vue';

    export default {
        components: {
            UsersListAvatar,
            SelectUser,
            TaskDueDate,
            TaskDueDatePicker
        },
        
        props: {
            phase: {
                type: Object,
                required: true
            },
            task: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                editTitle: false,
            }
        },

        computed: {
            isActionnable()
            {
                if ( ! this.task.uuid ) {
                    return false;
                }
                if ( this.editTitle ) {
                    return false;
                }
                if ( this.$refs.dueDatePicker && this.$refs.dueDatePicker.$refs.submenu && this.$refs.dueDatePicker.$refs.submenu.isDisplayed ) {
                    return false;
                }
                return true;
            }
        },

        methods: {
            startEditingTitle()
            {
                this.editTitle = true;
                this.$nextTick(() => this.$refs.taskInput.focus())
            },  

            async updateDoneAt()
            {
                console.log('test');
                this.task.done_at = this.task.done_at ? null : this.$moment().format('YYYY-MM-DD HH:mm:ss');
                await this.task.getEndpoint().update({uuid: this.task.uuid, data: this.task.data()});
            },
            
            async updateTitle()
            {
                let title = this.$refs.taskInput.value.trim();
                if ( title == '' ) {
                    return;
                }
                
                this.editTitle = false;

                if ( title == this.task.title ) {
                    return;
                }

                this.task.title = title;
                await this.task.getEndpoint().update({uuid: this.task.uuid, data: this.task.data()});
            },

            async updateDueDate()
            {
                this.task.due_date = this.task.due_date ? this.$moment(this.task.due_date).format('YYYY-MM-DD') : null;
                await this.task.getEndpoint().update({uuid: this.task.uuid, data: this.task.data()});
            },

            async updateUsers(ids)
            {
                this.task.setUsersByID(ids);
                await this.task.getEndpoint().update({uuid: this.task.uuid, data: this.task.data()});
            }
        }
    }
</script>