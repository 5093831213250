<template>
    <div class="relative flex flex-1">
        <slot></slot>              
        <slot name="remaining">
            <div v-if="remainingText && remainingWidth" class="absolute top-0 bottom-0 right-0 flex items-center justify-center w-full h-full text-sm bg-[#E9EBF0]" :style="`width: ${remainingWidth}%`">
                <div class="text-center">
                    <span class="block text-sm text-center text-gray-500">{{ remainingText }}</span>
                    <span class="block text-sm text-center text-gray-500" v-if="remainingSubText">{{ remainingSubText }}</span>
                </div>
            </div>      
        </slot>
    </div>
</template>

<script>
    export default {
        props: {
            remainingText: {
                type: String,
                nullable: true
            },
            remainingSubText: {
                type: String,
                nullable: true
            },
            remainingWidth: {
                type: Number,
                nullable: true
            }
        }
    }
</script>
