<template>
    <span :class="`px-2 py-1/2 rounded-full text-[9px] uppercase tracking-wide ${badgeClass}`">
        {{ badgeText }}
    </span>
</template>

<script>
export default{
    props: {
        status: {
            type: String,
            required: true
        }
    },
    computed: {
        badgeText()
        {
            return this.$t(`opportunities.quote_status.${this.status}`);
        },
        badgeClass()
        {
            switch (this.status) {
                case 'accepted':
                    return 'bg-green-600 text-white';
                case 'sent':
                    return 'bg-blue-600 text-white';
                case 'declined':
                    return 'bg-red-600 text-white';
                case 'draft':
                    return 'bg-gray-200 text-black';
            }
        }
    }
}
</script>
