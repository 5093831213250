<template>
    <div class="h-full">
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            
            <template v-if="opportunities.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('opportunities.index.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('opportunities.index.empty.text') }}</p>
                    <div class="flex justify-center">
                        <v-spa-loading-button @clicked="$bus.$emit('worksite:opportunity-form-modal:open')">
                            {{ $t('opportunities.index.empty.button') }}
                        </v-spa-loading-button>
                    </div>
                </div>
            </template> 
            <template v-else>
                <div class="flex items-stretch min-h-full">
                    <div class="flex flex-col w-1/4" v-for="(status, index) in statuses" :key="status">
                        <div class="relative flex justify-between px-6 py-4 border-t border-b border-l border-gray-300">
                            <div class="absolute top-0 bottom-0 z-10 flex items-center left-full"><div class="triangle-right"></div></div>
                            <div class="flex items-center">
                                <i :class="`far ${getStatusIcon(status)} text-lg text-gray-700 mr-3`"></i>
                                <p class="">{{ $t(`opportunities.status.${status}`) }}</p>
                            </div>
                            <div>
                                <p>{{ opportunitiesByStatus[status].length }}</p>
                            </div>
                        </div>
                        <div class="flex-1 p-4 border-r border-gray-300">
                            <draggable
                                :list="opportunitiesByStatus[status]"
                                group="opportunities"
                                @change="onOpportunityDrag"
                                v-bind="dragOptions"
                            >
                                <transition-group tag="div" type="transition" name="flip-list">
                                    <v-spa-panel class="relative mb-4 group cursor-grab" v-for="opportunity in opportunitiesByStatus[status]" :key="opportunity.uuid">
                                        <div class="absolute top-0 right-0 z-10 hidden pt-1 pr-1 cursor-pointer group-hover:block">
                                            <a href="#" @click.prevent.stop="opportunity.edit()">
                                                <i class="text-xs text-gray-600 hover:text-black fas fa-pencil"></i>
                                            </a>
                                        </div>
                                        <div
                                            class="absolute inset-0 z-[9]"
                                            @click.prevent.stop="opportunity.open()"
                                            @contextmenu.prevent="opportunity.contextMenu($event)"
                                        ></div>
                                        <div class="flex items-center justify-between mb-4">
                                            <div>
                                                <p class="text-sm text-gray-800">{{ opportunity.title }}</p>
                                                <p v-if="opportunity.contact_uuid">{{ opportunity.getContactName() }}</p>
                                            </div>
                                            <div>
                                                <users-list-avatar :users="opportunity.getManagers()"></users-list-avatar>
                                            </div>
                                        </div>
                                        <div class="flex items-end justify-between">
                                            <span class="text-xs text-gray-600">{{ opportunity.added_at | moment().format('DD MMMM YYYY') }}</span>
                                            <div class="flex flex-wrap justify-end">
                                                <span class="block w-full mb-1 text-xs text-right text-gray-800">{{ opportunity.total_quoted | price }}</span>
                                                <opportunity-source-badge v-if="opportunity.source" :source="opportunity.source"></opportunity-source-badge>
                                            </div>
                                        </div>
                                    </v-spa-panel>
                                </transition-group>
                            </draggable>
                        </div>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<style lang="scss">
    .triangle-right {
        width: 0;
        height: 0;
        border-top: 30px solid transparent;
        border-bottom: 30px solid transparent;
        border-left: 15px solid rgb(224 224 224 / var(--tw-border-opacity));
        position: relative;
    }

    .triangle-right:after {
        content: '';
        width: 0;
        height: 0;
        border-top: 28px solid transparent;
        border-bottom: 28px solid transparent;
        border-left: 14px solid white;
        position: absolute;
        top: -28px;
        right: 1px;
    }
    .moving-opportunity {
        @apply bg-white opacity-100 bg-gray-100 border border-primary;
    }
</style>

<script>
    import draggable from "vuedraggable";
    import OpportunitySourceBadge from '~base/components/Opportunity/_Partials/SourceBadge';
    import UsersListAvatar from '~spa/apps/trustup-pro-base-js/components/_Partials/UsersListAvatar';
    import Panel from '~base/components/_Partials/Panel.vue';
    
    export default {
        components: {
            draggable,
            Panel,
            UsersListAvatar,
            OpportunitySourceBadge
        },
        computed: {
            dragOptions() {
                return {
                    emptyInsertThreshold: 100,
                    sort: false,
                    animation: 200,
                    group: "description",
                    disabled: false,
                    ghostClass: "moving-opportunity"
                };
            },
        },
        data() {
            return {
                loading: true,
                opportunities: [],
                statuses: ['pending', 'contact', 'meeting', 'quote'],
                opportunitiesByStatus: {
                    pending: [],
                    contact: [],
                    meeting: [],
                    quote: []
                }
            }
        },
        methods: {
            getStatusIcon(status)
            {
                switch (status) {
                    case 'pending':
                        return 'fa-inbox-in';
                    case 'contact':
                        return 'fa-comment-lines';
                    case 'meeting':
                        return 'fa-calendar-check';
                    case 'quote':
                        return 'fa-file-invoice';
                }
            },

            getOpportunitiesByStatus(status)
            {
                return this.opportunities.filter(o => o.status === status);
            },

            setOpportunitiesByStatus()
            {
                for ( let status of this.statuses ) {
                    this.opportunitiesByStatus[status] = this.getOpportunitiesByStatus(status);
                }
            },

            async getOpportunities()
            {                
                let {opportunities} = await window.Worksite.endpoints.opportunity.index();
                this.opportunities = opportunities;
                this.setOpportunitiesByStatus();
                this.loading = false;
            },

            onOpportunityDrag(event)
            {
                if ( ! event.added ) {
                    return;
                }
                
                for ( let status of this.statuses ) {
                    let opportunity = this.opportunitiesByStatus[status].find(o => o.id == event.added.element.id)
                    if ( opportunity ) {
                        this.updateOpportunityStatus(opportunity.uuid, status);
                    }
                };
            },
            
            async updateOpportunityStatus(uuid, status)
            {
                await window.Worksite.endpoints.opportunity.updateStatus(uuid, status);
            },
        },
        async created() {
            await this.getOpportunities();

            this.$bus.$on('worksite:opportunity:created', this.setOpportunitiesByStatus);
            this.$bus.$on('worksite:opportunity:updated', this.setOpportunitiesByStatus);
            this.$bus.$on('worksite:opportunity:deleted', this.setOpportunitiesByStatus);
        },
        beforeDestroy()
        {
            this.$bus.$off('worksite:opportunity:created', this.setOpportunitiesByStatus);
            this.$bus.$off('worksite:opportunity:updated', this.setOpportunitiesByStatus);
            this.$bus.$off('worksite:opportunity:deleted', this.setOpportunitiesByStatus);
        }
    }
</script>