<template>
    <div>
        <div class="relative py-12" v-if="loading"><v-spa-loader /></div>

        <div v-else>
            <div class="flex items-center justify-between mb-8">
                <h2 class="text-xl text-black">
                    {{ $t('worksite.progress-billings.edit.title', {number: billing.number}) }}
                    <span :class="billing.getStatusBadgeClasses()">
                        {{ $t(`worksite.progress-billings.status.${billing.status}`) }}
                    </span>
                </h2>
                <div></div>
            </div>

            <div v-if="quote && billing">
                <worksite-progress-billing-form
                    :quote="quote"
                    :billings="billings"
                    :billing="billing"
                    :quote-billings="quoteBillings"
                    ref="form"
                ></worksite-progress-billing-form>
            </div>
        </div>
    </div>
</template>

<script>

    import Form from '~spa/classes/Form';
    import WorksiteProgressBillingQuoteEndpoint from './spa/endpoints/WorksiteProgressBillingQuoteEndpoint';
    import WorksiteProgressBillingEndpoint from './spa/endpoints/WorksiteProgressBillingEndpoint';
    import WorksiteProgressBillingForm from './_Form';

    export default {
        components: {
            WorksiteProgressBillingForm
        },
        data() {
            return {
                quotes: null,
                quote: null,
                billings: null,
                billing: null,
                loading: true
            }
        },
        computed: {
            quoteBillings()
            {
                return this.quote.getBillings(
                    this.billings.filter(b => b.uuid !== this.billing.uuid && b.id < this.billing.id)
                );
            }
        },
        methods: {
            fillForm()
            {
                this.$refs.form.setQuote(this.quote);
                this.$refs.form.form.lines = this.$refs.form.form.lines.map(line => {
                    let existingLine = this.billing.lines.find(l => l.worksite_progress_billing_quote_line_id == line.worksite_progress_billing_quote_line_id);
                    console.log(line, existingLine);
                    return {
                        ...line,
                        ...{
                            quantity: existingLine.quantity,
                            is_percentage: existingLine.is_percentage,
                        }
                    }
                })
            }
        },
        async created()
        {
            // TODO: Move to the SPA package
            window.Worksite.endpoints.progressBillingQuote = new WorksiteProgressBillingQuoteEndpoint();
            window.Worksite.endpoints.progressBilling = new WorksiteProgressBillingEndpoint();

            let {quotes} = await window.Worksite.endpoints.progressBillingQuote.setWorksite(this.$store.state.worksite).index();
            let {billings} = await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).index();
            this.billing = await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).retrieve(this.$route.params.billing);
            this.quotes = quotes;
            this.billings = billings;
            this.quote = this.quotes.find(q => q.id == this.billing.worksite_progress_billing_quote_id);

            this.loading = false;
            
            await this.$nextTick();
            this.fillForm();
        }
    }
</script>
