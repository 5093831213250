<template>
    <div class="flex space-x-8">
        <div class="flex flex-col w-1/3 space-y-8">
            <v-spa-panel 
                :title="$t('opportunity.panels.status.title')"
            >
                <div class="flex space-x-[1px] mb-4">
                    <div
                        :key="status"
                        v-for="(status, index) in ['pending', 'contact', 'meeting', 'quote']"
                        class="w-1/4 cursor-pointer group"
                        @click.prevent="updateOpportunityStatus(status)"
                        @mouseover="hoveredStatus = status"
                        @mouseleave="hoveredStatus = null"
                    >
                        <div
                            class="h-[15px] mb-1 group-hover:bg-primary-dark"
                            :class="{
                                'bg-primary': opportunity.hasOrPassedStatus(status, hoveredStatus),
                                'bg-gray-300': ! opportunity.hasOrPassedStatus(status, hoveredStatus),
                                'rounded-l-full': index == 0,
                                'rounded-r-full': index == 3
                            }"
                        ></div>
                        <span class="block text-xs text-center text-gray-700 group-hover:text-black">
                            {{ $t(`opportunities.status.${status}`) }}
                        </span>
                    </div>
                </div>
                <v-spa-element id="opportunity-form-manager_id" :form="form" input-name="manager_id" :label="$t('opportunity.form.labels.users')">
                    <select v-model="form.manager_id" :class="$spa.css.form.select" id="opportunity-form-manager_id" @change="updateOpportunityManagers">
                        <option :value="user.id" v-for="user in $store.getters.usersWithAdminRole" :key="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                </v-spa-element>
            </v-spa-panel>
            <v-spa-panel 
                :title="$t('opportunity.panels.meetings.title')"
                :button="$t('opportunity.panels.meetings.button')"
                @button-clicked="$bus.$emit('worksite:opportunity-meeting-form-modal:open', {opportunity})"
                :loading="loading.meetings"
            >
                <div class="relative py-4" v-if="loading.meetings">
                    <v-spa-loader></v-spa-loader>
                </div>
                <template v-if="! loading.meetings && meetings && meetings.isNotEmpty()">
                    <div
                        v-for="meeting in meetings"
                        :data-tooltip="meeting.title"
                        class="flex items-center mb-2 space-x-3 cursor-pointer group"
                        :key="meeting.uuid"
                        @click.prevent="toggleOpportunityMeetingCompletionStatus(meeting)"
                        @contextmenu.prevent="meeting.contextMenu($event, {data: {opportunity}})"
                    >
                        <div>
                            <div class="flex items-center justify-center bg-white group-hover:bg-primary border border-gray-300 rounded-full h-[24px] w-[24px]">
                                <i class="far fa-check text-primary group-hover:text-white" v-if="meeting.completed_at"></i>
                            </div>
                        </div>
                        <div>
                            <span class="text-sm ">{{ $moment(meeting.date).format('dddd DD MMMM YYYY').capitalize() }}</span>
                        </div>
                        <div>
                            <span class="text-xs text-gray-600">{{ meeting.date | moment().format('HH[h]mm') }}</span>
                        </div>
                    </div>
                </template>
            </v-spa-panel>
            <v-spa-panel 
                :title="$t('opportunity.panels.quotes.title')"
                :button="$t('opportunity.panels.quotes.button')"
                @button-clicked="$bus.$emit('create-quote-modal:open-from-opportunity', opportunity)"
                :loading="loading.quotes"
            >
                <div
                    v-for="quote in quotes"
                    class="flex items-center mb-2 space-x-3 cursor-pointer group"
                    :key="quote.uuid"
                    @click.prevent="openQuote(quote)"
                >
                    <div class="flex-1">
                        <span class="inline-block px-3 py-1 text-sm bg-gray-100 rounded-full">{{ quote.number || $t('opportunity.panels.quotes.no_number') }}</span>
                    </div>
                    <div class="w-[80px]">
                        <span class="text-sm ">{{ quote.total | price }}</span>
                    </div>
                    <div class="w-[80px] flex justify-end">
                        <quote-status-badge :status="quote.status_key"></quote-status-badge>
                    </div>
                </div>
            </v-spa-panel>
            <v-spa-panel
                :title="$t('opportunity.panels.client.title')"
                :button="$t('opportunity.panels.client.button')"
                @button-clicked="$bus.$emit('clients.edit', {uuid: opportunity.contact_uuid, options: {hide: {types: true}}})"
            >
                <div v-if="opportunity.contact">
                    <text-with-label
                        :label="$t('opportunity.panels.client.name')"
                        :text="opportunity.contact.name"
                    ></text-with-label>
                    <text-with-label
                        :label="$t('opportunity.panel.contact_email')"
                        :text="opportunity.contact.email"
                    ></text-with-label>
                    <text-with-label
                        :label="$t('opportunity.panel.contact_phone_number')"
                        :text="opportunity.contact.phone_number"
                    ></text-with-label>
                    <text-with-label
                        :label="$t('opportunity.panel.contact_address')"
                        :text="opportunity.getContactAddress()"
                    ></text-with-label>
                </div>
            </v-spa-panel>
        </div>
        <div class="flex flex-col w-2/3 space-y-8">
            <v-spa-panel 
                :title="$t('opportunity.panels.informations.title')"
                :button="$t('opportunity.panels.informations.button')"
                @button-clicked="opportunity.edit()"
            >
                <text-with-label
                    :label="$t('opportunity.panels.informations.date')"
                    :text="$t('misc.datetime', {date: $moment(opportunity.added_at).format('dddd DD MMMM YYYY'), time: $moment(opportunity.added_at).format('HH[h]mm')}).capitalize()"
                ></text-with-label>
                <text-with-label
                    :label="$t('opportunity.panels.informations.source')"
                    :text="$t(`opportunities.source.${opportunity.source}`)"
                ></text-with-label>
                <text-with-label
                    :label="$t('opportunity.panels.informations.address')"
                    :text="opportunity.getAddress()"
                ></text-with-label>
                <!-- <text-with-label
                    :label="$t('opportunity.panels.informations.tags')"
                >
                    TODO
                </text-with-label> -->
                <text-with-label
                    :label="$t('opportunity.panels.informations.text')"
                    :text="opportunity.text"
                    v-if="opportunity.text"
                ></text-with-label>
            </v-spa-panel>
            <v-spa-panel 
                :title="$t('opportunity.panels.notes.title')"
            >
                <v-spa-textarea default-height="h-[200px]" :should-resize="true" class-name="text-sm text-gray-800 min-h-[140px] bg-[#f9f8d1] px-3 py-2 rounded w-full" @input="updateOpportunityNotesDebounced" :label="$t('opportunity.form.labels.notes')" id="create-opportunity-form-notes" :form="form" input-name="notes"></v-spa-textarea>
            </v-spa-panel>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import Form from '~spa/classes/Form';
    import Panel from '~base/components/_Partials/Panel';
    import TextWithLabel from '~base/components/_Partials/TextWithLabel';
    import QuoteStatusBadge from '~base/components/Opportunity/_Partials/QuoteStatusBadge';
    
    export default {
        components: {
            Panel,
            TextWithLabel,
            QuoteStatusBadge
        },
        data() {
            return {
                hoveredStatus: null,
                loading: {
                    meetings: true,
                    quotes: true
                },
                meetings: null,
                quotes: null,
                form: new Form({
                    notes: '',
                    manager_id: null
                }),
            }
        },
        computed: {
            ...mapState(['opportunity'])
        },
        methods: {
            async updateOpportunityStatus(status)
            {
                this.opportunity.status = status;
                await window.Worksite.endpoints.opportunity.updateStatus(this.opportunity.uuid, status);
            },

            async updateOpportunityManagers()
            {
                await window.Worksite.endpoints.opportunity.updateManagers(this.opportunity.uuid, [this.form.manager_id]);
            },

            updateOpportunityNotesDebounced: _.debounce(function (e)
            {
                console.log('test');
                this.opportunity.notes = this.form.notes;
                this.updateOpportunityNotes();
            }, 500),

            async updateOpportunityNotes()
            {
                await window.Worksite.endpoints.opportunity.updateNotes(this.opportunity.uuid, this.opportunity.notes);
            },

            async toggleOpportunityMeetingCompletionStatus(meeting)
            {
                meeting.completed_at = meeting.completed_at ? null : this.$moment().format('YYYY-MM-DD HH:mm:ss');
                await window.Worksite.endpoints.opportunityMeeting
                    .setOpportunity(this.opportunity.uuid)
                    .updateCompletionStatus(meeting.uuid, meeting.completed_at);
            },

            async addOpportunityQuote({quote})
            {
                this.quotes.push(quote);
                this.opportunity.total_quoted += quote.total;
                await window.Worksite.endpoints.opportunity.addQuote(this.opportunity.uuid, quote.uuid);
                await window.Worksite.endpoints.opportunity.updateTotalQuoted(this.opportunity.uuid, this.opportunity.total_quoted);
            },

            openQuote(quote)
            {
                window.open(`${window.App.$spa.apps.find(app => app.key == 'invoicing').direct_url}/redirection?route=quotes.edit&quote=${quote.uuid}`, '_blank');
            },

            async setContact(params)
            {
                this.opportunity.contact_uuid = params.contact.uuid;
                this.opportunity.contact = params.contact;
                await window.Worksite.endpoints.opportunity.updateContact(this.opportunity.uuid, this.opportunity.contact_uuid);
            },

            refreshContact(params)
            {
                this.opportunity.contact_uuid = params.contact.uuid;
                this.opportunity.contact = params.contact;
            },

        },
        async created()
        {
            this.form.notes = this.opportunity.notes;
            this.form.manager_id = this.opportunity.getManagers()[0]?.id;

            let {meetings} = await window.Worksite.endpoints.opportunityMeeting.setOpportunity(this.opportunity).index();
            this.meetings = meetings;
            this.loading.meetings = false;
            
            this.quotes = await this.opportunity.getQuotes();
            this.$store.commit('setOpportunityQuotes', this.quotes);
            this.loading.quotes = false;

            this.$bus.$on('create-quote-modal:stored', this.addOpportunityQuote);
            this.$bus.$on('create-quote-modal:inserted', this.addOpportunityQuote);
            this.$bus.$on('contacts.stored', this.setContact);
            this.$bus.$on('contacts.updated', this.refreshContact);
        },
        beforeDestroy()
        {
            this.$bus.$off('create-quote-modal:stored', this.addOpportunityQuote);
            this.$bus.$off('create-quote-modal:inserted', this.addOpportunityQuote);
            this.$bus.$off('contacts.stored', this.setContact);
            this.$bus.$off('contacts.updated', this.refreshContact);
        }
    }
</script>
