<template>
    <div class="worksite-layout">
        <v-spa-layout
            ref="layout"
            @toggle-sidebar="handleSidebar"
            @show-sidebar="handleSidebar"
            @hide-sidebar="handleSidebar"
            :display-responsive-menu-on-desktop="false"
            responsive-menu-icon="/spa/close.svg"
        >
            <layout-responsive-sidebar ref="responsiveSidebar" slot="header-sidebar"></layout-responsive-sidebar>
            <layout-sidebar ref="sidebar" slot="sidebar" v-if="display.sidebar"></layout-sidebar>
            <layout-content ref="content" slot="content">
                <slot name="content"></slot>
            </layout-content>
        </v-spa-layout>

        <portal to="modals">
            <v-spa-right-panel-modal
                name="worksite-task-comments-modal"
                @before-open="$bus.$emit('worksite-task-comments-modal-before-open', $event)"
                @opened="$bus.$emit('worksite-task-comments-modal-opened', $event)"
                @before-close="$bus.$emit('worksite-task-comments-modal-before-close', $event)"
                @closed="$bus.$emit('worksite-task-comments-modal-closed', $event)"
                right-panel-width="600px"
                right-panel-width-xl="600px"
            >
                <portal-target name="worksite-task-comments-modal-content"></portal-target>
            </v-spa-right-panel-modal>

            <create-quote-modal></create-quote-modal>

            <invoicing-temp-components />
            <worksite-components />
            <planning-components />
        </portal>
        
        <upload-file-dropzone></upload-file-dropzone>
        <portal-target name="dropzone"></portal-target>
    </div>
</template>

<style lang="scss">
    .worksite-layout {

        .multiselect {
            .multiselect__tags {
                @apply w-full text-black placeholder-gray-500 font-normal px-3 pt-3 rounded border border-gray-300 bg-white outline-none hover:border-gray-600 focus:border-primary max-w-input text-sm    
            }
            .multiselect__tag {
                @apply bg-gray-200 text-gray-600;
            }
            .multiselect__option {
                @apply text-sm;
            }
            
            .multiselect__option--group-selected.multiselect__option--highlight {
                @apply bg-white text-black;
                &:after {
                    @apply bg-white text-gray-500;
                }
            }

            .multiselect__option--selected {
                @apply font-normal bg-white text-black;
                &:hover {
                    @apply text-black;
                }
                &:after {
                    @apply bg-white text-black;
                }
            }
            .multiselect__option--selected:hover {
                @apply bg-gray-100 text-black;
                &:after {
                    @apply bg-gray-100 text-gray-500;
                }
            }
            .multiselect__option--highlight:not(.multiselect__option--selected) {
                @apply bg-white text-gray-500;
                &:hover {
                    @apply text-black;
                }
                &:after {
                    @apply bg-white text-gray-500;
                }
            }
            .multiselect__tag-icon {
                @apply text-gray-600;
                &:after {
                    @apply text-gray-600;
                }
                &:focus, &:hover {
                    @apply bg-gray-200 text-gray-800;
                }
            }
        }
    }
</style>

<script>

    import { Type } from "@spa/api/app";
    import Sidebar from './_Layout/Sidebar';
    import ResponsiveSidebar from './_Layout/ResponsiveSidebar';
    import Content from './_Layout/Content';

    import CreateQuoteModal from './Modals/CreateQuoteModal';
    import UploadFileDropzone from './Modals/UploadFileDropzone';

    import InvoicingTempComponents from '~spa/apps/trustup-pro-invoicing-temp-js/components/Components';
    import PlanningComponents from '~spa/apps/trustup-pro-planning-js/components/Components';
    import WorksiteComponents from '~spa/apps/trustup-pro-worksite-js/components/Components';

    export default {
        components: {
            'layout-responsive-sidebar': ResponsiveSidebar,
            'layout-sidebar': Sidebar,
            'layout-content': Content,
            CreateQuoteModal,
            UploadFileDropzone,

            InvoicingTempComponents,
            PlanningComponents,
            WorksiteComponents,
        },
        data() {
            return {
                display: {
                    sidebar: true
                }
            }
        },
        methods: {
            handleSidebar(status) {
                if ( ! this.$store.state.layout.sidebar ) {
                    this.display.sidebar = false;
                    return;
                }

                this.display.sidebar = status;
            },
        },

        created()
        {
            this.$bus.$on('clients.edit', (params) => {
                params.onInit = (contact) => {
                    contact.types = new Type().translate([{ name: 'customer' }]);
                    return contact;
                };
                this.$bus.$emit('contacts.edit', params);
            });
        },

        mounted()
        {
            if ( ! this.$store.state.layout.sidebar ) {
                this.$bus.$emit('spa:hideSidebar')
            }
        }
    }
</script>  