export default class WorksiteTaskColumn {
    constructor(data = {}) {
        this.originalData = data;
        if ( ! this.originalData.title ) {
            this.originalData.title = null;
        }

        for (let field in data) {
            this[field] = data[field];
        }

        if ( this.type && ! this.title ) {
            this.title = window.App.$t(`worksite.tasks.columns.${this.type}.column`);
        }
    }

    data() {
        let data = {};

        for (let property in this.originalData) {
            data[property] = this[property];
        }

        return data;
    }

    getDefaultValue(first = false)
    {
        switch ( this.type ) {
            case "text":
                return first ? window.App.$t('worksite.tasks.columns.text.value_first') : window.App.$t('worksite.tasks.columns.text.value');

            case "user":
                return [window.App.$store.state.user.id];

            case "date":
                return window.moment().format('YYYY-MM-DD');

            case "status":
                return this.accepted_values[0].label;
            
            default: 
                return null;
        }
    }

    getWidth()
    {
        switch ( this.type ) {
            case "text":
                return 'auto';

            case "date":
                return "240px";

            case "status":
                return "130px";

            case "user":
                return "140px";

            case "comment":
                return "90px";
            
            default: 
                return 'auto';
        }
    }

    getIcon()
    {
        switch ( this.type ) {
            case "text":
                return 'far fa-text';

            case "user":
                return 'far fa-user';

            case "date":
                return 'far fa-calendar-week';

            case "status":
                return 'far fa-check-circle';

            case "comment":
                return 'far fa-comment';
            
            default: 
                return 'far fa-plus';
        }
    }

    getName()
    {
        return window.App.$t(`worksite.tasks.columns.${this.type}.label`);
    }

    static defaults()
    {
        return [
            this.text({position: 1}),
            this.user({position: 2}),
            this.date({position: 3}),
            this.status({position: 4}),
            this.comment({position: 5}),
        ];
    }

    static types()
    {
        return [
            'text',
            'comment',
            'user',
            'date',
            'status'
        ];
    }
    
    static text(data = {})
    {
        return new this({...data, ...{type: 'text'}});
    }
    
    static comment(data = {})
    {
        return new this({...data, ...{type: 'comment'}});
    }
    
    static date(data = {})
    {
        return new this({...data, ...{type: 'date'}});
    }
    
    static user(data = {})
    {
        return new this({...data, ...{type: 'user'}});
    }

    static status(data = {})
    {
        return new this({...data, ...{type: 'status', accepted_values: [
            {
                label: window.App.$t('worksite.tasks.columns.status.values.pending'),
                color: '#ffffff',
                background_color: '#E3E3E2'
            },
            {
                label: window.App.$t('worksite.tasks.columns.status.values.in_progress'),
                color: '#ffffff',
                background_color: '#F39F2F'
            },
            {
                label: window.App.$t('worksite.tasks.columns.status.values.completed'),
                color: '#ffffff',
                background_color: '#26BC57'
            },
            {
                label: window.App.$t('worksite.tasks.columns.status.values.stuck'),
                color: '#ffffff',
                background_color: '#D04444'
            }
        ]}});
    }

};