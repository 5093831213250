<template>
    <v-spa-submenu-container position="left-0" ref="submenu">
        <template v-slot:default="slotProps">
            <div class="min-h-[40px] flex items-center overflow-hidden rounded-full group cursor-default">
                <span class="flex items-center space-x-2">
                    <div class="w-[12px] h-[12px] rounded-full flex-none" :style="`background-color: ${$store.state.worksite.color};`"></div>
                    <span>{{ $store.state.worksite.title }}</span>
                </span>
                <div :class="{'hidden group-hover:flex': ! switchDisplayed, 'flex': switchDisplayed, '!flex': $refs.submenu && $refs.submenu.isDisplayed}" class="items-center justify-center px-4 py-2 ml-2 bg-white bg-gray-100 rounded-full cursor-pointer" @click="slotProps.toggle()">
                    <i class="text-xs text-gray-800 fas fa-chevron-down"></i>
                    <span class="ml-2 text-xs">
                        {{ $t('worksite.switch_worksite') }}
                    </span>
                </div>
            </div>
        </template>
        <div class="w-[400px] p-4 text-sm" slot="submenu">
            <input v-model="query" type="text" class="block w-full px-4 py-2 mb-4 text-sm text-gray-600 bg-gray-100 rounded" :placeholder="$t('sidebar.worksite_switch.search_placeholder')" />
            <router-link class="flex justify-between w-full mb-2 text-gray-700 hover:text-primary-dark" :class="{'text-black': worksite.uuid == $store.state.worksite.uuid}" :to="worksite.getRoute()" v-for="worksite in queriedWorksites" :key="worksite.uuid" @click.native="hideSubmenu">
                <span class="flex-1 block mr-2 overflow-hidden overflow-ellipsis" :class="{'underline underline-offset-2': worksite.uuid == $store.state.worksite.uuid}">{{ worksite.title }}</span>
                <span>{{ worksite.total_quoted | price }}</span>
            </router-link>
        </div>
    </v-spa-submenu-container>
</template>

<script>
    export default {
        data() {
            return {
                query: ''
            }
        },
        props: {
            switchDisplayed: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            worksites()
            {
                let worksites = this.$store.state.models.worksites;

                if ( ! worksites || worksites.isEmpty() ) {
                    return [];
                }

                return worksites.sort((a, b) => {
                    return new Date(b.created_at) - new Date(a.created_at);
                });
            },
            
            queriedWorksites() {
                if ( ! this.query ) {
                    return this.worksitesWithCurrent;
                }
                
                return this.worksites.filter(worksite => {
                    return worksite.title.toLowerCase().includes(this.query.toLowerCase());
                }).slice(0, 3);
            },

            worksitesWithCurrent()
            {
                let worksites = this.worksites.slice(0, 3);

                if ( worksites.filter(w => w.uuid == this.$store.state.worksite.uuid).length > 0 ) {
                    return worksites;
                }

                worksites.unshift(this.$store.state.worksite);
                return worksites;
            }
        },
        methods: {
            hideSubmenu() {
                if ( this.$refs.submenu ) {
                    this.$refs.submenu.hide()
                }
            }
        }
    }
</script>
