<template>
    <div class="relative">

        <div class="max-w-[600px] mx-auto" v-if="worksite.phases.isEmpty()">
            <div class="flex justify-center mb-4">
                <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
            </div>
            <div class="text-center">
                <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('worksite.phases.empty.title') }}</h1>
                <p class="mb-8 text-sm text-gray-500">{{ $t('worksite.phases.empty.text') }}</p>
                <div class="flex justify-center">
                    <v-spa-loading-button ref="addPhaseButton" @clicked="displayAddPhaseModal">
                        {{ $t('worksite.phases.empty.button') }}
                    </v-spa-loading-button>
                </div>
            </div>
        </div> 

        <template v-else>
            <div class="flex items-center justify-between mb-6">
                <div>
                    <div class="flex items-stretch border border-gray-300 rounded-lg">
                        <button class="flex items-center px-6 py-3 space-x-2 text-sm rounded-l-lg" :class="view == 'list' ? 'bg-gray-200' : 'bg-white hover:bg-gray-200 cursor-pointer'" @click.prevent="setView('list')">
                            <i class="fal fa-list-ol"></i>
                            <span>{{ $t('worksite.phases.views.list') }}</span>
                        </button>
                        <button class="flex items-center px-6 py-3 space-x-2 text-sm rounded-r-lg" :class="view == 'timeline' ? 'bg-gray-200' : 'bg-white hover:bg-gray-200 cursor-pointer'" @click.prevent="setView('timeline')">
                            <i class="fal fa-calendar-week"></i>
                            <span>{{ $t('worksite.phases.views.timeline') }}</span>
                        </button>
                    </div>
                </div>
                <div>
                    <v-spa-loading-button
                        ref="addPhaseButton"
                        extra-class="border border-gray-400"
                        :color="$spa.css.button.color.white"
                        @clicked="$bus.$emit('worksite:worksite-phase-form-modal:open', {worksite, defaultTitle: $t('modals.worksite-phase.default_title', {index: worksite.phases.length()+1})})"
                    >
                        {{ $t('worksite.phases.add_phase_button') }}
                    </v-spa-loading-button>
                </div>
            </div>

            <template v-if="view == 'list'">
                <worksite-list-phase
                    :worksite="worksite" 
                    :phase="phase"
                    :key="phase.uuid"
                    v-for="phase in phasesByStartDate"
                />
            </template>

            <worksite-phases-timeline
                ref="timeline"
                :worksite="worksite"
                :phases="phasesByStartDate"
                v-if="view == 'timeline'"
            />
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import WorksiteListPhase from './Phases/List/Phase.vue';
    import WorksitePhasesTimeline from './Phases/Timeline/Timeline.vue';

    export default {        
        components: {
            WorksiteListPhase,
            WorksitePhasesTimeline
        },

        data() {
            return {
                view: 'list'
            }
        },

        computed: {
            ...mapState(['worksite']),
            
            phasesByStartDate()
            {
                return this.worksite.phases.sort((a, b) => {
                    return new Date(a.starts_at) - new Date(b.starts_at);
                });
            },
        },

        methods: {
            setView(view)
            {
                this.view = view;
                this.$store.dispatch('replaceQueryInUrl', {url: this.$route.path, params: {view: this.view}});
            },

            displayAddPhaseModal()
            {
                this.$bus.$emit('worksite:worksite-phase-form-modal:open', {
                    worksite: this.worksite,
                    worksitePhases: this.worksite.phases,
                    defaultTitle: this.$t('modals.worksite-phase.default_title', {
                        index: this.worksite.phases.length() + 1
                    })
                })
            }
        },

        created()
        {
            if ( this.$route.query.view && this.view !== this.$route.query.view ) {
                this.setView(this.$route.query.view);
            }
        }
    }
</script>