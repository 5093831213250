import Vue from 'vue';

require("./_spa");

require('~spa/_bootstrap');
require('~spa/_string-helpers');
require('~spa/_vue-bootstrap');

import App from './components/App';
import router from './_router';
import i18n from './_i18n';
import store from './store';

const app = new Vue({
    el: '#app',
    template: '<App/>',
    components: {
        App,
    },
    i18n,
    store,
    router,
    created() {
        window.addEventListener('online', (event) => {
            window.Toasteo.success(
                this.$t('offline_mode.online')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
        window.addEventListener('offline', (event) => {
            window.Toasteo.warning(
                this.$t('offline_mode.offline')
            );
            this.$store.commit('setOnlineStatus', event.type);
        });
    }
}).$mount('#app');

window.App = app;

export { app };
