<template>
    <model-modal
        component="v-spa-mixed-modal"
        :model-class="modelClass"
        modal-type="form"
        @opened="onOpened"
        @resetted="onReset"
        ref="modal"
    >
        <template v-slot:default>
            <div class="relative">
                <form @submit.prevent="submit" v-if="quotes && quotes.length > 0">
                    <div class="mb-6">
                        <v-spa-label id="worksite-progress-billing-quote-form-quote" :label="$t('worksite.progress-billing-quote.form.labels.select_import_document')"></v-spa-label>
                        <select v-model="quoteToImport" :class="$spa.css.form.select" id="worksite-progress-billing-quote-form-quote">
                            <option :value="quote" v-for="quote in quotes" :key="quote.uuid">
                                {{ quote.number }}
                            </option>
                        </select>
                    </div>
                    <div class="flex justify-end max-w-input">
                        <v-spa-loading-button :loading="form.isLoading()">
                            <i class="mr-2 fal fa-check"></i>
                            {{ $t(`worksite.progress-billing-quote.form.button`) }}
                        </v-spa-loading-button>
                    </div>
                </form>
                <div class="p-4 bg-white border border-gray-200 rounded cursor-pointer hover:shadow" v-else>
                    <p class="text-sm text-center text-gray-600">{{ $t('worksite.progress-billing-quote.form.no_document_to_import') }}</p>
                </div>
            </div>
        </template>
    </model-modal>
</template>

<script>
    import ModelModal from '@spa/apps/trustup-pro-base-js/components/Model/Modal';
    import Model from './../models/WorksiteProgressBillingQuote';
    import Form from '@spa/classes/Form';

    export default {
        components: {
            ModelModal
        },

        data() {
            return {
                modelClass: Model,
                quotes: null,
                form: new Form({
                    quoteToImport: null  
                })
            };
        },

        computed: {
            model()
            {
                return this.$refs.modal?.model || null;
            },
        },

        methods: {
            async onOpened()
            {
                this.quotes = this.$store.state.worksite.profitability_documents.filter(d => d.profitability_type === 'quoted' && d.external_uuid !== null);
                
                if ( this.quotes.length > 0 ) {
                    this.quoteToImport = this.quotes[0];
                }
            },

            onReset()
            {
                this.quotes = null;
                this.quoteToImport = null;
            },

            async getFormData()
            {
                let quote = await this.$spa.TrustUpPro.getQuote(this.quoteToImport.external_uuid);
                let data = {
                    number: quote.number,
                    lines: quote.items.map(item => {
                        return {
                            title: item.title,
                            quantity: item.quantity,
                            price: item.price,
                            price_type_id: item.price_type_id,
                            vat_option_id: item.vat_option_id,
                            unit: item.price_type?.title || '',
                        }
                    })
                };
                console.log(quote, data);
                return data;
            },

            async submit()
            {
                if ( this.form.isLoading() ) {
                    return;
                }

                this.form.load();

                let data = await this.getFormData();
                await window.Worksite.endpoints.progressBillingQuote.setWorksite(this.$store.state.worksite).store(data);

                this.$refs.modal.close();
            }
        }
    }
</script>