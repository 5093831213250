export default class Gantt
{

    constructor(phases)
    {
        this.phases = phases;
        this.phaseHeight = 40;
        this.phaseHeightSeparation = 10;

        this.calculateOptimalDayWidth();

        this.phases = phases.map((phase, index) => {

            phase.range = window.moment().range(phase.starts_at, phase.ends_at);
            phase.width = Array.from(phase.range.by('day')).length * this.dayWidth;

            let diff = window.moment(phase.starts_at).diff(this.firstDay(), 'days');
            phase.left = diff * this.dayWidth;

            let container = document.querySelector('.worksite-phases-timeline-container');
            let containerWidth = container ? container.clientWidth : window.innerWidth;
            phase.fully_visible = phase.width < containerWidth;

            phase.top = 0;

            if ( index > 0 ) {
                phase.top = phases[index - 1].top;

                if ( phase.range.overlaps(phases[index - 1].range, { adjacent: true }) ) {
                    phase.overlaps_with_previous = true;
                    phase.top = phases[index - 1].top + this.phaseHeight + this.phaseHeightSeparation;

                    if ( index > 1 && phase.range.overlaps(phases[index - 2].range, { adjacent: true }) ) {
                        phase.overlaps_with_second_previous = true;
                    }
                }
            }
            
            return phase;
        });    
    }

    /**
     * Return the total phases height including the separation between them.
     */
    phasesHeight()
    {
        return this.phases.reduce((max, p) => p.top > max ? p.top : max, 0) + this.phaseHeight;
    }

    /**
     * Return the phases containing tasks w/ due dates (displayable in the timeline).
     */
    phasesWithUsableTasks()
    {
        return this.phases.filter(p => {
            return p.trustup_io.tasks
                && p.trustup_io.tasks.tasks
                && p.trustup_io.tasks.tasks.get().length > 0
                && p.trustup_io.tasks.tasks.get().filter(t => t.due_date !== null).length > 0
        });
    }

    /**
     * We want to display the timeline to the start of the first phase or to the first task of any phase found.
     */
    firstDay()
    {
        let phaseFirstDay = this.phases.reduce((min, p) => p.starts_at < min ? p.starts_at : min, this.phases[0].starts_at);
        let phasesWithUsableTasks = this.phasesWithUsableTasks();

        if ( phasesWithUsableTasks.length == 0 ) {
            return phaseFirstDay;
        }

        let taskFirstDay = phasesWithUsableTasks.reduce((min, p) => {
            let lastTask = p.trustup_io.tasks.tasks.get().reduce((min, t) => t.due_date < min ? t.due_date : min, p.trustup_io.tasks.tasks.get()[0].due_date);
            return lastTask < min ? lastTask : min;
        }, phasesWithUsableTasks[0].trustup_io.tasks.tasks.get()[0].due_date);

        if ( ! taskFirstDay ) {
            return phaseFirstDay;
        } 

        return phaseFirstDay < taskFirstDay ? phaseFirstDay : taskFirstDay;
    }

    /**
     * We want to display the timeline to the end of the last phase or to the last task of any phase found.
     */
    lastDay()
    {
        let phaseLastDay = this.phases.reduce((max, p) => p.ends_at > max ? p.ends_at : max, this.phases[0].ends_at);
        let phasesWithUsableTasks = this.phasesWithUsableTasks();
        
        if ( phasesWithUsableTasks.length == 0 ) {
            return phaseLastDay;
        }

        let taskLastDay = phasesWithUsableTasks.reduce((max, p) => {
            let lastTask = p.trustup_io.tasks.tasks.get().reduce((max, t) => t.due_date > max ? t.due_date : max, p.trustup_io.tasks.tasks.get()[0].due_date);
            return lastTask > max ? lastTask : max;
        }, phasesWithUsableTasks[0].trustup_io.tasks.tasks.get()[0].due_date);

        if ( ! taskLastDay ) {
            return phaseLastDay;
        } 

        return phaseLastDay > taskLastDay ? phaseLastDay : taskLastDay;
    }

    /**
     * We want to display a minimum of 5 days even if there is only one phase with a duration of 1 to 4 days
     * To do so, we artificially increase the last day to meet this threshold.
     */
    totalDays()
    {
        let lastDay = this.lastDay();

        // Check if the difference between the first and last day is less than 5 days
        // If so, increase the lastDay by the difference
        let diff = window.moment(lastDay).diff(window.moment(this.firstDay()), 'days');
        if ( diff < 4 ) {
            lastDay = window.moment(lastDay).add(4 - diff, 'days').format('YYYY-MM-DD');
        }

        return window.moment().range(this.firstDay(), lastDay).by('day');
    }

    totalDaysArray()
    {
        return Array.from(this.totalDays());
    }

    /**
     * By default a single day takes 200px but if there is less than 7 days displayed
     * then a single day will take 300px instead.
     */
    calculateOptimalDayWidth()
    {
        this.dayWidth = this.totalDaysArray().length < 7 ? 300 : 200;
    }

    /**
     * Return the total width of the timeline.
     */
    totalWidth()
    {
        return this.totalDaysArray().length * this.dayWidth;
    }

    /**
     * For previous worksites, we will start the timeline at the very end.
     * For future worksites, we will start the timeline at the very beginning.
     * For current worksites, we'll start the timeline two days before the current day (or one, or the current one as a fallback).
     */
    findBestDay()
    {
        let firstDay = this.firstDay();
        if ( window.moment(firstDay).isAfter(window.moment()) ) {
            return window.moment(firstDay).format('YYYY-MM-DD');
        }
        
        let lastDay = this.lastDay();
        if ( window.moment(lastDay).isBefore(window.moment()) ) {
            return window.moment(lastDay).format('YYYY-MM-DD');
        }

        let days = this.totalDaysArray();

        let twoDaysBeforeToday = window.moment().subtract(2, 'days');
        if ( days.some(d => d.format('YYYY-MM-DD') == twoDaysBeforeToday.format('YYYY-MM-DD')) ) {
            return twoDaysBeforeToday.format('YYYY-MM-DD');
        }

        let oneDayBeforeToday = window.moment().subtract(1, 'day');
        if ( days.some(d => d.format('YYYY-MM-DD') == oneDayBeforeToday.format('YYYY-MM-DD')) ) {
            return oneDayBeforeToday.format('YYYY-MM-DD');
        }

        return window.moment().format('YYYY-MM-DD');
    }

}