<template>
    <div class="flex flex-col h-full mt-6">
        <div class="flex-auto">
            <template v-if="$store.state.account">
                <template v-if="$route.params.worksite">
                    <v-spa-layout-sidebar-responsive-link section="worksite-informations" :to="{name: 'account.worksite.informations', params: {account: $store.state.account.uuid, worksite: $route.params.worksite}}" icon="far fa-home">
                        {{ $t('sidebar.worksite_informations') }}
                    </v-spa-layout-sidebar-responsive-link>
                    <v-spa-layout-sidebar-responsive-link section="worksite-profitability" :to="{name: 'account.worksite.profitability', params: {account: $store.state.account.uuid, worksite: $route.params.worksite}}" icon="far fa-chart-line">
                        {{ $t('sidebar.worksite_profitability') }}
                    </v-spa-layout-sidebar-responsive-link>
                    <v-spa-layout-sidebar-responsive-link section="worksite-tasks" :to="{name: 'account.worksite.tables', params: {account: $store.state.account.uuid, worksite: $route.params.worksite}}" icon="far fa-stream">
                        {{ $t('sidebar.worksite_tasks') }}
                    </v-spa-layout-sidebar-responsive-link>
                    <v-spa-layout-sidebar-responsive-link section="worksite-progress" :to="{name: 'account.worksite.progress', params: {account: $store.state.account.uuid, worksite: $route.params.worksite}}" icon="far fa-tasks">
                        {{ $t('sidebar.worksite_progress') }}
                    </v-spa-layout-sidebar-responsive-link>
                    <v-spa-layout-sidebar-responsive-link section="worksite-providers" :to="{name: 'account.worksite.providers', params: {account: $store.state.account.uuid, worksite: $route.params.worksite}}" icon="far fa-truck">
                        {{ $t('sidebar.worksite_providers') }}
                    </v-spa-layout-sidebar-responsive-link>
                    <v-spa-layout-sidebar-responsive-link section="worksite-files" :to="{name: 'account.worksite.files', params: {account: $store.state.account.uuid, worksite: $route.params.worksite}}" icon="far fa-file-alt">
                        {{ $t('sidebar.worksite_files') }}
                    </v-spa-layout-sidebar-responsive-link>

                    <div class="py-2">
                        <v-spa-layout-sidebar-spacer></v-spa-layout-sidebar-spacer>
                    </div>
                    <v-spa-layout-sidebar-responsive-link section="worksites-index" :to="{name: 'account.homepage', params: {account: $store.state.account.uuid, worksite: $route.params.worksite}}" icon="far fa-home">
                        {{ $t('sidebar.worksite_switch.back') }}
                    </v-spa-layout-sidebar-responsive-link>
                </template>
                
                <template v-if="! $store.state.worksite && ! $route.params.worksite">
                    <div class="mt-2"></div>
                    <v-spa-layout-sidebar-responsive-link section="worksites-index" :to="{name: 'account.worksites.index', params: {account: $store.state.account.uuid}}" icon="far fa-list-alt">
                        {{ $t('sidebar.worksites_index') }}
                    </v-spa-layout-sidebar-responsive-link>
                    <v-spa-layout-sidebar-responsive-link section="worksites-archives" :to="{name: 'account.worksites.archives', params: {account: $store.state.account.uuid}}" icon="far fa-archive">
                        {{ $t('sidebar.worksites_archives') }}
                    </v-spa-layout-sidebar-responsive-link>
                </template>
            </template>
        </div>
    </div>
</template>



<script>
    export default {
        //
    }
</script>
