<template>
    <v-spa-layout-page
        :tabs="tabs"
        :loading="loading"
        :back-link="{name: 'account.worksites.index'}"
        @header-mouseover="displaySwitch = true"
        @header-mouseleave="displaySwitch = false"
    >
        <template v-if="worksite">
            <template slot="title">
                <worksite-switch :switch-displayed="displaySwitch"></worksite-switch>
            </template>

            <v-spa-loading-button slot="title-button" ref="archiveButton" :color="$spa.css.button.color.white" extra-class="border border-gray-200" @clicked="toggleWorksiteArchiveStatus">
                <template v-if="! worksite.archived_at">
                    <!-- <i class="mr-2 fal fa-archive"></i> -->
                    {{ $t('worksite.informations.archive_button') }}
                </template>
                <template v-if="worksite.archived_at">
                    <!-- <i class="mr-2 fal fa-archive"></i> -->
                    {{ $t('worksite.informations.unarchived_button') }}
                </template>
            </v-spa-loading-button>

            <router-view slot="content" :key="'worksite-' + worksite.uuid + '-' + worksite.updated_at + '-' + $route.fullPath"></router-view>
        </template>
    </v-spa-layout-page>
</template>

<script>
    import {mapState} from 'vuex';
    import TitleTab from '~base/components/_Partials/TitleTab.vue';
    import WorksiteSwitch from '~base/components/_Partials/WorksiteSwitch';

    export default {
        components: {
            TitleTab,
            WorksiteSwitch
        },
        data() {
            return {
                displaySwitch: false,
                loading: true,
                tabs: [
                    {
                        name: 'account.worksite.informations',
                        label: this.$t('worksite.tabs.informations'),
                    },
                    {
                        name: 'account.worksite.profitability',
                        label: this.$t('worksite.tabs.profitability'),
                    },
                    {
                        name: 'account.worksite.phases',
                        label: this.$t('worksite.tabs.phases'),
                    },
                    // {
                    //     name: 'account.worksite.informations',
                    //     label: this.$t('worksite.tabs.checklist'),
                    // },
                    // {
                    //     name: 'account.worksite.informations',
                    //     label: this.$t('worksite.tabs.planning'),
                    // },
                    {
                        name: 'account.worksite.files',
                        label: this.$t('worksite.tabs.files'),
                    },
                    {
                        name: 'account.worksite.resources',
                        label: this.$t('worksite.tabs.resources'),
                    },
                    // {
                    //     name: 'account.worksite.informations',
                    //     label: this.$t('worksite.tabs.progression'),
                    // },
                    {
                        name: 'account.worksite.time-sheets',
                        label: this.$t('worksite.tabs.time_sheets'),
                    },
                    {
                        name: 'account.worksite.progress-billings.index',
                        label: this.$t('worksite.tabs.progress-billings'),
                    },
                    {
                        name: 'account.worksite.tables',
                        label: this.$t('worksite.tabs.tables'),
                    },
                ]
            }
        },
        computed: {
            ...mapState(['worksite']),
        },

        methods: {
            async toggleWorksiteArchiveStatus() {
                if ( this.$refs.archiveButton.submitted ) {
                    return;
                }

                this.$refs.archiveButton.load();
                await window.Worksite.endpoints.worksite[this.worksite.archived_at ? 'unarchive' : 'archive'](this.worksite.uuid);
                this.$refs.archiveButton.stop();
            },
            
            async getWorksite(uuid)
            {
                this.loading = true;

                let worksite = await window.Worksite.endpoints.worksite.retrieve(uuid, {tasks: true});
                await worksite.getFullContact();
                this.$store.commit('setWorksite', worksite);

                let {worksites} = await window.Worksite.endpoints.worksite.index({all: true})
                this.$store.commit('setWorksites', worksites);
                
                this.loading = false;
            }
        },
        
        created() {            
            if ( this.worksite && this.worksite.uuid == this.$route.params.worksite ) {
                return this.loading = false;
            }

            this.getWorksite(this.$route.params.worksite)
                .then(() => this.loading = false);
        },


        /**
         * Reset the current worksite(s) when changing page to update the sidebar.
         * Do not reset if we are changing route but not worksite, ie from informations to profitability.
         */
        beforeRouteUpdate (to, from, next) {
            if ( to.params && to.params.worksite && to.params.worksite == this.worksite.uuid ) {
                return next();
            }

            this.$store.commit('setWorksite', null);
            this.$store.commit('setWorksites', null);
            next();
        },
        beforeRouteLeave(to, from, next) {
            this.$store.commit('setWorksite', null);
            this.$store.commit('setWorksites', null);
            next();
        },
    }
</script>
