<template>
    <v-spa-layout-page
        :loading="loading"
        :back-link="opportunity ? {name: opportunity.archived_at ? 'account.opportunities.archives' : 'account.opportunities.index'} : null"
    >
        <template v-if="opportunity">
            <template slot="title">
                <div class="flex items-center space-x-2">
                    <span>{{ opportunity.title }}</span>
                    <div>
                        <opportunity-source-badge :source="opportunity.source" v-if="opportunity.source"></opportunity-source-badge>
                    </div>
                </div>
                <span class="block text-sm text-gray-600">
                    {{ $t('opportunity.total_quoted', {total: centsToPrice(opportunity.total_quoted)}) }}
                </span>
            </template>
            <template slot="title-button">
                <v-spa-loading-button v-if="! opportunity.archived_at" ref="archiveButton" :color="$spa.css.button.color.white" extra-class="border border-gray-200" @clicked="archiveOpportunity">
                    {{ $t('opportunity.archive_button') }}
                </v-spa-loading-button>
                <v-spa-loading-button v-if="opportunity.archived_at" ref="unarchiveButton" :color="$spa.css.button.color.white" extra-class="border border-gray-200" @clicked="unarchiveOpportunity">
                    {{ $t('opportunity.unarchive_button') }}
                </v-spa-loading-button>
                <v-spa-loading-button v-if="opportunity.worksites.length == 0" ref="worksiteButton" extra-class="ml-4" @clicked="transformOpportunityToWorksite">
                    {{ $t('opportunity.transform_to_worksite_button') }}
                </v-spa-loading-button>
                <v-spa-loading-button v-if="opportunity.worksites.length > 0" ref="worksiteButton" extra-class="ml-4" @clicked="$router.push({name: 'account.worksite.homepage', params: {worksite: opportunity.worksites[0]}})">
                    {{ $t('opportunity.open_worksite_button') }}
                </v-spa-loading-button>
            </template>
            <router-view slot="content" :key="'opportunity-' + opportunity.uuid + '-' + opportunity.updated_at + '-' + $route.fullPath"></router-view>
        </template>
    </v-spa-layout-page>
</template>

<script>
    import {mapState} from 'vuex';
    import OpportunitySourceBadge from './_Partials/SourceBadge.vue';

    export default {
        components: {
            OpportunitySourceBadge
        },
        computed: {
            ...mapState(['opportunity', 'opportunityQuotes'])
        },
        data() {
            return {
                loading: true,
            }
        },
        methods: {
            async getOpportunity(uuid)
            {
                this.loading = true;

                let opportunity = await window.Worksite.endpoints.opportunity.retrieve(uuid);
                await opportunity.getFullContact();
                this.$store.commit('setOpportunity', opportunity);
                
                this.loading = false;
            },

            async updateOpportunityArchiveStatus(button, action)
            {
                button.load();
                await window.Worksite.endpoints.opportunity[action](this.opportunity.uuid);
                button.stop();
            },

            async archiveOpportunity()
            {
                await this.updateOpportunityArchiveStatus(this.$refs.archiveButton, 'archive');
            },

            async unarchiveOpportunity()
            {
                await this.updateOpportunityArchiveStatus(this.$refs.unarchiveButton, 'unarchive');
            },

            async transformOpportunityToWorksite()
            {
                this.$refs.worksiteButton.load();
                let worksite = await this.opportunity.transformToWorksite(this.$store.state.opportunityQuotes);
                this.$router.push({name: 'account.worksite.homepage', params: {worksite: worksite.uuid}});
            },

            async refresh()
            {
                this.loading = true;
                await this.getOpportunity(this.$route.params.opportunity);
                this.loading = false;
            }

        },
        
        async created() {            
            await this.getOpportunity(this.$route.params.opportunity);
            this.loading = false;
        },
        beforeDestroy() {
            this.$store.commit('setOpportunity', null);
            this.$store.commit('setOpportunityQuotes', null);
        }
    }
</script>
