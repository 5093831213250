<template>
    <div id="timeline"></div>
</template>

<style>
    .fc .fc-timeline-now-indicator-arrow {
        display: none !important;
    }
    .fc .fc-timeline-now-indicator-container {
        top: 10px !important;
    }
    .fc-timeline-now-indicator-line:after {
        content: '';
        background: #DA6153;
        border-radius: 99px;
        height: 10px;
        width: 10px;
        border: none;
        position: absolute;
        left: calc(100% - 5.5px);
    }
    .fc-timeline-now-indicator-line {
        border-color: #DA6153 !important;
        margin: 0 19px !important;
    }
    .fc-day:not(.fc-timeline-slot-label) {
        background: rgb(250, 250, 250);
    }
    .fc-day-sat:not(.fc-timeline-slot-label),
    .fc-day-sun:not(.fc-timeline-slot-label) {
        background: rgb(245, 245, 245);
    }
    .fc-timeline-slot-label {
        font-weight: 300;
        font-size: 14px;
        color: #999;
    }
    .fc .fc-datagrid-cell-main {
        @apply text-sm;
    }
    .fc .fc-timeline-slot-cushion {
        padding: 4px 16px !important;
    }
    .fc-theme-standard td,
    .fc-theme-standard th,
    .fc .fc-scrollgrid-section-body table {
        border: none !important;
        border-bottom: 1px solid rgb(230, 230, 230) !important;
    }
    .fc-theme-standard td.without-border {
        border-bottom: 1px solid white !important;
    }
    .fc-theme-standard td.fc-timeline-resource-child .fc-datagrid-cell-cushion {
        padding: 8px 20px !important;
    }
    .fc-theme-standard .fc-timeline-header-row  .fc-timeline-slot {
        border-right: 1px solid rgb(230, 230, 230) !important;
    }
    .fc-resource:not(.without-border) .fc-timeline-lane-frame:after {
        content: '';
        position: absolute;
        top: 100%;
        border-bottom: 1px solid rgb(230, 230, 230) !important;
        left:0;
        right:0;
        z-index: 4;
    }
    .fc .fc-datagrid-cell-cushion {
        padding: 12px 20px;
    }
    .fc .fc-datagrid-cell-main {
        @apply text-gray-800;
    }
    .fc-direction-ltr .fc-timeline-event.fc-event-end {
        margin-top: 10px;
    }
    .fc-theme-standard .fc-scroller tr:first-of-type .fc-resource {
        padding-top: 20px;
    }
    
    .fc-timeline-event {
        @apply bg-primary rounded cursor-pointer;
        padding: 4px 10px;
    }
    .fc-theme-standard .fc-scrollgrid {
        border: none !important;
    }
    .fc-timeline-event-task,
    .fc-timeline-event-trustup-io-task {
        margin-top: 12px !important;
        background: white !important;
        @apply shadow border border-gray-300;
    }
    .fc-timeline-event-task:hover,
    .fc-timeline-event-trustup-io-task:hover {
        @apply shadow-lg;
    }
    .fc-timeline-event-trustup-io-task .fc-event-title-container,
    .fc-timeline-event-task .fc-event-title-container {
        @apply flex items-center justify-between;
    }

    .fc-direction-ltr .fc-datagrid-expander {
        position: absolute;
        right: 8px;
    }
    .fc-icon.fc-icon-plus-square:before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      @apply text-gray-800;
      content: "\f078";
    }
    .fc-icon.fc-icon-minus-square:before {
      font-family: "Font Awesome 5 Pro";
      font-weight: 500;
      @apply text-gray-800;
      content: "\f077";
    }
</style>

<script>
    
    import { Calendar } from '@fullcalendar/core';
    import momentPlugin from '@fullcalendar/moment';
    import interaction from '@fullcalendar/interaction';
    import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
    import frLocale from '@fullcalendar/core/locales/fr';
    import nlLocale from '@fullcalendar/core/locales/nl';
    import deLocale from '@fullcalendar/core/locales/de';

    let locales = {
        fr: frLocale,
        nl: nlLocale,
        de: deLocale
    };

    export default {
        props: {
            resources: {
                type: Array,
                required: true
            },
            events:{
                type: Array,
                required: true
            },
        },

        data() {
            return {
                timeline: null
            }
        },

        methods: {
            async render()
            {
                let vm = this;
                this.timeline = new Calendar(document.getElementById('timeline'), {
                    timeZone: 'UTC',
                    locale: this.$store.state.locale == 'en' ? 'en' : locales[this.$store.state.locale],
                    plugins: [ interaction, resourceTimelinePlugin, momentPlugin ],
                    initialView: 'resourceTimelineYear',
                    resourceAreaHeaderContent: ' ',
                    scrollTime: this.$moment().format('YYYY-MM-DD HH:mm'),
                    slotLabelFormat: [
                        { month: 'long', year: 'numeric' }, // top level of text
                        { day: 'numeric' }
                    ],
                    displayEventTime: false,
                    stickyHeaderDates: true,
                    headerToolbar: false,
                    resourceOrder: 'position',
                    resources: this.resources,
                    events: this.events,
                    resourcesInitiallyExpanded: false,
                    nowIndicator: true,
                    resourceAreaWidth: '360px',
                    slotMinWidth: 70,

                    eventClick(info)
                    {
                        if ( info.event && info.event.extendedProps && info.event.extendedProps.link ) {
                            vm.$router.push(info.event.extendedProps.link);
                        }
                    },

                    /**
                     * This is just to add the group title of a task (child resource)
                     * in a new line and smaller...
                     * And to remove the border of the parent resource + all but the last child when expanded.
                     */
                    resourceLabelDidMount(info)
                    {
                        if ( info.resource.extendedProps.borderId ) {
                            info.el.classList.add(`fc-border-${info.resource.extendedProps.borderId}`);
                        }

                        if ( info.resource.extendedProps.classNames ) {
                            info.el.classList.add(info.resource.extendedProps.classNames);
                        }

                        if ( info.resource.extendedProps.hasChildren ) {
                            info.el.querySelector('.fc-datagrid-expander').addEventListener('click', () => {
                                let elements = document.querySelectorAll(`.fc-border-${info.resource.extendedProps.borderId}`);
                                for ( let i = 0; i < elements.length; i++ ) {
                                    elements[i].classList.add('without-border');
                                }
                            });
                        }
                        
                        if ( info.resource.extendedProps.subtitle ) {
                            var subtitle = document.createElement('span');
                            subtitle.classList.add('block', 'text-xs', 'text-gray-600');
                            subtitle.style.paddingLeft = '17px';
                            subtitle.innerText = info.resource.extendedProps.subtitle;
                            info.el.querySelector('.fc-datagrid-cell-main').classList.add('!text-gray-800');
                            info.el.querySelector('.fc-datagrid-cell-main').appendChild(subtitle);
                        }
                    },

                    /**
                     * This is just to change the content of a task event to a font awesome icon...
                     */
                    eventDidMount(info)
                    {
                        if ( info.event.extendedProps.status ) {
                            console.log(info,info.event);
                            info.el.innerHTML = `<span data-tooltip-location="right" data-tooltip="${info.event.extendedProps.status.label} - ${info.event.title}" class="block w-full text-center"><i class="text-sm ${info.event.extendedProps.status.icon}" style="color: ${info.event.extendedProps.status.icon_color}"></i></span>`;
                            // info.el.style.borderLeft = '5px solid ' + info.event.extendedProps.status.background_color;
                            // var bubble = document.createElement('span');
                            // bubble.classList.add('block', 'rounded-full', 'h-[14px]', 'w-[14px]');
                            // bubble.style.backgroundColor = info.event.extendedProps.status.background_color;
                            // info.el.querySelector('.fc-event-title-container').appendChild(bubble);
                            // console.log(info.el.closest('.fc-resource').classList.add('without-border'));
                        }
                    },

                    /**
                     * This is just to hide the border of the parent + child resources except the last one.
                     */
                    resourceLaneDidMount(info)
                    {
                        if ( info.resource.extendedProps.borderId ) {
                            info.el.classList.add(`fc-border-${info.resource.extendedProps.borderId}`);
                        }
                        info.el.classList.add(`fc-timeline-lane-${info.resource.id}`);
                    },

                    /**
                     * SMH. We need to manually scroll to the center of the now indicator...
                     * but we then need to reset the vertical scroll 🤦‍♂️
                     */
                    datesSet(view)
                    {
                        var date = window.moment().format('YYYY-MM-DD');
                        setTimeout(() => {
                            document.querySelector(`.fc-timeline-now-indicator-line`).scrollIntoView({
                                inline: 'center',
                                block: 'nearest'
                            });
                            document.querySelector('.v-spa-layout-content .overflow-y-auto').scrollTop = 0
                        }, 250);
                    },
                });
                this.timeline.render();
            }
        },
        mounted() {
            this.render();
        }
    }
</script>