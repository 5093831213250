import BaseModel from '@spa/apps/trustup-pro-base-js/models/BaseModel';
    
export default class WorksiteProgressBilling extends BaseModel
{

    init(data)
    {    
        data.user_id = data.created_by;
        super.init(data);
    }

    static getPrefix()
    {
        return 'worksite:worksite-progress-billing';
    }

    getQuote(quotes)
    {
        return quotes.find(q => q.id == this.worksite_progress_billing_quote_id);
    }

    getStatusColor()
    {
        switch(this.status)
        {
            case 'draft':
                return 'bg-gray-600';
            case 'validation_requested':
                return 'bg-orange-600';
            case 'validated':
                return 'bg-green-600';
        }
    }

    getStatusBorderColor()
    {
        switch(this.status)
        {
            case 'draft':
                return 'border-gray-600';
            case 'validation_requested':
                return 'border-orange-600';
            case 'validated':
                return 'border-green-600';
        }
    }

    getStatusBadgeClasses()
    {
        return `rounded-full px-3 text-sm py-1 ${this.getStatusColor()} text-white`;
    }

    getInvoicedStatusColor()
    {
        switch(this.getInvoicedStatus())
        {
            case 'invoiced':
                return 'bg-green-600';
            case 'todo':
                return 'bg-red-600';
            case 'not':
                return 'bg-gray-400';
        }
    }

    getInvoicedBadgeClasses()
    {
        return `rounded-full px-3 text-sm py-1 ${this.getInvoicedStatusColor()} text-white`;
    }

    getInvoicedStatus()
    {
        if ( this.external_invoice_uuid ) {
            return 'invoiced';
        }

        if ( this.status == 'validated' ) {
            return 'todo';
        }

        return 'not';
    }

    toInvoice({quote, worksite, title, description, line})
    {
        console.log({quote, worksite, title, description, line}, 'toInvoice', quote.lines[0]);
        let data = {
            contact_uuid: worksite.contact_uuid,
            title: title || window.App.$t('worksite.worksite-progress-billing-invoice-form-modal.default_invoice_title', {title: worksite.title}),
            client_id: null,
            custom_created_at: null,
            document_date: null,
            description: description || window.App.$t('worksite.worksite-progress-billing-invoice-form-modal.default_invoice_description', {quote: quote.number}),
            document_conditions: null,
            payment_communication: null,
            payment_date: null,
            valid_until: null,
            quote_conditions: null,
            delivery_date: null,
            sections: [{
                title: 'Section',
                items: [0]
            }],
            items: [{
                title: line || window.App.$t('worksite.worksite-progress-billing-invoice-form-modal.default_invoice_line', {number: this.number}),
                quantity: 1,
                price: this.total,
                description: '',
                discount: 0,
                discount_unit: 'percentage',
                purchase_price: this.total,
                price_type_id: null,
                vat_option_id: quote.lines[0].vat_option_id || 1,
            }]
        }

        return data;
    }
    
    getLinePrice(line, quote)
    {
        return line.quantity > 0 ? line.total / line.quantity : 0
    }
}