<template>
    <div>
        <div class="relative py-12" v-if="loading"><v-spa-loader /></div>

        <div v-else>
            <div v-if="quotes.isNotEmpty() && billings.isNotEmpty()">
                <div class="flex items-center justify-between mb-4">
                    <h2 class="text-xl text-black">{{ $t('worksite.progress-billings.index.title') }}</h2>
                    <v-spa-loading-button :disabled="quoteIsFullyBilled" @clicked="$router.push({name: 'account.worksite.progress-billings.create', params: {account: $store.state.account.uuid, worksite: $store.state.worksite.uuid}})">
                        <i class="mr-4 fal fa-check" />
                        {{ $t('worksite.progress-billings.index.create_button') }}
                    </v-spa-loading-button>
                </div>

                <div class="flex flex-col mb-8 space-y-2">
                    <div class="px-8 py-3 text-center text-green-600 bg-green-100 rounded" v-if="quoteIsFullyBilled">
                        <p>{{ $t('worksite.progress-billings.index.quote_fully_billed') }}</p>
                    </div>
    
                    <div class="px-8 py-3 text-center text-red-600 bg-red-100 rounded" v-if="billingMissingInvoice">
                        <p>{{ $t('worksite.progress-billings.index.billing_missing_invoice') }}</p>
                    </div>
                </div>

                <table class="w-full mb-12 border border-gray-100 rounded">
                    <thead>
                        <tr>
                            <th class="px-3 py-2 text-xs font-normal text-left uppercase bg-gray-100 border-b border-gray-200 lg:text-sm">
                                {{ $t('worksite.progress-billings.index.table_headings.number') }}
                            </th>
                            <th class="px-3 py-2 text-xs font-normal text-left uppercase bg-gray-100 border-b border-gray-200 lg:text-sm">
                                {{ $t('worksite.progress-billings.index.table_headings.date') }}
                            </th>
                            <th class="hidden px-3 py-2 text-xs font-normal text-left uppercase bg-gray-100 border-b border-gray-200 lg:text-sm md:table-cell">
                                {{ $t('worksite.progress-billings.index.table_headings.created_by') }}
                            </th>
                            <th class="px-3 py-2 text-xs font-normal text-left uppercase bg-gray-100 border-b border-gray-200 lg:text-sm">
                                {{ $t('worksite.progress-billings.index.table_headings.quote') }}
                            </th>
                            <th class="px-3 py-2 text-xs font-normal text-left uppercase bg-gray-100 border-b border-gray-200 lg:text-sm">
                                {{ $t('worksite.progress-billings.index.table_headings.total') }}
                            </th>
                            <th class="px-3 py-2 text-xs font-normal text-left uppercase bg-gray-100 border-b border-gray-200 lg:text-sm">
                                {{ $t('worksite.progress-billings.index.table_headings.validation_status') }}
                            </th>
                            <th class="hidden px-3 py-2 text-xs font-normal text-left uppercase bg-gray-100 border-b border-gray-200 lg:table-cell lg:text-sm">
                                {{ $t('worksite.progress-billings.index.table_headings.invoiced_status') }}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="billing in billings" :key="billing.uuid" class="cursor-pointer" @click.prevent="$router.push({name: 'account.worksite.progress-billings.edit', params: {account: $store.state.account.uuid, worksite: $store.state.worksite.uuid, billing: billing.uuid}})">
                            <td class="px-3 py-2 text-xs border-b border-gray-200 lg:text-sm whitespace-nowrap">
                                {{ billing.number }}
                            </td>
                            <td class="px-3 py-2 text-xs border-b border-gray-200 lg:text-sm">
                                <span class="hidden xl:block">{{ billing.created_at | moment().format('DD MMMM YYYY') }}</span>
                                <span class="block xl:hidden">{{ billing.created_at | moment().format('DD/MM/YYYY') }}</span>
                            </td>
                            <td class="hidden px-3 py-2 text-xs border-b border-gray-200 lg:text-sm md:table-cell">
                                <div v-if="billing.getUser()" class="flex items-center space-x-2">
                                    <img :src="billing.getUser().avatar" class="w-6 h-6 rounded-full" />
                                    <span class="text-sm text-gray-800">
                                        {{ billing.getUser().name }}
                                    </span>
                                </div>
                                <div v-else class="text-sm text-gray-800">
                                    /
                                </div>
                            </td>
                            <td class="px-3 py-2 text-xs border-b border-gray-200 lg:text-sm">
                                {{ billing.getQuote(quotes).number }}
                            </td>
                            <td class="px-3 py-2 text-xs border-b border-gray-200 lg:text-sm">
                                {{ billing.total | price }}
                            </td>
                            <td class="px-3 py-2 text-xs border-b border-gray-200 lg:text-sm">
                                <span :class="billing.getStatusBadgeClasses()">
                                    {{ $t(`worksite.progress-billings.status.${billing.status}`) }}
                                </span>
                            </td>
                            <td class="hidden px-3 py-2 text-xs border-b border-gray-200 lg:table-cell lg:text-sm">
                                <span :class="billing.getInvoicedBadgeClasses()">
                                    {{ $t(`worksite.progress-billings.invoiced.${billing.getInvoicedStatus()}`) }}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="flex items-center justify-center p-4" v-if="quotes.isEmpty()">
                <div class="p-8 text-center cursor-pointer" @click.prevent="$bus.$emit('worksite:worksite-progress-billing-quote-form-modal:open')">
                    <div class="-my-12">
                        <v-spa-lottie-empty></v-spa-lottie-empty>
                    </div>
                    <p class="text-xl text-gray-400">{{ $t('worksite.progress-billings.add_quote') }}</p>
                </div>
            </div>

            <div class="flex items-center justify-center p-4" v-if="quotes.isNotEmpty() && billings.isEmpty()">
                <div class="p-8 text-center cursor-pointer" @click.prevent="$router.push({name: 'account.worksite.progress-billings.create', params: {account: $store.state.account.uuid, worksite: $store.state.worksite.uuid}})">
                    <div class="-my-12">
                        <v-spa-lottie-empty></v-spa-lottie-empty>
                    </div>
                    <p class="text-xl text-gray-400">{{ $t('worksite.progress-billings.add_billing') }}</p>
                </div>
            </div>
        
        </div>

        <worksite-progress-billing-quote-modal-form />
    </div>
</template>

<script>

    import WorksiteProgressBillingQuoteEndpoint from './spa/endpoints/WorksiteProgressBillingQuoteEndpoint';
    import WorksiteProgressBillingEndpoint from './spa/endpoints/WorksiteProgressBillingEndpoint';
    import WorksiteProgressBillingQuoteModalForm from './spa/components/WorksiteProgressBillingQuoteModalForm';

    export default {
        components: {
            WorksiteProgressBillingQuoteModalForm
        },
        data() {
            return {
                quotes: null,
                billings: null,
                loading: true
            }
        },
        computed: {
            quoteIsFullyBilled()
            {
                return this.quotes.every(q => {
                    return q.total == q.getBillings(this.billings).get().reduce((total, billing) => total + billing.total, 0);
                });
            },
            
            billingMissingInvoice()
            {
                return this.billings && this.billings.some(billing => billing.getInvoicedStatus() == 'todo');
            },
        },
        methods: {
            redirectToCreateOnFirstQuoteImport()
            {
                if ( this.quotes.length() == 1 ) {
                    this.$router.push({name: 'account.worksite.progress-billings.create', params: {account: this.$store.state.account.uuid, worksite: this.$store.state.worksite.uuid}});
                }
            }
        },
        async created()
        {
            // TODO: Move to the SPA package
            window.Worksite.endpoints.progressBillingQuote = new WorksiteProgressBillingQuoteEndpoint();
            window.Worksite.endpoints.progressBilling = new WorksiteProgressBillingEndpoint();

            let {quotes} = await window.Worksite.endpoints.progressBillingQuote.setWorksite(this.$store.state.worksite).index();
            let {billings} = await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).index();
            this.quotes = quotes;
            this.billings = billings;
            
            this.loading = false;

            this.$bus.$on('worksite:worksite-progress-billing-quote:created', this.redirectToCreateOnFirstQuoteImport);
        },
        beforeDestroy()
        {
            this.$bus.$off('worksite:worksite-progress-billing-quote:created', this.redirectToCreateOnFirstQuoteImport);
        }
    }
</script>
