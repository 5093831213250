<template>
    <amounts-panels
        ref="amountsPanels"
        :display-links="displayLinks"

        :total-quoted="$store.state.worksite.getProfitability().total.quoted"
        :title-quoted="$t('worksite.profitability.panels.quoted.title')"

        :total-costs="$store.state.worksite.getProfitability().total.costs"
        :title-costs="$t('worksite.profitability.panels.costs.title')"

        :positive-liquidity="$store.state.worksite.hasPositiveLiquidity()"
        :total-liquidity="$store.state.worksite.getProfitability().total.liquidity"
        :title-liquidity="$t('worksite.profitability.panels.liquidity.title')"

        :positive-margin="$store.state.worksite.hasPositiveMargin()"
        :total-margin="$store.state.worksite.getProfitability().total.margin"
        :title-margin="$t('worksite.profitability.panels.margin.title')"
    ></amounts-panels>
</template>

<script>
    import AmountsPanels from '~base/components/Profitability/AmountsPanels';

    export default {
        components: {
            AmountsPanels
        },
        props: {
            displayLinks: {
                type: Boolean,
                default: true
            }
        }
    }
</script>
