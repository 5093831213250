<template>
    <div class="relative mb-4" :style="`height: ${gantt.phasesHeight()}px; width: ${gantt.totalWidth()}px`" v-if="gantt">
        <div
            class="absolute px-4 py-2 bg-gray-200 rounded-full shadow cursor-pointer hover:bg-gray-300"
            :style="`top: ${phase.top}px; left: ${phase.left}px; width: ${phase.width}px`"
            v-for="phase in gantt.phases"
            :key="phase.uuid"
            @contextmenu.stop.prevent="phase.contextMenu($event)"
            @click.prevent="phase.edit()"
        >
            <div class="relative">
                <h1
                    :class="phase.fully_visible ? 'block text-center' : 'inline-block sticky left-4'"
                    class="font-semibold text-gray-800"
                >
                    {{ phase.title }}
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
    import Gantt from "./_Gantt";

    export default {
        props: {
            worksite: {
                type: Object,
                required: true
            },
            phases: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                gantt: null
            }
        },
        watch: {
            phases() {
                console.log('Watch 2');
                this.init();
            }
        },
        methods: {
            init()
            {
                console.log('Init Gantt.vue');
                this.gantt = new Gantt(this.phases, this.firstDay, this.dayWidth);
                this.$emit('initialized');
            }
        },
        created()
        {
            this.init();
        },
        mounted()
        {
            this.$emit('mounted');
        }
    }
</script>