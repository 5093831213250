<template>
    <layout>
        <v-spa-pages-accounts />
    </layout>
</template>
​
<script>
    import Layout from '~base/components/Layout';

    export default {
        components: {
            'layout': Layout
        }
    }
</script>