<template>
    <div :class="containerClassName">
        <p class="text-sm text-gray-600">
            {{ label}}
        </p>
        <slot>
            <p class="text-gray-900" v-if="text">
                {{ text }}
            </p>
        </slot>
    </div>
</template>

<script>
    export default {
        props: {
            containerClassName: {
                type: String,
                default: 'mb-4'
            },
            label: {
                type: String,
                required: true
            },
            text: {
                type: String,
                required: false
            }
        }
    }
</script>