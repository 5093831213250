<template>
    <div>
        
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>

        <template v-else>
            <div v-if="activitiesPerDay && activitiesPerDay.length == 0">
                <div class="flex justify-center">
                    <div class="max-w-[200px] -mb-4">
                        <v-spa-lottie-empty></v-spa-lottie-empty>
                    </div>
                </div>
                <p class="text-center">{{ $t('worksite.time_sheets.empty') }}</p>
            </div>
            <div class="flex space-x-8" v-else>
                <div class="w-[300px]">
                    <h2 class="mb-4 text-xl font-medium">{{ $t('worksite.time_sheets.total_panel.title') }}</h2>
                    <div class="py-6 border border-gray-200 rounded-lg">
                        <div class="px-6 mb-6 border-b border-gray-200">
                            <p class="mb-4 text-lg text-gray-600">
                                {{ $t('worksite.time_sheets.total_panel.totals.title') }}
                            </p>
                            <text-with-label :label="$t('worksite.time_sheets.total_panel.total')">
                                <span class="text-3xl font-semibold">{{ totalHours() }}</span>
                            </text-with-label>
                            <text-with-label :label="$t('worksite.time_sheets.total_panel.costs')">
                                <span class="text-3xl font-semibold">{{ totalPrice() | price }}</span>
                            </text-with-label>
                        </div>
                        <div class="px-6">
                            <p class="mb-4 text-lg text-gray-600">
                                {{ $t('worksite.time_sheets.total_panel.per_user.title') }}
                            </p>
                            <div class="flex flex-col space-y-4">
                                <div class="flex items-center space-x-4" v-for="user in activitiesUser()" :key="user.id">
                                    <div class="w-10 h-10 bg-center bg-cover rounded-full" :style="`background-image: url('${user.avatar}')`"></div>
                                    <div>
                                        <text-with-label container-class-name="" :label="$t('worksite.time_sheets.total_panel.per_user.total')">
                                            <span class="text-xl font-semibold">{{ totalHoursForUser(user) }}</span>
                                        </text-with-label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-1">
                    <h2 class="mb-4 text-xl font-medium">{{ $t('worksite.time_sheets.activities.title') }}</h2>
                    <div class="mb-8" :key="day.date" v-for="day in activitiesPerDay">
                        <h2 class="mb-2 text-gray-800">{{ day.date | moment().format('dddd DD MMMM YYYY') }}</h2>
                        <div class="flex flex-col space-y-2">
                            <time-activity
                                v-for="activity in day.activities"
                                :key="activity.uuid"
                                :activity="activity"
                            ></time-activity>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
	import TimeActivitiesStatistics from '~spa/classes/TimeActivitiesStatistics';
    import {mapState} from 'vuex';
    import Panel from '~base/components/_Partials/Panel';
    import TextWithLabel from '../_Partials/TextWithLabel';
    import TimeActivity from '~spa/apps/trustup-pro-planning-js/components/TimeActivity/Item';

    export default {
        components: {
            Panel,
            TextWithLabel,
            TimeActivity
        },
        data() {
            return {
                loading: true,
                activitiesPerDay: null,
                activities: null,
            }
        },
        computed: {
            ...mapState(['worksite']),
        },
        methods: {
            activitiesUser()
            {
                return this.activities.get().reduce((acc, activity) => {
                    if (!acc.find(user => user.id == activity.user_id)) {
                        acc.push(activity.user);
                    }
                    return acc;
                }, []);
            },

            totalHoursForUser(user)
            {
                return this.totalHours(
                    this.activities.filter(activity => activity.user_id == user.id)
                );
            },
            
            totalHours(activities = this.activities)
            {
                return new TimeActivitiesStatistics(activities).hours();
            },
            
            totalPrice(activities = this.activities)
            {
                return new TimeActivitiesStatistics(activities).price();
            },

            async getTimetrackerImportableDocuments()
            {
                let {activities} = await window.Worksite.endpoints.worksiteTimeActivity
                    .setWorksite(this.worksite)
                    .index();
                    
                this.activities = activities;
                this.setActivitiesPerDay();
            },

            setActivitiesPerDay()
            {
                console.log('test');
                this.activitiesPerDay = this.activities.get().reduce((acc, activity) => {
                    let date = activity.getStartsAt().format('YYYY-MM-DD');
                    if (!acc.some(d => d.date === date)) {
                        acc.push({
                            date: date,
                            activities: []
                        });
                    }
                    acc.find(d => d.date === date).activities.push(activity);
                    return acc;
                }, []);
            }
        },
        async created()
        {
            await this.getTimetrackerImportableDocuments();
            this.loading = false;

            this.$bus.$on('planning:time-activity:created', () => this.setActivitiesPerDay());
            this.$bus.$on('planning:time-activity:updated', () => this.setActivitiesPerDay());
            this.$bus.$on('planning:time-activity:deleted', () => this.setActivitiesPerDay());
        },
        beforeDestroy()
        {
            this.$bus.$off('planning:time-activity:created', () => this.setActivitiesPerDay());
            this.$bus.$off('planning:time-activity:updated', () => this.setActivitiesPerDay());
            this.$bus.$off('planning:time-activity:deleted', () => this.setActivitiesPerDay());
        }
    }
</script>
