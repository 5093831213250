<template>
    <router-link 
        :to="{name: tab.name}"
        class="pb-2 text-gray-600 whitespace-nowrap"
        active-class="title-tab-active"
    >
        {{ tab.label }}
    </router-link>
</template>

<style scoped>
    .title-tab-active {
        position: relative;
    }
    .title-tab-active:before {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        @apply bg-gray-500;
    }
</style>

<script>
    export default {
        props: {
            tab: {
                type: Object,
                required: true
            }
        }
    }
</script>