<template>
    <div id="app" class="relative min-h-screen">
        <div class="absolute inset-0 z-50 p-16" v-if="$store.state.loadingApplication">
            <v-spa-loader></v-spa-loader>
            <p class="font-thin text-center text-gray-700">Loading application...</p>
        </div>
        <router-view></router-view>
    </div>
</template>

<script>    
    export default {
        name: 'app',

        watch: {
            '$route': {
                deep: true,
                handler: function (page) {
                    // window.Intercom("update", {last_request_at: parseInt((new Date()).getTime()/1000)});
                    
                    this.$bus.$emit('spa:closeResponsiveSidebar');
                    if ( this.$store.state.professional ) {
                        this.$store.dispatch('setVariableInTitle', {variable: 'professionalname', value: this.$store.state.professional.company});
                    }
                }
            }
        }
    }
</script>