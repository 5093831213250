<template>
    <table class="w-full">
        <thead>
            <tr>
                <th class="py-2 pr-4 text-sm font-normal text-left text-gray-500 uppercase border-b border-gray-200">
                    {{ $t('profitability.index.table_headings.title')}}
                </th>
                <th class="px-4 py-2 text-sm font-normal text-left text-gray-500 uppercase border-b border-gray-200">
                    {{ $t('profitability.index.table_headings.end_date')}}
                </th>
                <th class="px-4 py-2 text-sm font-normal text-left text-gray-500 uppercase border-b border-gray-200">
                    {{ $t('profitability.index.table_headings.quoted')}}
                </th>
                <th class="py-2 pl-4 text-sm font-normal text-left text-gray-500 uppercase border-b border-gray-200">
                    {{ $t('profitability.index.table_headings.costs')}}
                </th>
                <th class="py-2 pl-4 text-sm font-normal text-left text-gray-500 uppercase border-b border-gray-200">
                    {{ $t('profitability.index.table_headings.margin')}}
                </th>
                <th class="py-2 pl-4 text-sm font-normal text-right text-gray-500 uppercase border-b border-gray-200">
                    {{ $t('profitability.index.table_headings.actions')}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr class="group js-file-context-menu-trigger" v-for="worksite in worksites" :key="worksite.uuid" @contextmenu.prevent="openworksiteContextMenu(worksite, $event)">
                <td class="p-4 pl-0 border-b border-gray-200">
                    <div class="flex items-center space-x-4">
                        <div class="max-w-[400px] truncate">
                            <p class="text-gray-800">{{ worksite.title }}</p>
                            <p class="text-sm text-gray-600">{{ worksite.getAddress() }}</p>
                        </div>
                    </div>
                </td>
                <td class="p-4 border-b border-gray-200">
                    {{ worksite.ends_at | moment().format('DD MMMM YYYY') }}
                </td>
                <td class="p-4 border-b border-gray-200">
                    <p>{{ worksite.total_quoted | price }}</p>
                </td>
                <td class="p-4 border-b border-gray-200">
                    <p>{{ worksite.total_costs | price }}</p>
                </td>
                <td class="p-4 border-b border-gray-200">
                    <div class="flex items-center space-x-1" :class="{'text-[#77BB5A]': worksite.margin > 0, 'text-[#D95C4D]': worksite.margin <= 0}">
                        <i class="text-sm fal fa-check-circle" v-if="worksite.margin > 0" />
                        <i class="text-sm fal fa-exclamation-circle" v-if="worksite.margin <= 0" />
                        <span>{{ worksite.margin | price }}</span>
                    </div>
                </td>
                <td class="p-4 pr-0 border-b border-gray-200">
                    <v-spa-submenu-container class="h-full" :container="$spa.css.submenu.container.default" position="right-0" :ref="`worksiteActionsSubmenu-${worksite.uuid}`">
                        <template v-slot:default="slotProps">
                            <div class="flex items-center justify-center h-full px-4 py-2 text-gray-600 cursor-pointer hover:text-black" @click.stop.prevent="slotProps.toggle()">
                                <i class="text-sm fas fa-ellipsis-h" />
                            </div>
                        </template>
                        
                        <div class="relative w-auto" :container="$spa.css.submenu.container.default" slot="submenu">
                            <a :class="$spa.css.contextmenu.link" href="#" @click.stop.prevent="$router.push({name: 'account.worksite.homepage', params: {worksite: worksite.uuid}})">
                                <i :class="`${$spa.css.contextmenu.icon} far fa-eye`" />
                                {{ $t('worksites.index.worksite_actions.open') }}
                            </a>
                        </div>
                    </v-spa-submenu-container>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>

export default {
    props: {
        worksites: {
            type: Array,
        }
    }   
}
</script>
