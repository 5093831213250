<template>
    <div
        class="relative h-full"
        @contextmenu.prevent="openContextMenu"
    >
    
        <div class="flex items-center justify-between mb-8">
            <div class="flex flex-wrap">
                <router-link class="mr-2 text-gray-600 hover:text-gray-800" :to="{name: 'account.worksite.files', params: {account: $store.state.account.uuid, worksite: $store.state.worksite.uuid}}">
                    {{ $t('worksite.files.home') }}
                </router-link>
                <div v-for="breadcrumb in breadcrumbs" :key="`span-${breadcrumb.uuid}`">
                    <span class="inline-block mr-2 text-gray-600">/</span>
                    <span @contextmenu.stop.prevent="breadcrumb.contextMenu($event)" :key="`contextmenu-${breadcrumb.uuid}`">
                        <router-link class="mr-2 text-gray-600 hover:text-gray-800" :to="{name: 'account.worksite.files.folder', params: {account: $store.state.account.uuid, worksite: $store.state.worksite.uuid, folder: breadcrumb.uuid}}" :key="`link-${breadcrumb.uuid}`">
                            {{ breadcrumb.label }}
                        </router-link>
                    </span>
                </div>
            </div>
            <div class="flex items-center space-x-2" slot="button">
                <v-spa-loading-button @clicked="$bus.$emit('worksite:worksite-file:upload')">{{ $t('worksite.files.add_file') }}</v-spa-loading-button>
                <v-spa-loading-button @clicked="$bus.$emit('worksite:worksite-file-folder-form-modal:open', {worksite: $store.state.worksite, currentFolder: $store.state.pages.worksite.files.folder})" :color="$spa.css.button.color.white" extra-class="border border-gray-200">{{ $t('worksite.files.add_folder') }}</v-spa-loading-button>
            </div>
        </div>

        <div class="grid grid-cols-1 gap-4 mb-12 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4" v-if="folders && folders.isNotEmpty()">
            <div v-for="folder in folders" :key="folder.uuid">
                <div
                    class="flex items-center w-full h-full cursor-pointer js-folder-context-menu-trigger"
                    @click="folder.open()"
                    @contextmenu.stop.prevent="folder.contextMenu($event)"
                >
                    <div class="flex items-center justify-center px-4 py-2 mr-4 bg-gray-200 rounded lg:px-6 lg:py-4">
                        <i class="block text-lg text-gray-600 lg:text-2xl far fa-folder"></i>
                    </div>
                    <div class="truncate">
                        <p class="block w-full text-sm truncate lg:text-base flex-shrink-1 whitespace-nowrap">{{ folder.title }}</p>
                        <p class="block w-full text-xs text-gray-600 truncate lg:text-sm flex-shrink-1 whitespace-nowrap">
                            {{ $tc('worksite.files.folder.files_count', folder.files_count, {count: folder.files_count}) }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>

        <table class="w-full" v-if="files && files.isNotEmpty()">
            <thead>
                <tr>
                    <th :class="$spa.css.table.th_first">
                        {{ $t('worksite.files.table_headings.title')}}
                    </th>
                    <th :class="$spa.css.table.th">
                        {{ $t('worksite.files.table_headings.type')}}
                    </th>
                    <th :class="$spa.css.table.th">
                        {{ $t('worksite.files.table_headings.date')}}
                    </th>
                    <th :class="$spa.css.table.th_last">
                        {{ $t('worksite.files.table_headings.actions')}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="group js-file-context-menu-trigger"
                    v-for="file in files"
                    :key="file.uuid"
                    @contextmenu.stop.prevent="file.contextMenu($event)"
                    @click.prevent.stop="file.preview()"
                >
                    <td :class="`${$spa.css.table.td_first} cursor-pointer`">
                        <div class="flex items-center space-x-4">
                            <div class="flex justify-center w-12">
                                <div class="w-12 h-12 bg-center bg-cover rounded" :style="`background-image: url(${file.url_miniature}`" v-if="file.isImage()"></div>
                                <i :class="file.getFAIconClass()" v-else />
                            </div>
                            <div class="max-w-[400px] truncate">
                                {{ file.name }}
                            </div>
                        </div>
                    </td>
                    <td :class="`${$spa.css.table.td} cursor-pointer`">
                        {{ file.getType() }}
                    </td>
                    <td :class="`${$spa.css.table.td} cursor-pointer`">
                        {{ file.created_at.format('DD MMMM YYYY') }}
                    </td>
                    <td :class="`${$spa.css.table.td_last} cursor-pointer`">
                        <v-spa-submenu-container class="h-full" :container="$spa.css.submenu.container.default" position="right-0" :ref="`fileActionsSubmenu-${file.uuid}`">
                            <template v-slot:default="slotProps">
                                <div class="min-h-[40px]">
                                    <div class="flex items-center justify-end h-full px-4 py-2 text-gray-600 cursor-pointer hover:text-black" @click.stop.prevent="slotProps.toggle()">
                                        <i class="text-sm fas fa-ellipsis-h" />
                                    </div>
                                </div>
                            </template>
                            
                            <template v-slot:submenu>
                                <div class="relative w-auto" :container="$spa.css.submenu.container.default">
                                    <worksite-file-actions
                                        :file="file"
                                        @close="$refs[`fileActionsSubmenu-${file.uuid}`][0].close()"
                                    />
                                </div>
                            </template>
                        </v-spa-submenu-container>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="flex items-center justify-center p-4" v-if="! loading && files.isEmpty()">
            <div class="p-8 text-center cursor-pointer" @click.prevent="$bus.$emit('worksite:worksite-file:upload')">
                <div class="-my-12">
                    <v-spa-lottie-empty></v-spa-lottie-empty>
                </div>
                <p class="text-xl text-gray-400">{{ $t('worksite.files.add_file') }}</p>
            </div>
        </div>
        
        <vue-context ref="menu">
            <div :class="$spa.css.contextmenu.container">
                <li>
                    <a href="#" @click.prevent="$bus.$emit('worksite:worksite-file:upload')" :class="$spa.css.contextmenu.link">
                        <i :class="`${$spa.css.contextmenu.icon} fas fa-file`" />
                        {{ $t('worksite.files.contextmenu.default.add_file') }}
                    </a>
                </li>
                <li>
                    <a href="#" @click.prevent="$bus.$emit('worksite:worksite-file-folder-form-modal:open', {worksite: $store.state.worksite, currentFolder: $store.state.pages.worksite.files.folder})" :class="$spa.css.contextmenu.link">
                        <i :class="`${$spa.css.contextmenu.icon} fas fa-folder`" />
                        {{ $t('worksite.files.contextmenu.default.add_folder') }}
                    </a>
                </li>
            </div>
        </vue-context>
    </div>  
</template>

<script>
    import Lottie from '~spa/apps/trustup-pro-base-js/components/Lottie';
    import WorksiteFileActions from '~spa/apps/trustup-pro-worksite-js/components/WorksiteFile/Actions';
    import VueContext from 'vue-context';

    export default {        
        components: {
            VueContext,
            WorksiteFileActions,
            Lottie
        },
        
        data()
        {
            return {
                loading: true,
                files: null,
                folders: null,
                breadcrumbs: null
            }
        },

        computed: {
            currentFileIndex()
            {
                return this.files.map(f => f.uuid).indexOf(this.currentFile.uuid);
            },
            hasPreviousFile()
            {
                return this.currentFileIndex > 0;
            },
            hasNextFile()
            {
                return this.currentFileIndex < (this.files.length - 1);
            }
        },

        methods: {
            async getContent()
            {
                this.loading = true;

                let {files, folders, breadcrumbs} = await window.Worksite.endpoints.worksiteFile
                    .setWorksite(this.$store.state.worksite)
                    .index({folder: this.$store.state.pages.worksite.files.folder});

                this.files = files;
                this.folders = folders;
                this.breadcrumbs = breadcrumbs;
                this.loading = false;
            },

            openContextMenu(event)
            {
                this.$bus.$emit('all-context-menu:close');
                this.$refs.menu.open(event);
            },
            closeContextMenu()
            {
                if ( this.$refs.menu ) {
                    this.$refs.menu.close();
                }
            }
        },

        created()
        {
            this.$store.state.pages.worksite.files.folder = this.$route.params.folder || null;

            this.getContent();

            this.$bus.$on('all-context-menu:close', this.closeContextMenu);
        },

        beforeDestroy()
        {
            this.$store.state.pages.worksite.files.folder = this.$route.params.folder || null;
            this.$bus.$off('all-context-menu:close', this.closeContextMenu);
            // this.files.destroy();
            // this.folders.destroy();
            // this.breadcrumbs.destroy();
        }
    }
</script>

<style>
    .v-context {
        display: block;
        z-index: 1500;
        position: fixed;
    }
    .v-context:focus,
    .v-context > li > a:focus,
    .v-context ul:focus,
    .v-context ul > li > a:focus {
        outline: 0;
    }
</style>