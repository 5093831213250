<template>
    <div
        class="flex items-center justify-center h-8 text-sm rounded mr-[1px] cursor-pointer"
        :class="color"
        :style="`width: calc(${width}% - 1px)`"
        :data-tooltip="text"
        data-tooltip-position="top"
        @click.stop.prevent="$emit('clicked')"
        @contextmenu.stop.prevent="$emit('contextmenu', $event)"
    >
        <div class="block w-full px-1">
            <span class="block w-full overflow-x-hidden text-sm text-center text-white whitespace-nowrap" v-if="displayText">{{ text }}</span>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            color: {
                type: String,
                required: true
            },
            width: {
                type: Number,
                required: true
            },
            text: {
                type: String,
                required: false
            },
            displayText: {
                type: Boolean,
                default: true
            }
        },
    }
</script>