<template>
    <div>
        
        <div class="relative py-12" v-if="loading.base"><v-spa-loader></v-spa-loader></div>

        <template v-else>
            <div>  
                <draggable
                    @end="onGroupReorder"
                    v-model="groups.items"
                    group="groups"
                    handle=".group-handle"
                >  
                    <worksite-tasks-group
                        v-for="(group, index) in groups"
                        :index="index"
                        :group="group"
                        :columns="columns"
                        :column-types="columnTypes"
                        :key="group.uuid"
                        @deleted="onGroupDeleted"
                        @duplicate="duplicateGroup"
                        @duplicate-with-items="duplicateGroupWithItems"
                    ></worksite-tasks-group>
                </draggable>
            </div>
            <div class="relative mb-4 bg-gray-100" v-if="loading.group">
                <td class="py-6 bg-gray-100"><v-spa-loader background-color="bg-gray-100"></v-spa-loader></td>
            </div>
            <div v-else>
                <v-spa-loading-button ref="addGroupButton" extra-class="border border-gray-400" :color="$spa.css.button.color.white" @clicked="addNewGroup">{{ $t('worksite.tasks.add_group_button') }}</v-spa-loading-button>
            </div>
        </template>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import WorksiteTask from '~base/models/WorksiteTask';
    import WorksiteTaskColumn from '~base/models/WorksiteTaskColumn';
    import WorksiteTasksGroup from './Tasks/Group';
    
    export default {

        components: {
            draggable,
            WorksiteTasksGroup
        },
        
        data() {
            return {
                loading: {
                    base: true,
                    task: false,
                    group: false,
                    column: false
                },
                columns: null,
                columnTypes: null,
                groups: null
            }
        },

        methods: {
            async getTasks()
            {
                let {columns, groups} = await window.Worksite.endpoints.worksiteTask.setWorksite(this.$store.state.worksite).index();
                this.columns = columns;
                this.groups = groups;
                
                await this.init();
                        
                this.loading.base = false;
            },

            async init()
            {
                if ( this.columns.isEmpty() ) {
                    await this.createColumns();
                }

                if ( this.groups.isEmpty() ) {
                    await this.addGroup();
                }
                
                if ( this.groups.every(g => g.tasks.isEmpty()) ) {
                    await this.addTask(WorksiteTask.default(this.columns, {first: true, group_id: this.groups.items[0].id}).data());
                }
            },

            async createColumns()
            {
                let columns = WorksiteTaskColumn.defaults();
                let promises = [];
                for ( let i = 0; i < columns.length; i++ ) {
                    promises.push(
                        window.Worksite.endpoints.worksiteTaskColumn.setWorksite(this.$store.state.worksite).store(columns[i].data())
                    );
                }

                this.columns = await Promise.all(promises);
            },

            async addTask(data = WorksiteTask.default(this.columns).data())
            {
                let task = await window.Worksite.endpoints.worksiteTask.setWorksite(this.$store.state.worksite).store(data);
                this.setTaskInGroup(task);
            },

            unsetTaskFromGroup(task)
            {
                this.groups = this.groups.map(group => {
                    if ( group.id == task.group_id ) {
                        group.tasks = group.tasks.filter(t => t.uuid !== task.uuid);
                    }
                    return group;
                });
            },

            setTaskInGroup(task)
            {
                this.groups = this.groups.map(group => {
                    if ( group.id == task.group_id ) {
                        console.log(group, task);
                        group.tasks.add(task);
                    }
                    return group;
                });
            },

            async onGroupReorder()
            {
                await window.Worksite.endpoints.worksiteTaskGroup.setWorksite(this.$store.state.worksite).reorder(this.groups.map(s => s.uuid));
            },

            async addGroup(data)
            {
                await window.Worksite.endpoints.worksiteTaskGroup.setWorksite(this.$store.state.worksite).store({
                    ...data, ...{title: this.groups.length() == 0 ? this.$t('worksite.tasks.default_first_group_title') : this.$t('worksite.tasks.default_group_title'), position: 1}
                });
            },
            
            async addNewGroup()
            {
                this.loading.group = true;

                await this.addGroup({position: this.groups.length() + 1});
                await this.addTask(WorksiteTask.default(this.columns, {first: true, group_id: this.groups[this.groups.length() - 1].id}).data());
                
                this.loading.group = false;
            },

            async moveTasksToGroup(tasks, group)
            {
                let promises = [];
                for ( let i = 0; i < tasks.length; i++ ) {
                    promises.push( this.moveTaskToGroup(tasks[i], group) );
                }

                await Promise.all(promises);
            },

            async moveTaskToGroup(task, group)
            {
                this.unsetTaskFromGroup(task);
                task.group_id = group.id;
                this.setTaskInGroup(task);
                await window.Worksite.endpoints.worksiteTask.setWorksite(this.$store.state.worksite).update({uuid: task.uuid, data: task.data()});
            },

            onGroupDeleted(group)
            {
                this.groups = this.groups.filter(g => g.uuid !== group.uuid);
            },

            async duplicateGroup({group, order})
            {
                this.loading.group = true;

                let newGroup = await window.Worksite.endpoints.worksiteTaskGroup.setWorksite(this.$store.state.worksite).store({title: group.title, order});

                this.loading.group = false;

                return newGroup;
            },

            async duplicateGroupWithItems({group, order})
            {
                this.loading.group = true;

                let newGroup = await this.duplicateGroup({group, order});

                let promises = [];
                for ( let i = 0; i < group.tasks.length; i++ ) {
                    promises.push( this.addTask({...group.tasks[i].data(), ...{group_id: newGroup.id}}) );
                }
                await Promise.all(promises);

                this.loading.group = false;
            }
        },

        created() {
            this.columnTypes = WorksiteTaskColumn.types();
            this.getTasks();
        }
    }
</script>

<style>
    .sortable-chosen.sortable-ghost {
        opacity: 0;
    }
    .sortable-fallback {
        opacity: .85 !important;
    }
    .sortable-drag {
        background: white;
        border-radius: 0.5rem;
    }
    .sortable-drag td {
        background: white;
    }
    .sortable-drag td:first-of-type {
        border-top-left-radius: 0.5rem;
        border-bottom-left-radius: 0.5rem;
    }
    .sortable-drag td:last-of-type {
        border-top-right-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
    }
</style>
