<template>
    <v-spa-right-panel-modal
        name="create-quote-modal"
        @closed="reset"
    >
        <div v-if="! $spa.TrustUpPro.hasAccount('invoicing')">
            <img :src="`${$spa.TrustUpPro.url}/apps/invoicing/logo.png`" class="w-auto h-[30px] mb-4">
            <p class="text-sm text-gray-600 whitespace-normal">
                {{ $t('modals.create_quote.no_account') }}
            </p>
        </div>
        <div v-else>
            <div class="flex items-center justify-center flex-1 w-full mt-8 lg:mt-24" v-if="success">
                <div class="relative w-full">
                    <div class="flex justify-center relative w-full -mt-[50px] -mb-[50px]">
                        <div class="max-w-[450px]">
                            <v-spa-lottie-success></v-spa-lottie-success>
                        </div>
                    </div>
                    <p class="mb-4 text-lg text-center text-gray-800">
                        {{ $t('modals.create_quote.success.text') }}
                    </p>
                    <div class="flex justify-center w-full">
                        <v-spa-loading-button color="bg-[#22D180] hover:bg-green-600 text-white" :loading="form.isLoading()" :prevent-default="true" @clicked="openQuote">
                            {{ $t('modals.create_quote.success.button') }}
                        </v-spa-loading-button>
                    </div>
                </div>
            </div>
            <template v-else>
                <h2 class="flex items-center w-full mb-8 text-xl font-medium text-gray-800">
                    <img :src="`${$spa.TrustUpPro.url}/apps/invoicing/icon.svg`" class="w-10 h-10 mr-2">
                    <span>{{ $t(`modals.create_quote.title`) }}</span>
                </h2>
                <div class="flex mb-8 space-x-6">
                    <a href="#" @click.prevent="tab = 'insert'" class="inline-block pb-1 font-medium" :class="tab == 'insert' ? 'text-primary border-b-2 border-primary' : 'text-gray-800'" v-if="models.quotes && models.quotes.length > 0">
                        {{ $t(`modals.create_quote.tabs.insert.label`) }}
                    </a>
                    <a href="#" @click.prevent="tab = 'create'" class="inline-block pb-1 font-medium" :class="tab == 'create' ? 'text-primary border-b-2 border-primary' : 'text-gray-800'">
                        {{ $t(`modals.create_quote.tabs.create.label`) }}
                    </a>
                </div>
                <div class="relative py-8" v-if="loading">
                    <v-spa-loader background-color="bg-[#fafafa]"></v-spa-loader>
                </div>
                <div v-if="! loading">
                    <form @submit.prevent="submit">
                        <p class="mb-6 text-sm text-gray-800">
                            {{ $t(`modals.create_quote.tabs.${tab}.help`) }}
                        </p> 
                        <template v-if="tab == 'create'">                           
                            <v-spa-input :label="$t(`modals.create_quote.tabs.${tab}.labels.quote_title`)" id="create-quote-modal-new-form-reference" :form="form" input-name="reference"></v-spa-input>
                        
                            <v-spa-label id="create-quote-modal-new-form-title" :label="$t(`modals.create_quote.tabs.${tab}.labels.content`)"></v-spa-label>

                            <template v-if="editContent">
                                <div class="flex -mx-1">
                                    <div class="flex-1 px-1">
                                        <v-spa-input :display-label="false" id="create-quote-modal-new-form-title" :form="form" input-name="title"></v-spa-input>
                                    </div>
                                    <div class="px-1 w-[80px]">
                                        <v-spa-input :display-label="false" id="create-quote-modal-new-form-quantity" :form="form" input-name="quantity"></v-spa-input>
                                    </div>
                                    <div class="px-1 w-[60px]">
                                        <v-spa-element :display-label="false" id="create-quote-modal-new-form_price_type_id" :form="form" input-name="price_type_id">
                                            <select :class="$spa.css.form.select" v-model="form.price_type_id">
                                                <option :value="priceType.id" :key="priceType.uuid" v-for="priceType in models.priceTypes">
                                                    {{ priceType.title }}
                                                </option>
                                            </select>
                                        </v-spa-element>
                                    </div>
                                    <div class="px-1 h-[38px] flex items-center">
                                        <span class="text-sm text-gray-600">
                                            x
                                        </span>
                                    </div>
                                    <div class="px-1 w-[120px]">
                                        <v-spa-price-input :display-label="false" id="create-quote-modal-new-form-price" :form="form" input-name="price"></v-spa-price-input>
                                    </div>
                                    <div class="px-1 w-[80px]">
                                        <v-spa-element :display-label="false" id="create-quote-modal-new-form_vat_option_id" :form="form" input-name="vat_option_id">
                                            <select :class="$spa.css.form.select" v-model="form.vat_option_id">
                                                <option :value="vatOption.id" :key="vatOption.uuid" v-for="vatOption in models.vatOptions">
                                                    {{ vatOption.percentage }}%
                                                </option>
                                            </select>
                                        </v-spa-element>
                                    </div>
                                    <div class="w-8 flex-shrink-0 px-1 h-[38px] flex items-center">
                                        <button class="flex items-center justify-center w-6 h-6 text-black bg-gray-300 rounded-full hover:bg-green-600 hover:text-white" @click="editContent = false"><i class="text-sm fal fa-check"></i></button>
                                    </div>
                                </div>
                            </template>

                            <template v-if="! editContent">
                                <div class="block w-full mb-6">
                                    <span class="text-lg text-gray-800 cursor-pointer" @click="editContent = true" :data-tooltip="$t(`modals.create_quote.tabs.${tab}.labels.edit_content`)">
                                        {{ form.title }} - {{ hoursAndMinutes }} x {{ form.price | price }} ({{ vatOptionTitle }})  
                                    </span>
                                </div>
                            </template>

                            <v-spa-loading-button :loading="form.isLoading()" :disabled="editContent">
                                {{ $t(`modals.create_quote.tabs.${tab}.button`) }}
                                <span class="block text-xs" :class="editContent ? 'text-gray-700' : 'text-gray-200'">
                                    {{ $t(`modals.create_quote.tabs.${tab}.button_line_2`, {price: centsToPrice(total)}) }}
                                </span>
                            </v-spa-loading-button>
                        </template>
                        <template v-if="tab == 'insert'">
                            <div class="mb-6">
                                <v-spa-label id="create-worksite-profitability-document-form-document" :label="$t(`modals.create_quote.tabs.${tab}.labels.select_quote`)"></v-spa-label>
                                <select v-model="quote" :class="$spa.css.form.select" id="create-worksite-profitability-document-form-document">
                                    <option :value="quote" v-for="quote in models.quotes" :key="quote.uuid">
                                        {{ quoteLabel(quote) }}
                                    </option>
                                </select>
                            </div>

                            <v-spa-loading-button :loading="form.isLoading()">
                                {{ $t(`modals.create_quote.tabs.${tab}.button`) }}
                                <span class="block text-xs text-gray-200">
                                    {{ $t(`modals.create_quote.tabs.${tab}.button_line_2`, {price: centsToPrice(quote.total)}) }}
                                </span>
                            </v-spa-loading-button>
                        </template>
                    </form>
                </div>
            </template>
        </div>
    </v-spa-right-panel-modal>
</template>

<script>
    import Form from '~spa/classes/Form';
    
    export default {
        data() {
            return {
                opened: false,
                loading: false,
                success: false,
                models: {
                    priceTypes: null,
                    vatOptions: null,
                    quotes: null,
                },
                storedQuote: null,
                quote: null,
                form: new Form({
                    reference: '',
                    title: '',
                    price: 0,
                    quantity: 1,
                    price_type_id: null,
                    vat_option_id: null
                }),
                closeTimeout: null,
                editContent: true,
                tab: 'create'
            }
        },
        computed: {
            total()
            {
                return this.form.price * this.form.quantity;
            },
            hoursAndMinutes()
            {
                let priceType = this.priceTypeTitle;
                if ( priceType !== 'h' || this.form.quantity % 1 == 0 ) {
                    return this.form.quantity + priceType;
                }
                
                return window.timeToHoursAndMinutes(this.form.quantity);
            },
            priceTypeTitle()
            {
                if ( ! this.form.price_type_id || ! this.models.priceTypes ) {
                    return '';
                }
                
                return this.models.priceTypes.find(p => p.id == this.form.price_type_id).title;
            },
            vatOptionTitle()
            {
                if ( ! this.form.vat_option_id || ! this.models.vatOptions ) {
                    return '';
                }
                
                return this.models.vatOptions.find(v => v.id == this.form.vat_option_id).percentage + '%';
            }
        },
        methods: {
            quoteLabel(quote)
            {
                let translation = quote.client_name
                    ? 'modals.create_quote.tabs.insert.labels.select_quote_option_with_client'
                    : 'modals.create_quote.tabs.insert.labels.select_quote_option';

                return this.$t(translation, {
                    client: quote.client_name,
                    number: quote.number,
                    price: window.centsToPrice(quote.total),
                    date: window.moment(quote.created_at).format('DD MMMM YYYY')
                });
            },

            openQuote()
            {
                window.open(`${window.App.$spa.apps.find(app => app.key == 'invoicing').direct_url}/redirection?route=quotes.edit&quote=${this.storedQuote.uuid}`, '_blank');
                this.$modal.hide('create-quote-modal');
            },
            
            reset()
            {
                this.opened = false;
                this.loading = false;
                this.success = false;
                this.models = {
                    priceTypes: null,
                    vatOptions: null,
                    quotes: null,
                };
                this.storedQuote = null;
                this.quote = null;
                this.editContent = true;
                this.form.stop();
                clearTimeout(this.closeTimeout);
            },

            async open()
            {
                this.opened = true;
                this.loading = true;

                this.$modal.show('create-quote-modal');

                if ( this.$spa.TrustUpPro.hasAccount('invoicing') ) {
                    await this.getModels();
                    if ( this.models.quotes.length > 0 ) {
                        this.tab = 'insert';
                    }
                }

                this.loading = false;
            },

            async openFromOpportunity(opportunity)
            {
                this.form.reference = opportunity.title;
                await this.open();
            },

            async submit()
            {
                if ( this.form.isLoading() ) {
                    return;
                }

                this.form.load();

                if ( this.tab == 'create' ) {
                    await this.storeNewQuote();
                    this.form.stop();
                }

                if ( this.tab == 'insert' ) {
                    await this.insertQuote();
                    this.form.stop();
                }
            },
            
            async storeNewQuote()
            {
                let quote = await this.$spa.TrustUpPro.storeQuote({
                    title: this.form.reference,
                    client_id: null,
                    custom_created_at: null,
                    document_date: null,
                    description: '',
                    document_conditions: null,
                    payment_communication: null,
                    payment_date: null,
                    valid_until: null,
                    quote_conditions: null,
                    delivery_date: null,
                    sections: [{
                        title: 'Section',
                        items: [0]
                    }],
                    items: [{
                        title: this.form.title,
                        price: this.form.price,
                        quantity: this.form.quantity,
                        description: '',
                        discount: 0,
                        discount_unit: 'percentage',
                        purchase_price: this.form.price,
                        price_type_id: this.form.price_type_id,
                        vat_option_id: this.form.vat_option_id
                    }]
                });

                this.storedQuote = quote;
                this.success = true;
                
                this.$bus.$emit('create-quote-modal:stored', {
                    quote
                });

                this.closeTimeout = setTimeout(() => this.$modal.hide('create-quote-modal'), 10000);
            },

            async insertQuote()
            {
                this.$bus.$emit('create-quote-modal:inserted', {
                    quote: this.quote
                });
                this.$modal.hide('create-quote-modal');
            },

            async getModels()
            {
                return await Promise.all([
                    this.getQuotes(),
                    this.getPriceTypes(),
                    this.getVATOptions()
                ]);
            },

            async getQuotes()
            {
                let quotes = await this.$spa.TrustUpPro.getQuotes();
                this.models.quotes = quotes.filter(q => q.status_key !== 'draft');
                this.quote = this.models.quotes[0];
            },

            async getPriceTypes()
            {
                let priceTypes = await this.$spa.TrustUpPro.getInvoicingPriceTypes();
                this.models.priceTypes = priceTypes;
                this.form.price_type_id = this.models.priceTypes[0].id;

                if ( this.models.priceTypes.find(type => type.title == 'h') ) {
                    this.form.price_type_id = this.models.priceTypes.find(type => type.title == 'h').id;
                }
            },

            async getVATOptions()
            {
                let vatOptions = await this.$spa.TrustUpPro.getInvoicingVATOptions();
                this.models.vatOptions = vatOptions;
                this.form.vat_option_id = this.models.vatOptions[0].id;
            }
        },
        created()
        {
            this.$bus.$on('create-quote-modal:open', this.open);
            this.$bus.$on('create-quote-modal:open-from-opportunity', this.openFromOpportunity);
        },
        beforeDestroy() {
            this.$bus.$off('create-quote-modal:open', this.open);
            this.$bus.$off('create-quote-modal:open-from-opportunity', this.openFromOpportunity);
        }
    }
</script>
