<template>
    <model-modal
        component="v-spa-mixed-modal"
        :model-class="modelClass"
        modal-type="form"
        modal-name="worksite:worksite-progress-billing-request-validation-form-modal"
        @opened="onOpened"
        @resetted="onReset"
        ref="modal"
    >
        <template v-slot:default>
            <h2 class="mb-8 text-xl font-thin text-center text-gray-800">
                {{ $t('worksite.worksite-progress-billing-request-validation-form-modal.title', {number: model.number}) }}
            </h2>
            <form @submit.prevent="submit">
                <v-spa-checkbox id="wpbrvfm_requires_validation" :form="form" input-name="requires_validation" :label="$t('form.labels.progress_billing_requires_validation')" />
                <div v-if="form.requires_validation">
                    <v-spa-input id="wpbrvfm_email" :form="form" input-name="email" type="email" :label="$t('form.labels.email')" />
                    <v-spa-input id="wpbrvfm_subject" :form="form" input-name="subject" :label="$t('form.labels.email_subject')" />
                    <v-spa-textarea id="wpbrvfm_text" :form="form" input-name="text" :label="$t('form.labels.email_text')" />
                </div>
                <div class="flex justify-end w-full">
                    <v-spa-loading-button :loading="form.isLoading()" v-if="form.requires_validation">
                        <i class="mr-2 fal fa-check"></i>
                        {{ $t('worksite.worksite-progress-billing-request-validation-form-modal.request_validation_button') }}
                    </v-spa-loading-button>
                    <v-spa-loading-button :loading="form.isLoading()" v-if="! form.requires_validation">
                        <i class="mr-2 fal fa-check"></i>
                        {{ $t('worksite.worksite-progress-billing-request-validation-form-modal.validate_button') }}
                    </v-spa-loading-button>
                </div>
            </form>
        </template>
    </model-modal>
</template>

<script>
    import ModelModal from '@spa/apps/trustup-pro-base-js/components/Model/Modal';
    import Model from './../models/WorksiteProgressBilling';
    import Form from '@spa/classes/Form';

    export default {
        components: {
            ModelModal
        },

        data() {
            return {
                modelClass: Model,
                form: new Form({
                    requires_validation: true,
                    subject: null,
                    text: null,
                    email: null 
                })
            };
        },

        computed: {
            model()
            {
                return this.$refs.modal?.model || null;
            },
        },

        methods: {
            getModalEvent()
            {
                if ( this.modalName ) {
                    return `${this.modalName}:open`;
                }

                let method = this.modalType == 'form' ? 'getFormModalEvent' : 'getModalEvent';
                return this.modelClass[method]();
            },

            async onOpened({send_to})
            {
                this.form.subject = this.$t('worksite.worksite-progress-billing-request-validation-form-modal.default_email_subject', {number: this.model.number});
                this.form.text = this.$t('worksite.worksite-progress-billing-request-validation-form-modal.default_email_text', {number: this.model.number});
                this.form.email = send_to;
            },

            onReset()
            {
                this.form.reset();
            },

            async submit()
            {
                if ( this.form.isLoading() ) {
                    return; 
                }

                this.form.load();

                if ( ! this.form.requires_validation ) {
                    await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).validate(this.model.uuid);
                    return this.success();
                }

                await window.Worksite.endpoints.progressBilling.setWorksite(this.$store.state.worksite).requestValidation(this.model.uuid, {
                    send_to: this.form.email,
                    subject: this.form.subject,
                    text: this.form.text
                });
                this.success();
            },

            success()
            {
                this.form.stop();
                this.$bus.$emit('worksite:worksite-progress-billing-request-validation-form-modal:success', {billing: this.model});
                this.$refs.modal.close();
            }
        }
    }
</script>
