export default {
    apiHeaders: state => {
        if ( ! state.token ) {
            return {};
        }

        return {
            'Authorization': state.token.token_type + ' ' + state.token.access_token
        };
    },

    appApiHeaders:(state, getters) => {
        let headers = {
            'Content-Language': state.locale
        };

        if ( state.token ) {
            headers['X-TrustUp-Authorization'] = state.token.token_type + ' ' + state.token.access_token
        }

        if ( window.App.$spa.user && window.App.$spa.user.jwt ) {
            headers['X-TrustUp-JWT'] = window.App.$spa.user.jwt;
        }

        return headers;
    },

    accountApiHeaders: (state, getters) => {
        let headers = structuredClone(getters.appApiHeaders);

        if ( state.account ) {
            headers['X-Account-UUID'] = state.account.uuid;
        }

        return headers;
    },

    getUsersById: state => ids => {
        return state.models.users.filter(user => ids.includes(user.id));
    },

    getUserById: state => (id) => {
        return state.models.users.find(user => user.id == id);
    },

    getUserName: (state, getters) => (id) => {
        return getters.getUserById(id)?.getDisplayName();
    },

    getUserFullName: (state, getters) => (id) => {
        return getters.getUserById(id)?.getFullName();
    },

    getUserAvatar: (state, getters) => (id) => {
        return getters.getUserById(id)?.avatar;
    },

    usersWithAdminRole: state => {
        return state.models.users.filter(user => user.role == 'super-admin' || user.role == 'admin');
    },

    usersWithWorkerRole: state => {
        return state.models.users.filter(user => user.role == 'user');
    }
}