<template>
    <div class="h-full">
        <div class="relative py-12" v-if="loading"><v-spa-loader></v-spa-loader></div>
        <template v-if="! loading">
            
            <template v-if="worksites.isEmpty()">
                <div class="flex justify-center mb-4">
                    <v-spa-lottie-empty class="max-w-[300px]"></v-spa-lottie-empty>
                </div>
                <div class="text-center">
                    <h1 class="mb-2 text-3xl font-thin text-gray-800">{{ $t('worksites.index.empty.title') }}</h1>
                    <p class="mb-8 text-sm text-gray-500">{{ $t('worksites.index.empty.text') }}</p>
                    <div class="flex justify-center">
                        <v-spa-loading-button @clicked="$bus.$emit('worksite:worksite-form-modal:open')">
                            {{ $t('worksites.index.empty.button') }}
                        </v-spa-loading-button>
                    </div>
                </div>
            </template> 
            <div class="flex flex-col space-y-8" v-else>
                <div v-if="currentWorksites.length > 0">
                    <h2 class="mb-4 text-2xl text-medium">{{ $t('worksites.index.current.title') }}</h2>
                    <worksites-index-list :worksites="currentWorksites"></worksites-index-list>
                </div>
                <div v-if="futureWorksites.length > 0">
                    <h2 class="mb-4 text-2xl text-medium">{{ $t('worksites.index.future.title') }}</h2>
                    <worksites-index-list :worksites="futureWorksites"></worksites-index-list>
                </div>
            </div>
        </template>
    </div>
    
</template>

<script>
    import ActiveWorksiteCollection from '~spa/apps/trustup-pro-worksite-js/collections/ActiveWorksiteCollection';
    import WorksitesIndexList from './Index/_List';

    export default {
        components: {
            WorksitesIndexList
        },
        data() {
            return {
                worksites: [],
                loading: true
            }
        },
        computed: {
            currentWorksites()
            {
                return this.worksites.filter(w => w.isCurrent()).sort((a, b) => {
                    return new Date(b.starts_at) - new Date(a.starts_at);
                });
            },
            futureWorksites()
            {
                return this.worksites.filter(w => w.isFuture()).sort((a, b) => {
                    return new Date(a.starts_at) - new Date(b.starts_at);
                });
            }
        },
        methods: {
            async getWorksites()
            {
                let {worksites} = await window.Worksite.endpoints.worksite.index();
                this.worksites = worksites;
                this.loading = false;
            },
        },
        async created() {
            await this.getWorksites();
        }
    }
</script>